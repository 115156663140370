import axios from "axios";
import qs from "qs";
import axiosClient from "../service/axiosClient";
import httpRequest from "../service/httpRequest";

interface postPopupResponse {
  data: {
    result: boolean;
    message: string;
  };
  status: string;
}

// 팝업 생성
export const postPopupRequest = async (formData: FormData) => {
  try {
    const res = await httpRequest({
      url: `popup/add`,
      method: "post",
      data: formData,
    });
    return res.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("Different error than axios");
    }
  }
};

// 팝업 생성
export const putPopupRequest = async (formData: FormData) => {
  try {
    const res = await httpRequest({
      url: `popup/edit`,
      method: "post",
      data: formData,
    });
    return res.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("Different error than axios");
    }
  }
};

interface popupInfo {
  id: number;
  title: string;
  image: string;
  status: boolean;
  username: string;
  start_date: string;
  end_date: string;
  create_date: string;
}

export interface getPopupResponse {
  data: {
    content: popupInfo[];
    totalPages: number;
    totalElements: number;
  };
  status: string;
}

// 팝업 5개씩 가져오기
export const getPopupRequest = async (params:any) => {
  try {
    return await httpRequest({
      url: `popup`,
      method: "get",
      params,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const getOnePopupRequest = async (id:any) => {
  try {
    return await httpRequest({
      url: `popup/${id}`,
      method: "get",
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const deletePopupImageRequest = async (id:any) => {
  try {
    return await httpRequest({
      url: `popup/image/${id}`,
      method: "delete",
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export interface delOnePopupResponse {
  data: {
    result: boolean;
    message: string;
  };
  status: string;
}

// 팝업 한개만 삭제
export const delOnePopupRequest = async (page: number) => {
  try {
    return await httpRequest({
      url: `popup/${page}`,
      method: "delete",
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export interface delSelectPopupResponse {
  data: {
    result: boolean;
    message: string;
  };
  status: string;
}

// 팝업 선택 삭제
export const delSelectPopupRequest = async (ids: any[]) => {
  try {
    return await httpRequest({
      url: `popup/delete`,
      method: "delete",
      data: {
        id: ids
      }
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};
