import {Button, Form, InputNumber, Modal, Switch} from "antd";
import React, {useEffect, useState} from "react";
import Banner from "../../../interface/Banner";
import {updateBannerViewExpose, updateBannerViewSeq} from "../../../api/banner";

type EditBannerExposeModalProps = {
    visible: boolean;
    onDone?: VoidFunction;
    onClose?: (val?: boolean) => void;
    detail?: Banner;
};

export default function EditBannerExposeModal(props: EditBannerExposeModalProps) {
    const {
        visible,
        onDone,
        onClose,
        detail
    } = props;
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const onFinish = async (values: any) => {
        setSubmitting(true);
        const response = await updateBannerViewExpose(detail?.id, values.view_yn ? 1 : 0);
        setSubmitting(false);
        if (response) {
            onDone && onDone();
            _close();
        }
    }
    const onFailed = (data:any) => {
        console.log('failed', data);
    }
    const _close = () => {
        onClose && onClose(false);
    }
    useEffect(() => {
        if (visible) {
            form.setFieldsValue({
                view_yn: detail?.viewYn === 1
            })
        }
    }, [visible]);
    return (
        <Modal
            title="Edit Banner View Expose"
            visible={visible}
            centered={true}
            maskClosable={false}
            closable={false}
            className='form-modal'
            footer={null}
        >
            <Form labelCol={{span: 5}} wrapperCol={{span: 9}} form={form} name="edit-event-view-seq" onFinish={onFinish} onFinishFailed={onFailed}>
                <Form.Item
                    label="View Expose"
                    name="view_yn"
                    valuePropName="checked"
                    initialValue={detail?.viewYn === 1}
                >
                    <Switch id="view_yn" />
                </Form.Item>
            </Form>
            <Form.Item
                wrapperCol={{ span: 24 }}
                className="btn-wrap"
                style={{textAlign: 'end'}}
            >
                <Button htmlType="button" danger onClick={_close} disabled={submitting}>
                    취소
                </Button>
                <Button type="primary" onClick={() => form.submit()} htmlType="button" loading={submitting} disabled={submitting} >
                    저장
                </Button>
            </Form.Item>
        </Modal>
    );
}