import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Button, PageHeader } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AddPopupModal from './AddPopupModal';
import EditPopupModal from './EditPopupModal';
import { axiosGetallmanager } from "../../api/auth";
import Swal from "sweetalert2";
import { delSelectPopupRequest, getPopupRequest, getPopupResponse } from "../../api/popup";
import moment from "moment";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export type TableWrapProps = {
  type?: number;
}


export default function PopupList() {
  const [isOpen, setIsOpen] = useState(false);
  const [chkid, setChkid] = useState(-1);
  const [paramsData, setParamsData] = useState({});

  const [totalElements, setTotalElements] = useState(0);
  const [popupList, setPopupList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [queries, setQueries] = useState({
    search_count: 10,
    search_page: 1,
  });
  const [submitting, setSubmitting] = useState(false);

  const _deleteSelected = async () => {
    setSubmitting(true);
    await delSelectPopupRequest(selectedIds);
    setSubmitting(false);
    setShowDeleteConfirm(false);
    _loadPopupList();
  }

  const _loadPopupList = async () => {
    if (!loading) setLoading(true);
    const data: any = await getPopupRequest(queries);
    setLoading(false);
    if (!data) {
      return Swal.fire(
        "권한 없음",
        "Super 권한자만 접근 가능 합니다",
        "error"
      ).then(() => {
        // navigate("/manager/admin");
      });
    }
    setPopupList(data.content);
    setTotalElements(data.totalElements);
  }

  const onSelectionChanged = (data: any, detail: any) => {
    setSelectedIds(data);
  }

  const pageNation = async (page: number, detail: any) => {
    setQueries({
      ...queries,
      search_page: page + 1
    })
  };

  const clickbutton = (params: any, e: any) => {
    e.stopPropagation();
    // console.log('pr : ', params.row);
    // console.log('typeof:', typeof (params.row))
    // console.log('id', params.id);
    setIsOpen(true);
    setChkid(params.id);
    setParamsData(params.row);
  }

  const isClose = (pageNum: boolean) => {
    setIsOpen(pageNum);
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70, sortable: false, disableColumnMenu: true },
    {
      field: 'deleteYn', headerName: '삭제 여부', width: 100, sortable: false, disableColumnMenu: true,
      renderCell: (params) => (
        // NOTE: (kay) params 콘솔 찍어보면 데이터 나와요.
        <Tag color={params.formattedValue} >
          {
            params.formattedValue === 1 ? '게시중' : '삭제'
          }
        </Tag>
      ),
    },
    { field: 'title', headerName: '제목', width: 200, sortable: false, disableColumnMenu: true },
    { field: 'url', headerName: '링크 주소', width: 500, sortable: false, disableColumnMenu: true, },
    { field: 'startDate', headerName: '시작 날짜', type: 'date', width: 120, sortable: false, disableColumnMenu: true },
    { field: 'endDate', headerName: '종료 날짜', type: 'date', width: 120, sortable: false, disableColumnMenu: true, },
    {
      field: 'actions', headerName: '', width: 90, sortable: false, disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <Button type="primary" ghost onClick={(e) => clickbutton(params, e)}>
            수정
          </Button>
          {
            (isOpen && params.id === chkid) &&
            <EditPopupModal paramsData={paramsData} isOpen={isOpen} isClose={isClose} onDone={_loadPopupList} />
          }
        </>
      ),
    }
    // {
    //   field: 'fullName', headerName: 'Full name', description: 'This column has a value getter and is not sortable.', sortable: false, width: 160,
    //   valueGetter: (params: GridValueGetterParams) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  // const rows = [
  //   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  //   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  //   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  //   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  //   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  //   { id: 6, lastName: 'Melisandre', firstName: 'null', age: 150 },
  //   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  //   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  //   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  //   { id: 10, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  //   { id: 11, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  //   { id: 12, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  //   { id: 13, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  //   { id: 14, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  // ];

  const pageSize = 10;

  useEffect(() => {
    _loadPopupList();
  }, [queries]);

  // TODO: row click to - data callbak
  return (
    <div className="popup-page">
      <PageHeader
        title="팝업 관리"
        // breadcrumb={{ routes }}
        subTitle="팝업 목록"
      />
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <AddPopupModal onDone={_loadPopupList} />
        <Button
          type="primary" danger style={{ marginLeft: '8px' }} disabled={selectedIds.length === 0}
          onClick={() => setShowDeleteConfirm(true)}
        > 삭제 </Button>
      </div>
      <TableWrap type={pageSize}>
        <DataGrid
          className='popup-list-table'
          rows={popupList}
          columns={columns}
          pageSize={queries.search_count}
          rowCount={totalElements}
          loading={loading}
          pagination
          paginationMode="server"
          onPageChange={pageNation}
          onSelectionModelChange={onSelectionChanged}
          rowsPerPageOptions={[queries.search_count]}
          checkboxSelection
          onRowClick={(params, event) => {
            // NOTE: If you click it, data on rows
            // console.log(params.row.lastName);
          }}
        />
      </TableWrap>
      <Dialog
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          삭제
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            선택한 항목을 모두 삭제하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setShowDeleteConfirm(false)}>
            취소
          </Button>
          <Button onClick={_deleteSelected} loading={submitting} disabled={submitting} danger>
            확인하다
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const TableWrap = styled.div<TableWrapProps>`
  height: calc(${props => props.type} * 63px);
`

const Tag = styled.div<{ color?: number }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 30px; 
  font-size: 12px;
  color: ${props => props.color === 0 ? '#18AB56' : '#EB5757'};
  background: ${props => props.color === 0 ? '#F0FFF8' : '#FFF0F0'};
  border-radius: 8px;
  font-weight: 600;
  padding: 0 12px;

`