import React from 'react'
import styled from 'styled-components';

interface Props {
    label?: string
    content?: string | number | undefined
}

const Tag = styled.div<{ color?: string, background?: string}>`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	line-height: 30px;
	font-size: 12px;
	border-radius: 8px;
	font-weight: 600;
	padding: 0 12px;
	color: ${props => (props.color)};
	background:${props => (props.background)};
`;

export default function LabelContent(Props : Props) {
    const {label, content } = Props
    const handleTranslate = (content: string) =>{
      switch(content) {
        case 'STEP1_IN_PROGRESS' :
            return <Tag color='#FFBC10' background='#FEFFE5'>1차 진행중</Tag>
        case'STEP1_COMPLETED':
            return <Tag color='#FFBC10' background='#FEFFE5'>1차 대기</Tag>
        case 'STEP2_START':
            return <Tag color='#18AB56' background='#F0FFF8'>2차 진행중</Tag>
        case 'STEP2_IN_PROGRESS':
            return <Tag color='#18AB56' background='#F0FFF8'>2차 진행중</Tag>
        case 'STEP2_COMPLETED':
            return <Tag color='#18AB56' background='#F0FFF8'>2차 완료</Tag>
        case 'PROCESS_DONE':
            return  <Tag color='#1ABBB9' background='#EBFDFF'>실사완료</Tag>
        break;
        default:
          return content
      }
    }
          const Title = styled.p`
        font-size: 16px;
        color: black;
      `;
          const Content = styled.p`
            font-size: 16px;
            color: black;
            font-weight: 700;
          `;
          const Wrapper = styled.section`
            display: flex;
            gap:15px;
          `;
  return (
        <Wrapper>
            <Title>
                {label}
            </Title>
            <Content>
              {handleTranslate(content as string)}
            </Content>
        </Wrapper>
  )
}


