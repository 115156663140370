import React, { useEffect, useState } from "react";
import { PageHeader, Button } from "antd";
import { DataGrid, GridColDef, GridValueGetterParams, GridColumns, GridComparatorFn, GridEventListener } from "@mui/x-data-grid";
import styled from "styled-components";
import ConfirmDialog from "../../components/dialog/ConfirmDialog";
import { getRaiserList, deleteRaiser } from "../../api/raiser";
import Raiser from "../../interface/Raiser";
import Swal from "sweetalert2";
import { format } from "date-fns";
import Util from "../../utilities/Util";
import { useNavigate } from "react-router-dom";

export type TableWrapProps = {
	type?: number;
};

const rawData = [
	{
		raiserId: 1,
		createDt: "2022-04-07T14:53:53",
		raiserName: "장가",
		localName: "경기",
		farmer: "name1",
		phoneNum: "01012341234",
		progress: "STEP1_START",
	},
	{
		raiserId: 2,
		createDt: "2022-04-07T14:53:53",
		raiserName: "장가",
		localName: "경기",
		farmer: "name2",
		phoneNum: "01023452345",
		progress: "STEP2_COMPLETED",
	},
	{
		raiserId: 3,
		createDt: "2022-04-07T14:53:53",
		raiserName: "장가",
		localName: "경기",
		farmer: "name3",
		phoneNum: "01034563456",
		progress: "STEP3_COMPLETED",
	},
	{
		raiserId: 4,
		createDt: "2022-04-07T14:53:53",
		raiserName: "장가",
		localName: "경기",
		farmer: "name3",
		phoneNum: "01034563456",
		progress: "STEP2_IN_PROGRESS",
	},
	{
		raiserId: 5,
		createDt: "2022-04-07T14:53:53",
		raiserName: "장가",
		localName: "경기",
		farmer: "name3",
		phoneNum: "01034563456",
		progress: "STEP2_IN_PROGRESS",
	},
	{
		raiserId: 6,
		createDt: "2022-04-07T14:53:53",
		raiserName: "장가",
		localName: "경기",
		farmer: "name3",
		phoneNum: "01034563456",
		progress: "PROCESS_DONE",
	},
	{
		raiserId: 7,
		createDt: "2022-04-07T14:53:53",
		raiserName: "장가",
		localName: "경기",
		farmer: "name3",
		phoneNum: "01034563456",
		progress: "PROCESS_DONE",
	},
	{
		raiserId: 8,
		createDt: "2022-04-07T14:53:53",
		raiserName: "장가",
		localName: "경기",
		farmer: "name3",
		phoneNum: "01034563456",
		progress: "PROCESS_DONE",
	},
	{
		raiserId: 9,
		createDt: "2022-04-07T14:53:53",
		raiserName: "장가",
		localName: "경기",
		farmer: "name3",
		phoneNum: "01034563456",
		progress: "PROCESS_DONE",
	},
	{
		raiserId: 10,
		createDt: "2022-04-07T14:53:53",
		raiserName: "장가",
		localName: "경기",
		farmer: "name3",
		phoneNum: "01034563456",
		progress: "PROCESS_DONE",
	},
	{
		raiserId: 11,
		createDt: "2022-04-07T14:53:53",
		raiserName: "장가",
		localName: "경기",
		farmer: "name3",
		phoneNum: "01034563456",
		progress: "PROCESS_DONE",
	},
]


const _getProgressColor = (progress?: string) => {
	if (progress === 'STEP1_IN_PROGRESS' || progress === 'STEP1_COMPLETED')
		return '#FFBC10';
	else if (progress === 'STEP2_START' || progress === 'STEP2_IN_PROGRESS' || progress === 'STEP2_COMPLETED')
		return '#18AB56';
	else
		return '#1ABBB9';
}

const _getProgressBackgroundColor = (progress?: string) => {
	if (progress === 'STEP1_IN_PROGRESS' || progress === 'STEP1_COMPLETED')
		return '#FEFFE5';
	else if (progress === 'STEP2_START' || progress === 'STEP2_IN_PROGRESS' || progress === 'STEP2_COMPLETED')
		return '#F0FFF8';
	else
		return '#EBFDFF';
}

const _getProgressToKor = (progress?: string) => {
	if (progress === 'STEP1_IN_PROGRESS') 
		return '1차 진행중';
	else if (progress === 'STEP1_COMPLETED')
		return '1차 완료';
	else if (progress === 'STEP2_START' || progress === 'STEP2_IN_PROGRESS')
		return '2차 진행중';
	else if (progress === 'STEP2_COMPLETED')
		return '2차 완료';
	else
		return '실사완료';
}

const EntranceInq = () => {
	const navigate= useNavigate();
	const [loading, setLoading] = useState(true);
	const [list, setList] = useState<Raiser[]>(rawData);
	const [isOpen, setIsOpen] = useState(false);
	const [chkid, setChkid] = useState(-1);
	const [paramsData, setParamsData] = useState<Raiser[]>();
	const [queries, setQueries] = useState({
		search_page: 1,
		search_count: 10,
	});
	const [totalElements, setTotalElements] = useState(0);
	const [selectedIds, setSelectedIds] = useState([]);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const pageSize = 10;

	const customSortProgress: GridComparatorFn<any> = (v1, v2) => {
		let a = 0;
		let b = 0;
		switch (v1) {
			case 'STEP1_START':
				a = 9;
				break;
			case 'STEP2_START':
				a= 8;
				break;
			case 'STEP3_START':
				a = 7;
				break;
			case 'STEP1_IN_PROGRESS':
				a = 6;
				break;
			case 'STEP2_IN_PROGRESS':
				a= 5;
				break;
			case 'STEP3_IN_PROGRESS':
				a = 4;
				break;
			case 'STEP1_COMPLETED':
				a= 3;
				break;
			case 'STEP2_COMPLETED':
				a= 2;
				break;
			case 'STEP3_COMPLETED':
				a= 1;
				break;
			default:
				a = 0;
				break;
		}
		switch (v2) {
			case 'STEP1_START':
				b= 9;
				break;
			case 'STEP2_START':
				b= 8;
				break;
			case 'STEP3_START':
				b= 7;
				break;
			case 'STEP1_IN_PROGRESS':
				b= 6;
				break;
			case 'STEP2_IN_PROGRESS':
				b= 5;
				break;
			case 'STEP3_IN_PROGRESS':
				b= 4;
				break;
			case 'STEP1_COMPLETED':
				b = 3;
				break;
			case 'STEP2_COMPLETED':
				b = 2;
				break;
			case 'STEP3_COMPLETED':
				b = 1;
				break;
			default:
				b= 0;
				break;
		}
		return a-b;
	}

	const columns: GridColDef[] = [
		{
			field: "raiserId",
			headerName: "ID",
			width: 100,
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: "progress",
			headerName: "상태",
			width: 200,
			sortable: true,
			disableColumnMenu: true,
			renderCell: ({ formattedValue }) => {
				return <Tag progress={formattedValue}>
					{
						(_getProgressToKor(formattedValue))
					}
				</Tag>
			},
			sortComparator: customSortProgress
		},
		{
			field: "createDt",
			headerName: "등록일자",
			width: 200,
			sortable: true,
			disableColumnMenu: true,
			renderCell: ( { formattedValue} ) => {
				return <>
					{
						formattedValue && format(new Date(formattedValue), "yyyy.MM.dd HH:MM:ss")
					}
				</>
			}
		},
		{
			field: "raiserName",
			headerName: "농장명",
			width: 170,
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: "localName",
			headerName: "지역",
			width: 170,
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: "farmer",
			headerName: "이름",
			width: 170,
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: "phoneNum",
			headerName: "휴대전화번호",
			width: 170,
			sortable: false,
			disableColumnMenu: true,
		},
	];

	useEffect(() => {
		_loadData();
	},[queries])

	const _loadData = async () => {
		if (!loading) setLoading(true);
		const res = await getRaiserList(queries);
		setLoading(false);
		if (res) {
			setList(res.content);
			setTotalElements(res.totalElements);
		}

	}

	const _deleteSelected = async () => {
		setSubmitting(true);
		const res = await deleteRaiser(selectedIds);
		
		setSubmitting(false);
		setShowDeleteConfirm(false);
		if (res) {
			Swal.fire("삭제 완료", "고객센터 삭제가 완료되었습니다.", "success");
		} else {
			Swal.fire({
				icon: "error",
				title: "삭제 실패",
				text: "고객센터 삭제를 실패하였습니다."
			})
		}
		_loadData();
	}

	const pageNation = (page: number) => {
		setQueries({
			...queries,
			search_page: page + 1,
		})
	}

	const onSelectionChanged = (data: any) => {
		setSelectedIds(data);
	}

	const onHandleRowClick = ({ id }: any, { detail }: any) => {
		if (detail === 2) {
			navigate(`/manager/admin/raiser/${id}`)
		}
		return;
	}

	return (
		<>
			<div>
				<PageHeader
					title="입점문의"
				// subTitle="입점/제휴 목록"
				/>
			</div>

			<div style={{ margin: "20px 0" }} className="subTitle">
				<p style={{ fontWeight: 700, fontSize: "14px" }}>입점 문의 목록</p>
			</div>
			
			<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '20px' }}>
				<Button style={{ marginLeft: '8px', backgroundColor: 'rgba(0, 16, 61, 0.06)', display: 'flex', justifyContent: 'center', alignItems: 'center' }} disabled={selectedIds.length === 0} onClick={() => { setShowDeleteConfirm(true)}}
				> 삭제 </Button>
			</div>

			{/* Table */}
			<TableWrap type={pageSize}>
				<DataGrid
					className="popup-list-table"
					rows={list}
					getRowId={(row) => row.raiserId}
					rowsPerPageOptions={[queries.search_count]}
					columns={columns}
					initialState={{
						sorting: {
							sortModel: [
								{
									field: 'progress',
									sort:'desc'
								}
							]
						}
					}}
					pageSize={queries.search_count}
					checkboxSelection
					pagination
					paginationMode="server"
					onPageChange={pageNation}
					rowCount={totalElements}
					onSelectionModelChange={onSelectionChanged}
					loading={loading}
					onRowClick={onHandleRowClick}
				/>
			</TableWrap>

			<ConfirmDialog
				visible={showDeleteConfirm}
				onClose={() => setShowDeleteConfirm(false)}
				title="삭제"
				content="선택한 항목을 모두 삭제하시겠습니까?"
				onConfirm={_deleteSelected}
				// loading={submitting}
			/>
		
		</>
	);
};

const TableWrap = styled.div<TableWrapProps>`
	height: calc(${(props) => props.type} * 63px);
`;

const Tag = styled.div<{ progress: string }>`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	line-height: 30px;
	font-size: 12px;
	border-radius: 8px;
	font-weight: 600;
	padding: 0 12px;
	color: ${props => (_getProgressColor(props.progress))};
	background: ${props => (_getProgressBackgroundColor(props.progress))};
`;

export default EntranceInq;
