import {CKEditor, CKEditorProps} from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import {MyCustomUploadAdapterPlugin} from "../../classes/MyUploadAdapter";

const config = {
    // @ts-ignore
    extraPlugins: [ MyCustomUploadAdapterPlugin ],
    toolbar: {
        items: [
            'heading', '|',
            'fontsize', '|',
            'alignment', '|',
            'fontColor', 'fontBackgroundColor', '|',
            'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', 'specialCharacters', '|',
            'highlight', 'horizontalLine', '|',
            'link', '|',
            'outdent', 'indent', '|',
            'bulletedList', 'numberedList', 'todoList', '|',
            'code', 'codeBlock', '|',
            'insertTable', '|',
            'uploadImage', 'mediaEmbed', 'blockQuote', 'htmlEmbed', '|',
            'pageBreak', 'removeFormat', 'sourceEditing', '|',
            'undo', 'redo'
        ],
        shouldNotGroupWhenFull: true
    }
}

export default function CustomCKEditor (props: CKEditorProps) {
    // @ts-ignore
    return <CKEditor editor={ Editor } {...props} config={config} />
}