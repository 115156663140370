import axiosClient from "./axiosClient";
import axios, { AxiosRequestConfig, Method } from "axios";

type RequestType = {
  url: string;
  method?: "POST" | "GET" | "PUT" | Method;
  params?: any;
  data?: any;
  successMessage?: string | undefined;
  headers?: any;
} & AxiosRequestConfig;

const _renewAccessToken = async () => {
  const refreshToken = sessionStorage.getItem("refreshToken");
  try {
    // @ts-ignore
    const res = await axios({
      url: `${process.env.REACT_APP_BASE_API}manager/renew/token`,
      method: "GET",
      headers: { refreshToken },
    });
    const result = res.data;
    if (result?.data?.result) {
      sessionStorage.setItem("token", result?.data?.message);
    }
    return result;
  } catch (e) {
    console.error("catch", JSON.stringify(e));
  }
};

const httpRequest = async ({
  url,
  method = "GET",
  params,
  data,
  successMessage,
  ...rest
}: RequestType): Promise<any> => {
  let result;
  try {
    const res = await axiosClient.request({
      url,
      method,
      params,
      data,
      ...rest,
    });
    result = res?.data?.data;
    if (!result) {
      result = res?.data;
    }
  } catch (error: any) {
    if (error?.response?.data?.code === "TK004") {
      const res = await _renewAccessToken();
      if (res && res.status === "OK" && res.data?.result) {
        const originalRequest = error?.config;
        try {
          const res = await axiosClient.request(originalRequest);
          result = res?.data?.data;
          if (!result) {
            result = res?.data;
          }
        } catch (e: any) {
          result = false;
          // document.location.href="/";
        }
      }
    } else if (error?.response?.data?.code === "TK003") {
      result = "SUPER 계정만 접근 가능합니다.";
    } else if (error?.response?.data?.code === "TK002") {
      result = "권한이 없는 사용자입니다.";
    }
  }
  return result;
};

export default httpRequest;
