import moment from "moment";
import {upperCase} from "lodash";

function stripHtml(html: string) {
    return Util.stripHtml(html).replace(/\n/g, '').replace(/\r/g, '');
}

function getCategoryName (key: string) {
    switch (key) {
        case 'INVESTMENT':return '뱅카우 개선사항 건의';
        case 'BUG': return '뱅카우 사용 시 불편사항';
        case 'ETC': return '뱅카우 사용 시 불편사항';
        default:return '';
    }
}

function qnaJsonToCsvData(data: any): string {
    if (!data) return '';
    let str = '';
    const headers = data.headers;
    if (headers) {
        str += `${headers?.category||''},${headers?.name||''},${headers?.phoneNumber||''},`;
        str += `${headers?.email||''},${headers?.title||''},${headers?.content||''},`;
        str += `${headers?.replyChecked||''},${headers?.replyType||''},,`;
        str += '답변,답변일자,답변자 이름,\r\n';
    }
    const qna = data.qnaExcelResults;
    if (qna) {
        qna.forEach((row: any) => {
            const excelDto = row.qnaExcelDto;
            const replyExcelDto = row.qnaReplyExcelDto;
            str += `${getCategoryName(excelDto.category)},${excelDto.qnaName||''},${excelDto.phoneNumber||''},`;
            str += `${excelDto.email||''},${excelDto.title||''},${JSON.stringify(stripHtml(excelDto.message||''))},`;
            str += `${excelDto.checked ? "TRUE" : "FALSE"},${excelDto.replyType||''},,`;
            str += `${JSON.stringify(stripHtml(replyExcelDto.answer||''))},${replyExcelDto.createDate ? moment(replyExcelDto.createDate).format('YYYY-MM-DD') : ''},${replyExcelDto.managerName||''},\r\n`;
        })
    }
    return Util.utf8(str);
}

function farmQnaJsonToCsvData(data: any): string {
    if (!data) return '';
    let str = '';
    const headers = data.headers;
    if (headers) {
        str += `${headers?.name||''},${headers?.phoneNumber||''},`;
        str += `${headers?.email||''},${headers?.farmName||''},${headers?.farmAddress||''},`;
        str += `${headers?.replyChecked||''},${headers?.replyCreateDt||''},,`;
        str += '답변,답변일자,답변자 이름,\r\n';
    }
    const qna = data.farmQnaExcelResults;
    if (qna) {
        qna.forEach((row: any) => {
            const excelDto = row.farmQnaExcelDto;
            const replyExcelDto = row.farmQnaReplyExcelDto;
            str += `${excelDto.farmQnaName||''},${excelDto.phoneNumber||''},`;
            str += `${excelDto.email||''},${excelDto.farmName||''},${JSON.stringify(excelDto.farmAddress)||''},`;
            str += `${`${excelDto.checked}`.toUpperCase()},${excelDto.createDate ? moment(replyExcelDto.createDate).format('YYYY-MM-DD') : ''},,`;
            str += `${JSON.stringify(stripHtml(replyExcelDto.answer||''))},${replyExcelDto.createDate ? moment(replyExcelDto.createDate).format('YYYY-MM-DD') : ''},${replyExcelDto.managerName||''},\r\n`;
        })
    }
    return Util.utf8(str);
}

const Util = {
    isValidURL(string: string): boolean {
        const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return (res !== null)
    },
    downloadQnaCSV(data: any): Promise<void> {
        return new Promise((resolve) => {
            const csv = qnaJsonToCsvData(data);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const exportedFilename = `qna-${new Date().getTime()}.csv`;

            // @ts-ignore
            if (window.navigator?.msSaveBlob) { // IE 10+
                // @ts-ignore
                window.navigator.msSaveBlob(blob, exportedFilename);
            } else {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = exportedFilename;
                link.click();
                window.URL.revokeObjectURL(url);
            }
            resolve();
        });
    },
    downloadFarmQnaCSV(data: any): Promise<void> {
        return new Promise((resolve) => {
            const csv = farmQnaJsonToCsvData(data);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const exportedFilename = `farm-qna-${new Date().getTime()}.csv`;

            // @ts-ignore
            if (window.navigator?.msSaveBlob) { // IE 10+
                // @ts-ignore
                window.navigator.msSaveBlob(blob, exportedFilename);
            } else {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = exportedFilename;
                link.click();
                window.URL.revokeObjectURL(url);
            }
            resolve();
        });
    },
    utf8(str: string) {
        return '\uFEFF' + str;
    },
    stripHtml(html: string) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }
};

export default Util;