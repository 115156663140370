import {Button, Form, Modal, Select} from "antd";
import BhEvent from "../../../interface/BhEvent";
import React, {useEffect, useState} from "react";
import {
    axiosEditEventImageExpose, axiosEditEventViewExpose,
} from "../../../api/event";

type EditEventExportTypeModalProps = {
    visible: boolean;
    onDone?: VoidFunction;
    onClose?: (val?: boolean) => void;
    detail?: BhEvent;
};

const optionConfig = {
    rules: [{ type: 'string' as const, required: true, message: '노출여부를 선택해주세요' }],
};

export default function EditEventViewModal(props: EditEventExportTypeModalProps) {
    const {
        visible,
        onDone,
        onClose,
        detail
    } = props;
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const onFinish = async (values: any) => {
        console.log("onFinish", JSON.stringify(values));
        // return;
        setSubmitting(true);
        const response = await axiosEditEventViewExpose(detail?.id, values.view_yn);
        setSubmitting(false);
        if (response) {
            onDone && onDone();
            _close();
        }
    }
    const onFailed = (data:any) => {
        console.log('failed', data);
    }
    const _close = () => {
        onClose && onClose(false);
    }
    useEffect(() => {
        if (visible) {
            form.setFieldsValue({
                view_yn: `${detail?.viewYn}`
            })
        }
    }, [visible]);
    return (
        <Modal
            title="Edit Event View Yn"
            visible={visible}
            centered={true}
            maskClosable={false}
            closable={false}
            className='form-modal'
            footer={null}
        >
        <Form labelCol={{span: 5}} wrapperCol={{span: 9}} form={form} name="edit-event-view-yn" onFinish={onFinish} onFinishFailed={onFailed}>
            <Form.Item label="노출 여부" name="view_yn" initialValue={`${detail?.viewYn}`} {...optionConfig}>
                <Select>
                    <Select.Option value={'0'}>노출멈춤</Select.Option>
                    <Select.Option value={'1'}>노출</Select.Option>
                </Select>
            </Form.Item>
        </Form>
        <Form.Item
            wrapperCol={{ span: 24 }}
            className="btn-wrap"
            style={{textAlign: 'end'}}
        >
            <Button htmlType="button" danger onClick={_close} disabled={submitting}>
                취소
            </Button>
            <Button type="primary" onClick={() => form.submit()} htmlType="button" loading={submitting} disabled={submitting} >
                저장
            </Button>
        </Form.Item>
        </Modal>
    );
}