import {DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams} from '@mui/x-data-grid';
import {Button, PageHeader, Popconfirm} from "antd";
import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import Swal from "sweetalert2";
import {
  axiosChangeAuthority, axiosDeleteManager,
  axiosGetallmanager,
  Imanager
} from "../../api/auth";
import AddUserListModal from './AddUserListModal';
import moment from "moment";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {InfoOutlined} from "@mui/icons-material";

export type TableWrapProps = {
  type?: number;
}

const pageSize= 10;

const UserList = () => {
  const [totalpage, setTotalpage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [currpage, setCurrpage] = useState(0);
  const [userlist, setUserlist] = useState<Imanager[]>([]);
  const [queries, setQueries] = useState({
    search_count: 10,
    search_page: 1,
  });
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [userDetail, setUserDetail] = useState<Imanager|null>(null);

  const navigate = useNavigate();

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70, sortable: false, disableColumnMenu: true },
    { field: 'email', headerName: '이메일', width: 200, sortable: false, disableColumnMenu: true },
    { field: 'username', headerName: '사용자 이름', width: 130, sortable: false, disableColumnMenu: true },
    { field: 'managerStatus', headerName: '매니저 권한', width: 130, sortable: false, disableColumnMenu: true, },
    {
      field: 'create_date', headerName: '생성 날짜', width: 150, sortable: false, disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => moment(params.row.create_date).format("DD MMM YYYY, hh:mm a")
    },
    {
      field: 'edit', headerName: '', width: 70, sortable: false, disableColumnMenu: true,
      renderCell : (params) =>
          <Button type="primary" ghost onClick={(e) => _onInfoClick(params, e)}>수정</Button>
    },
  ];

  const _onInfoClick = (params: GridRenderCellParams, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setUserDetail(params.row);
  }

  const _deleteSelected = async () => {
    setSubmitting(true);
    for (let i = 0; i < selectedIds.length; i++) {
      await axiosDeleteManager(selectedIds[i]);
    }
    setSubmitting(false);
    setShowDeleteConfirm(false);
    _loadUserList();
  }

  const onSelectionChanged = (data:any, detail: any) => {
    setSelectedIds(data);
  }

  const pageNation = async (page: number, detail: any) => {
    setQueries({
      ...queries,
      search_page: page+1
    })
  };

  // const giveAuthority = async (id: number) => {
  //   const result = await Swal.fire({
  //     title: "이 계정에 admin권한을 부여 하시겠습니까?",
  //     confirmButtonText: "권한 부여",
  //     showCancelButton: true
  //   });
  //   if (result.isConfirmed) {
  //     const res = await axiosChangeAuthority(id);
  //     if (res) {
  //       return pageNation(currpage);
  //     }
  //   }
  // };

  const _loadUserList = async () => {
    if (!loading) setLoading(true);
    const data = await axiosGetallmanager(queries);
    setLoading(false);
    if (!data) {
      return Swal.fire(
          "권한 없음",
          "Super 권한자만 접근 가능 합니다",
          "error"
      ).then(() => {
        // navigate("/manager/admin");
      });
    } else if (data === "SUPER 계정만 접근 가능합니다." || data === "권한이 없는 사용자입니다.") {
      return Swal.fire(
          "권한 없음",
          data,
          "error"
      ).then(() => {
        navigate("/manager/admin");
      });
    }
    setUserlist(data.content);
    setTotalpage(data.totalPages);
    setTotalElements(data.totalElements);
  }

  useEffect(() => {
    _loadUserList();
  }, [queries]);
  return (
    <>
      <PageHeader
        title="계정 관리"
        // breadcrumb={{ routes }}
        subTitle="계정 목록"
      />
      <div style={{display: "flex", marginBottom: "20px"}}>

        <AddUserListModal onDone={_loadUserList} userDetail={userDetail} onClose={() => setUserDetail(null)} />
        <Button
            type="primary" danger style={{marginLeft: '8px'}} disabled={selectedIds.length === 0}
            onClick={() => setShowDeleteConfirm(true)}
        > 삭제 </Button>
      </div>
      <TableWrap type={queries.search_count < 10 ? 10 : queries.search_count}>
        <DataGrid
          className='popup-list-table'
          rows={userlist}
          rowCount={totalElements}
          columns={columns}
          page={queries.search_page-1}
          pageSize={queries.search_count}
          rowsPerPageOptions={[queries.search_count]}
          checkboxSelection
          loading={loading}
          onPageChange={pageNation}
          onSelectionModelChange={onSelectionChanged}
          paginationMode="server"
          pagination
          disableSelectionOnClick
        />
      </TableWrap>
      <Dialog
          open={showDeleteConfirm}
          onClose={() => setShowDeleteConfirm(false)}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          삭제
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            선택한 항목을 모두 삭제하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setShowDeleteConfirm(false)}>
            아니오
          </Button>
          <Button onClick={_deleteSelected} loading={submitting} disabled={submitting} danger>
            예
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const TableWrap = styled.div<TableWrapProps>`
  height: calc(${props => props.type} * 63px);
`

export default UserList;
