import { Object } from 'lodash';
import React,{useState, useEffect} from 'react'
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import {Spin} from "antd";

interface Props {
    label?:string
    buttonLabel?:string
    inputImgLabel?:string
    img?: any
    handleDownloadAllImg: any
    loadingButton: boolean
}


const Wrapper = styled.section`
  display: flex;
  width:618px;
  height:212px;
  background-color: #F5F5F5;
  flex-direction: colunm;
  gap:8px;
`;
const WrapperLabel = styled.section`
  display: flex;
  width:618px;
  height:40px;
  flex-direction: colunm;
  justify-content: space-between
`;

const ImgImport = styled.img`
  width:240px;
  height:180px;
  position: relative;
  top:16px;
  left:16px;
`;


const Text = styled.p`
font-size: 14px;
color:#999999
`;

const Button = styled.button`
width: 122px;
height:30px;
border-radius: 4px;
background-color: rgba(0, 16, 61, 0.06);
border-style: none;
`;

const InputPicture = styled.input`
width: 81px;
height:36px;
border-radius: 30px;
background-color: rgba(0, 16, 61, 0.06);
border-style: none;
position: relative;
left: 510px;
top: -123px;
`;
const ButtonImgs = styled.button`
width: 81px;
height:36px;
border-radius: 30px;
background-color: rgba(0, 16, 61, 0.06);
border-style: none;
position: relative;
left: 510px;
top: -123px;
color:#707070
`;

export default function UploadPicture(Props:Props) {
    const {label, buttonLabel, img , handleDownloadAllImg, loadingButton}=Props
    const [files,setFile]=useState<any>([]) 
    const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
    const [selectedPicture, setSelectedPicture] =useState<any>([])
    const [isLoading,setIsLoading] =useState(false)
    const handlerFile=(e:any)=>{   
      setFile([...e.target.files]); 
    }

    useEffect(() => {
        if(files.length > 1)return;
        let newArrImg : any=[...selectedPicture]
        files.forEach((file:any) => newArrImg.push(URL.createObjectURL(file)))
        setSelectedPicture(newArrImg)
    },[files])
    useEffect(() => {
      if(selectedPicture.length > 2){
        setSelectedPicture(selectedPicture.slice(0,2))
      }
  },[selectedPicture])

    const handleDeleteImg = (index:number) =>{
      return (event: React.MouseEvent) => {
        setSelectedPicture(selectedPicture.slice(0,index))
        console.log('delete',index)  
      }
    }
    const handleDownloadAllImage =(img: any) =>{
      setIsLoading(true)
      handleDownloadAllImg(img)
    }
    useEffect(()=>{
        setTimeout(() => {
          setIsLoading(false)
        },3000)
    },[isLoading])

  return (
    <div>
         <WrapperLabel>
            <Text>{label}</Text>
            {isLoading?<Spin indicator={antIcon} />:<Button onClick={()=>handleDownloadAllImage(img)}>{buttonLabel}</Button>}
        </WrapperLabel>
        <Wrapper>
            {img?.map((x:any,index:number)=>
                <ImgImport alt='농장사진' src={x.imageUrl
                } key={index} onClick={handleDeleteImg(index)}/>
            )}
        </Wrapper>
        <ButtonImgs>...외 N장</ButtonImgs>
    </div>
  )
}
