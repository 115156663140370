import AWS from 'aws-sdk';

AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
});

const cloudFront = process.env.REACT_APP_AWS_CLOUD_FRONT;
const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME;
const saveType = 'application/octet-stream';


export const fileDownloadFromS3 = async (fileUrl: string, originalFileName: string) => {
    try {
        const s3 = new AWS.S3();
        const replaceFileKey = fileUrl.replace(cloudFront as string, '');

        const { Body } = await s3.getObject({Bucket: bucketName as string, Key: replaceFileKey as string }).promise();

        if (!Body) {
            return "파일을 불러오지 못했습니다.";
        }

        let blob = new Blob([Body as BlobPart], { type: saveType });
        downloadBlob(blob, originalFileName);
    }  catch (error) {
        console.error("error => ", JSON.stringify(error));
        return error;
    }
}

function downloadBlob(blob: any, originalFileName: string) {
    if (blob === undefined) return;

    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = originalFileName;
    document.body.appendChild(link);

    link.click();
    link.remove();

    setTimeout((_) => {
      window.URL.revokeObjectURL(blobUrl);
    }, 2000);
  }