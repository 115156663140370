import { useState } from "react";
import TopAppBar from "../components/layout/TopAppBar";
import LeftGNB from "../components/layout/LeftGNB";
import MainArea from "../components/MainArea";
import styled from 'styled-components';

const Main = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TopAppBar isOpen={isOpen} setIsOpen={setIsOpen} />
      <MainContent>
        {/* <LeftGNB isOpen={isOpen} setIsOpen={setIsOpen} /> */}
        <LeftGNB />
        <MainArea />
      </MainContent>
    </>
  );
};

const MainContent = styled.div`
  display: flex;
  width: 100%;
  margin-top: 65px;
  height: calc(100vh - 65px);
  overflow: hidden;
`

export default Main;
