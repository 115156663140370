import axios from "axios";
import qs from "qs";
import axiosClient from "../service/axiosClient";
import httpRequest from "../service/httpRequest";

interface FarmQna {
  id: number;
  farmQnaName: string;
  phoneNumber: string;
  email: string;
  farmName: string;
  farmAddress: string;
  cowNum: number;
  feedName: string;
  checked: boolean;
  availableDate: string;
  createDate: string;
}

const errorMessage = (error: any) => {
  if (axios.isAxiosError(error)) {
    console.log(error.message);
  } else {
    throw new Error("different error than axios");
  }
};
export interface FarmQnaResponse {
  data: {
    content: FarmQna[];
    totalPages: number;
  };
}

export interface defaultResponse {
  data: {
    result: boolean;
    message: string;
  };
  status: string;
}

interface clientQnaList {
  createDate: string;
  faqCategory: string;
  id: number;
  message: string;
  title: string;
  updateData: Date;
  username: string;
}

export interface dataResponse {
  data: {
    totalElements: number;
    result: boolean;
    message: string;
    content: clientQnaList[];
  };
  status: string;
}


export interface DeleteFarmRequest {
  id: number[];
}

export interface FarmQnaDetail {
  data: {
    id: number;
    farmQnaName: string;
    phoneNumber: string;
    email: string;
    farmName: string;
    farmAddress: string;
    cowNum: number;
    feedName: string;
    checked: boolean;
    availableDate: string;
    createDate: string;
  };
}

export interface FarmQnaDetailResponse {
  data: {
    result: boolean;
    message: string;
  };
  status: string;
}

export const getFarmRequest = async (page: number) => {
  try {
    return await httpRequest({
      url: `farmqna`,
      method: "get",
      params: {page}
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const getClientFaqs = async (queries: any) => {
  try {
    return await httpRequest({
      url: `faq/public/list`,
      method: "post",
      data: queries
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const getAdminFaqs = async (queries: any) => {
  try {
    return await httpRequest({
      url: `faq/list`,
      method: "post",
      data: queries
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const getClientFaqDetail = async (id:any) => {
  try {
    return await httpRequest({
      url: `faq/public/${id}`,
      method: "get",
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const getAdminFaqDetail = async (id:any) => {
  try {
    return await httpRequest({
      url: `faq/${id}`,
      method: "get",
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const addFaq = async (requestData: any) => {
  try {
    return await httpRequest({
      url: `faq/add`,
      method: "post",
      data: requestData
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const updateFaq = async (id:any, requestData: any) => {
  try {
    return await httpRequest({
      url: `faq/${id}`,
      method: "put",
      data: requestData
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const deleteFaqs = async (list:any) => {
  try {
    return await httpRequest({
      url: `faq/list`,
      method: "delete",
      data: {ids: list}
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const deleteFarmRequest = async (
  deleteFarmRequest: DeleteFarmRequest
) => {
  try {
    return await httpRequest({
      url: `farmqna/delete`,
      method: "delete",
      data: deleteFarmRequest
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const getFarmRequestDetail = async (id: string | undefined) => {
  try {
    return await httpRequest({
      url: `farmqna/${id}`,
      method: "get",
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const deleteFarmRequestDetail = async (id: string | undefined) => {
  try {
    return await httpRequest({
      url: `farmqna/${id}`,
      method: "delete",
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const addQnaRequest = async (formData: any) => {
  try {
    const res = await httpRequest({
      url: `qna/add`,
      method: "post",
      data: formData,
    });
    return res.result;
  } catch (error) {
    errorMessage(error);
  }
};



export const getQnaList = async (queries: any) => {
  try {
    return await httpRequest({
      url: `qna`,
      method: "get",
      params: queries,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const getQnaDetail = async (id: any) => {
  try {
    return await httpRequest({
      url: `qna/${id}`,
      method: "get",
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const postQnaAnswer = async (id: any, requestData: any) => {
  try {
    return await httpRequest({
      url: `qna/${id}/reply`,
      method: "post",
      data: requestData,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const deleteQnas = async (ids: any[],) => {
  try {
    return await httpRequest({
      url: `qna/delete`,
      method: "delete",
      data: {id: ids},
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const downloadQnaCSV = async () => {
  try {
    return await httpRequest({
      url: `qna/excel`,
      method: "get",
      responseEncoding: 'utf-8',
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.message);
    } else {
      throw new Error("different error than axios");
    }
  }
};
