import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Button, PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import Swal from "sweetalert2";
import {
  axiosEditStatus,
  axiosGetReview,
  axiosRemoveOneReview,
  axiosRemoveReviews,
  Ireview
} from "../../api/reviewer";
import AddReviewModal from './AddReviewModal';
import EditReviewModal from './EditReviewModal';
import moment from "moment";
import ConfirmDialog from "../../components/dialog/ConfirmDialog";

export type TableWrapProps = {
  type?: number;
}

const pageSize= 10;

const ReviewList = () => {
  const [checked, setChecked] = useState<number[]>([]);

  const [list, setList] = useState<Ireview[]>([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [currpage, setCurrpage] = useState(0);
  const [totalpage, setTotalpage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const [chkid, setChkid] = useState(-1);
  const [paramsData, setParamsData] = useState({})

  const [loading, setLoading] = useState(false);
  const [queries, setQueries] = useState({
    search_page: 1,
    search_count: 10,
  });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const clickbutton = (params: any, e: any) => {
    e.stopPropagation();
    // console.log('pr : ', params.row);
    // console.log('typeof:', typeof (params.row))
    // console.log('id', params.id);
    setIsOpen(true);
    setChkid(params.id);
    setParamsData(params.row);
  }

  const isClose = (pageNum: boolean) => {
    setIsOpen(pageNum);
  }

  const handleCheck = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      // 선택됨 목록에 없으면
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const pageNation = async (page: number) => {
    setQueries({
      ...queries,
      search_page: page+1,
    })
  };

  const onSelectionChanged = (data:any) => {
    setSelectedIds(data);
  }

  const _deleteSelected = async () => {
    if (!selectedIds.length) return;
    let res;
    setSubmitting(true);
    if (1 === selectedIds.length) {
      res = await axiosRemoveOneReview(selectedIds[0]);
    } else {
      res = await axiosRemoveReviews(selectedIds);
    }
    setSubmitting(false);
    setShowDeleteConfirm(false);
    if (res) {
      Swal.fire("삭제 완료", "리뷰 삭제가 완료되었습니다.", "success");
      _loadData();
    } else {
      Swal.fire({
        icon: "error",
        title: "삭제 실패",
        text: "리뷰 삭제를 실패하였습니다."
      });
    }
  };

  const _loadData = async () => {
    if (!loading) setLoading(true);
    const data = await axiosGetReview(queries);
    setLoading(false);
    if (data) {
      setList(data.content);
      setTotalpage(data.totalPages);
      setTotalElements(data.totalElements);
    }
  };

  useEffect(() => {
    _loadData();
  }, [queries]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70, sortable: false, disableColumnMenu: true },
    { field: 'buyerName', headerName: '고객명', width: 130, sortable: false, disableColumnMenu: true },
    // { field: 'raiserName', headerName: 'Raiser name', width: 130, sortable: false, disableColumnMenu: true },
    // { field: 'cattleName', headerName: 'Cattle name', width: 110, sortable: false, disableColumnMenu: true, },
    { field: 'comment', headerName: '코멘트', width: 200, sortable: false, disableColumnMenu: true, },
    { field: 'reviewDate', headerName: '리뷰 날짜', width: 130, sortable: false, disableColumnMenu: true, },
    { field: 'createBy', headerName: '생성 권한', width: 130, sortable: false, disableColumnMenu: true, },
    {
      field: 'createDate', headerName: '생성일', width: 170, sortable: false, disableColumnMenu: true,
      valueGetter: (params) => moment(params.row.createDate).format("DD MMM YYYY, hh:mm a")
    },
    {
      field: 'actions', headerName: '이벤트', width: 90,
      sortable: false, disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <Button type="primary" ghost onClick={(e) => clickbutton(params, e)}>
            수정
          </Button>
          {
            (isOpen && params.id === chkid) &&
            <EditReviewModal paramsData={paramsData} isOpen={isOpen} isClose={isClose} onDone={_loadData} />
          }
        </>
      ),
    }
    // {
    //   field: 'fullName', headerName: 'Full name', description: 'This column has a value getter and is not sortable.', sortable: false, width: 160,
    //   valueGetter: (params: GridValueGetterParams) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  return (
    <div>
      <PageHeader
        // className="고객 후기 관리"
        title="고객 후기 관리"
        // breadcrumb={{ routes }}
        subTitle="고객 후기 목록"
      />

      <div style={{display: "flex", marginBottom: "20px"}}>
        <AddReviewModal onDone={_loadData} />
        <Button type="primary" danger style={{marginLeft: '8px'}}
                disabled={selectedIds.length === 0}
                onClick={() => setShowDeleteConfirm(true)}
        > 삭제 </Button>
      </div>

      <TableWrap type={pageSize}>
        <DataGrid
          className='review-list-table'
          rows={list}
          columns={columns}
          pageSize={queries.search_count}
          rowCount={totalElements}
          loading={loading}
          pagination
          paginationMode="server"
          onPageChange={pageNation}
          onSelectionModelChange={onSelectionChanged}
          rowsPerPageOptions={[queries.search_count]}
          checkboxSelection
        />
      </TableWrap>
      <ConfirmDialog
          visible={showDeleteConfirm}
          onClose={() => setShowDeleteConfirm(false)}
          title="삭제"
          content="선택한 항목을 모두 삭제하시겠습니까?"
          onConfirm={_deleteSelected}
          loading={submitting}
      />
    </div>
  );
};

const TableWrap = styled.div<TableWrapProps>`
  height: calc(${props => props.type} * 63px);
`

export default ReviewList;
