import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import "antd/dist/antd.css";
import { Button, DatePicker, Form, Input, Modal, Select, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { axiosAddManager, axiosChangeAuthority, axiosChangePassword, Imanager } from "../../api/auth";
import Swal from "sweetalert2";

// const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

type AddUserListModalProps = {
  onDone?: VoidFunction;
  onClose?: VoidFunction;
  userDetail?: Imanager | null;
}

enum Privileges {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
}

const managerStatus = [
  {
    label: 'Admin',
    value: Privileges.ADMIN,
  },
  {
    label: 'Manager',
    value: Privileges.MANAGER,
  }
]

const AddUserListModal = ({ onDone, userDetail, onClose }: AddUserListModalProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(Boolean(userDetail));
  const [form] = Form.useForm();

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
    onClose && onClose();
    if (isUpdate) {
      onReset();
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    setVisible(Boolean(userDetail));
    setIsUpdate(Boolean(userDetail));
    if (userDetail) {
      form.setFieldsValue(userDetail)
    }
  }, [userDetail])

  const onFinish = async (fieldsValue: any) => {
    const values = {
      ...fieldsValue,
    };
    console.log('values', values);
    setSubmitting(true);
    if (isUpdate) {
      if (userDetail?.id) {
        const data = await axiosChangePassword({ password1: values.password, password2: values.confirmpassword, sendEmail: values.email });
        if (!data) {
          return Swal.fire(
            "오류",
            "프로세스 실패",
            "error"
          ).then(() => { hideModal(); });
        }
        if (data) {
          return Swal.fire(
            "성공",
            "비밀번호가 변경되었습니다",
            "success"
          ).then(() => { hideModal(); setSubmitting(false); });
        }
      }
    } else {
      const data = await axiosAddManager(values);
      if (!data) {
        return Swal.fire(
          "오류",
          "프로세스 실패",
          "error"
        ).then(() => {

        });
      }
    }
    setSubmitting(false);
    onDone && onDone();
    hideModal();
    onReset();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <Modal
        title={isUpdate ? "사용자 수정" : "사용자 추가"}
        visible={visible}
        // onOk={hideModal}
        // onCancel={hideModal}
        centered={true}
        maskClosable={false}
        closable={false}
        // okText="确认"
        // cancelText="取消"
        className='form-modal'
        footer={null}
        destroyOnClose
      >
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item
            label="유저 이름"
            name="username"
            rules={isUpdate ? [] : [{ required: true, message: '사용자 이름을 입력하십시오' }]}
          >
            <Input type="text" id="username" readOnly={isUpdate} />
            {/* <Input type="text" id="username" /> */}
          </Form.Item>
          <Form.Item
            label="이메일"
            name="email"
            htmlFor="email"
            rules={isUpdate ? [] : [{ required: true, message: 'email을 입력해 주세요' }]}
          >
            <Input id="email" type="email" readOnly={isUpdate} />
          </Form.Item>
          <Form.Item
            label="권한"
            name="managerStatus"
            rules={[{ required: true, message: '역할을 선택하세요.' }]}
            htmlFor="managerStatus"
          >
            <Select id="managerStatus" options={managerStatus} />
          </Form.Item>
          {
            isUpdate && (
              <Form.Item
                label="비밀번호 수정"
                name="password"
                rules={isUpdate ? [{ required: true, message: '수정할 비밀번호를 입력해 주세요' }] : []}
                htmlFor="password"
              >
                <Input id="password" type="password" />
              </Form.Item>
            )
          }
          {
            isUpdate && (
              <Form.Item
                label="비밀번호 확인"
                name="confirmpassword"
                rules={isUpdate ? [{ required: true, message: '다시 비밀번호를 입력해 주세요' }] : []}
                htmlFor="confirmpassword"
              >
                <Input id="confirmpassword" type="password" />
              </Form.Item>
            )
          }
          {
            !isUpdate && (
              <Form.Item
                label="비밀번호"
                name="password"
                rules={isUpdate ? [] : [{ required: true, message: '비밀번호를 입력해 주세요' }]}
                htmlFor="password"
              >
                <Input id="password" type="password" />
              </Form.Item>
            )
          }
          <Form.Item
            {...tailLayout}
            className="btn-wrap"
          >
            {!isUpdate && (
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
            )}
            <Button htmlType="button" danger onClick={hideModal} >
              취소
            </Button>
            <Button type="primary" htmlType="submit" loading={submitting} disabled={submitting} >
              저장
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={showModal} type="primary">추가</Button>
    </div>
  );
}

export default AddUserListModal;