import axios from "axios";
import qs from "qs";
import axiosClient from "../service/axiosClient";
import httpRequest from "../service/httpRequest";

interface defaultResponse {
  data: {
    result: boolean;
    message: string;
  };
  status: string;
}

export interface Ireview {
  id: number;
  title: string;
  status: boolean;
  buyer_name: string;
  user_name: string;
  farm_name: string;
  create_date: string;
}

interface Ipagenation {
  data: {
    content: Ireview[];
    number: number;
    totalElements: number;
    totalPages: number;
  };
  status: string;
}

// 리뷰 정보 불러오기
export const axiosGetReview = async (queries:any) => {
  try {
    return await httpRequest({
      url: `buyer/list`,
      method: "get",
      params: queries,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

export interface reviewDetail {
  id: number;
  title: string;
  content: string;
  status: boolean;
  buyer_name: string;
  user_name: string;
  farm_name: string;
  create_date: string;
}

interface IreviewDetail {
  data: reviewDetail;
  status: string;
}

// 상세 페이지
export const axiosGetReviewDetail = async (id: any) => {
  try {
    return await httpRequest({
      url: `buyer/detail/${id}`,
      method: "get",
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

interface IDist {
  id: number;
  status: boolean;
}

// 리뷰 배포 유무 수정
export const axiosEditStatus = async (datas: IDist) => {
  try {
    const res = await httpRequest({
      url: `buyer/edit`,
      method: "patch",
      data: datas
    });
    return res.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

// 단일 삭제
export const axiosRemoveOneReview = async (id: number) => {
  try {
    const res = await httpRequest({
      url: `buyer/${id}`,
      method: "delete",
    });
    return res.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

// 복수 삭제
export const axiosRemoveReviews = async (id: number[]) => {
  try {
    const res = await httpRequest({
      url: `buyer/list`,
      method: "delete",
      data: { ids: id },
    });
    return res.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const axiosEditReview = async (id: number | undefined, requestData: any) => {
  try {
    const res = await httpRequest({
      url: `buyer/${id}`,
      method: "put",
      data: requestData,
    });
    return res.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const axiosAddReview = async (requestData: any) => {
  try {
    const res = await httpRequest({
      url: `buyer/add`,
      method: "post",
      data: requestData,
    });
    return res.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

