import React, { useEffect, useState } from 'react'
import { PageHeader } from "antd";
import { Button } from 'antd';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Input, Space } from 'antd';
import styled from 'styled-components';
import DetailPartnerModal from './DetailPartnerModal';
import ConfirmDialog from "../../components/dialog/ConfirmDialog";
import {deleteQnas, downloadQnaCSV} from "../../api/qna";
import {deleteFarmQnaList, downloadFarmQnaCSV, getFarmQnaList} from "../../api/partner";
import FarmQna from "../../interface/FarmQna";
import Util from "../../utilities/Util";

const { Search } = Input;
const onSearch = (value: string) => console.log(value);

export type TableWrapProps = {
  type?: number;
}

const Partner = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [chkid, setChkid] = useState(-1);
  const [paramsData, setParamsData] = useState<FarmQna>();

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<FarmQna[]>([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [queries, setQueries] = useState({
    search_count: 10,
    page: 0
  });
  const [totalElements, setTotalElements] = useState(0);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    _loadData();
  }, [queries]);

  const _downloadCSV = async () => {
    setDownloading(true);
    const res = await downloadFarmQnaCSV();
    await Util.downloadFarmQnaCSV(res);
    setDownloading(false);
  }

  const _deleteSelected = async () => {
    setSubmitting(true);
    await deleteFarmQnaList(selectedIds);
    setSubmitting(false);
    setShowDeleteConfirm(false);
    _loadData();
  }

  const _loadData = async () => {
    if (!loading) setLoading(true);
    const res = await getFarmQnaList(queries);
    setLoading(false);
    if (res) {
      setList(res.content);
      setTotalElements(res.totalElements);
    }
    // setCallData(res && res?.data?.content);
  }

  // console.log('list', list);

  const pageNation = (page: number) => {
    setQueries({
      ...queries,
      page: page,
    })
  }

  const onSelectionChanged = (data: any) => {
    setSelectedIds(data);
  }

  const clickbutton = (params: any, e: any) => {
    e.stopPropagation();
    // console.log('pr : ', params.row);
    // console.log('typeof:', typeof (params.row))
    // console.log('id', params.id);
    setIsOpen(true);
    setChkid(params.id);
    setParamsData(params.row);
  }

  const isClose = (pageNum: boolean) => {
    setIsOpen(pageNum);
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70, sortable: false, disableColumnMenu: true },
    // { field: 'email', headerName: '이메일', width: 250, sortable: false, disableColumnMenu: true },
    { field: 'farm_qna_name', headerName: '이름', width: 130, sortable: false, disableColumnMenu: true },
    { field: 'phone_number', headerName: '휴대전화번호', width: 130, sortable: false, disableColumnMenu: true },
    { field: 'farm_name', headerName: '농장 이름', width: 170, sortable: false, disableColumnMenu: true },
    { field: 'farm_address', headerName: '농장 주소', width: 170, sortable: false, disableColumnMenu: true, },
    // { field: 'cow_num', headerName: '사육 두수', width: 100, sortable: false, disableColumnMenu: true, },
    {
      field: 'checked', headerName: '확인', width: 130,
      valueFormatter: (params) => params.value ? '답변완료' : '답변대기'
    },
    {
      field: 'actions', headerName: '상세보기', width: 110,
      renderCell: (params) => (
        <>

          {
            params.row.checked ?
              <Button type="primary" ghost onClick={(e) => clickbutton(params, e)}>
                답변보기
              </Button> :
              <Button type="primary" onClick={(e) => clickbutton(params, e)}>
                답변하기
              </Button>
          }
          {
            (isOpen && params.id === chkid) &&
            <DetailPartnerModal paramsData={paramsData} isOpen={isOpen} isClose={isClose} onDone={_loadData} onClose={() => setParamsData(undefined)} />
          }
        </>
      ),
    }
    // {
    //   field: 'fullName', headerName: 'Full name', description: 'This column has a value getter and is not sortable.', sortable: false, width: 160,
    //   valueGetter: (params: GridValueGetterParams) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  const pageSize = 10;

  return (
    <>
      <div>
        <PageHeader
          // className=""
          title="입점 문의"
          // breadcrumb={{ routes }}
          subTitle="입점/제휴 목록"
        />
      </div>
      {/* <Space direction="vertical">
        <Search
          placeholder="input search text"
          allowClear
          onSearch={onSearch}
          style={{
            width: 200,
          }}
        />
      </Space> */}
      <div style={{ display: "flex", justifyContent: 'space-between', marginBottom: "20px" }}>
        <Button
          type="primary" danger style={{ marginLeft: '8px' }} disabled={selectedIds.length === 0}
          onClick={() => setShowDeleteConfirm(true)}
        > 삭제 </Button>
        <Button
            type="primary" style={{ marginRight: '8px', marginLeft: '8px' }}
            onClick={_downloadCSV} loading={downloading} disabled={downloading}
        > Export CSV </Button>
      </div>
      
      <TableWrap type={pageSize}>
        <DataGrid
          className='popup-list-table'
          rows={list}
          rowCount={totalElements}
          columns={columns}
          pageSize={queries.search_count}
          rowsPerPageOptions={[queries.search_count]}
          loading={loading}
          onPageChange={pageNation}
          onSelectionModelChange={onSelectionChanged}
          paginationMode="server"
          pagination
          checkboxSelection
        // onSelectionChanged={onSelectionChanged}
        // onSelectionModelChange={(newSelection:any) => {
        //   setSelection(newSelection.rows);
        // }}
        // onRowClick={()=>test(rows.values())}
        // selectionModel={select}
        />
      </TableWrap>
      <ConfirmDialog
        visible={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        title="삭제"
        content="선택한 항목을 모두 삭제하시겠습니까?"
        onConfirm={_deleteSelected}
        loading={submitting}
      />
    </>
  )
}

const TableWrap = styled.div<TableWrapProps>`
  height: calc(${props => props.type} * 63px);
`

export default Partner