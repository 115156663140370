import axios from "axios";
import qs from "qs";
import axiosClient from "../service/axiosClient";
import httpRequest from "../service/httpRequest";

export const getBannerList = async (queries: any) => {
    try {
        return await httpRequest({
            url: `banner/admin/list`,
            method: "get",
            params: queries,
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};

export const getBannerDetail = async (id: any) => {
    try {
        return await httpRequest({
            url: `banner/detail/${id}`,
            method: "get",
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};

export const addBanner = async (formData:any) => {
    try {
        return await httpRequest({
            url: `banner/add`,
            method: "post",
            data: formData
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};

export const updateBannerEvent = async (id: any, eventId: any) => {
    try {
        return await httpRequest({
            url: `banner/update/${id}/event/${eventId}`,
            method: "patch",
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};

export const updateBannerViewSeq = async (id: any, order?: any) => {
    try {
        return await httpRequest({
            url: `banner/update/${id}/view/order`,
            method: "patch",
            params: {
                view_seq: order
            }
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};

export const updateBannerViewExpose = async (id: any, expose?: any) => {
    try {
        return await httpRequest({
            url: `banner/update/${id}/view/expose`,
            method: "patch",
            params: {
                view_yn: expose
            }
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};

export const updateBannerImage = async (id: any, formRequest: any) => {
    try {
        return await httpRequest({
            url: `banner/update/${id}/image`,
            method: "post",
            data: formRequest
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};

export const deleteBanner = async (id: any) => {
    try {
        return await httpRequest({
            url: `banner/delete/${id}`,
            method: "delete",
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};