/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {PageHeader,Spin} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import {UpdateVisitDate,VisitDate,UpdateProcess2Type,UpdateProgressRaiser,GetDetailRaiser,RaiserDetail,DownloadFileImg,DownloadFileImgType, RaiserImageList, imageDownloadApi} from '../api/raiser'
import Divider from '@mui/material/Divider';
import LabelContent from '../components/Label/label'
import UploadPicture from '../components/input/UploadPicture'
import styled from 'styled-components';
import StatusCheck from './StatusCheck'
import ComfirmModal from '../components/Modal/ComfirmModal';
import { useParams,useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { fileDownloadFromS3 } from '../service/awsConfig';


const Wrapper = styled.section`
  display: flex;
  gap:40px;
  margin-top: 20px;
  margin-bottom: 20px
`;

const WrapperInput = styled.section`
  display: flex;
  gap:30px;
  margin-top: 20px;
`;

const TitlePage = styled.h1`
  font-size: 25px;
  font-weight: 700;
  color: black;
`;
const Loading = styled.div`
  display:flex;
  justify-content: center;
  position: relative;
  top: 200px
`;


export default function EntranceInjuryPage() {
  const antIcon = <LoadingOutlined style={{ fontSize: 70 }} spin />;
  const [raiser,setRaisers] = useState<RaiserDetail>() 
  const [raiserImages, setRaiserImages] = useState<RaiserImageList[]>([]);
  const {id}=useParams()
  const navigate= useNavigate()
  const [visitDate, setVisitDate] =useState('')
  const [loading , setLoading] =useState(false)
  const [waitDownload,setWaitDownload]=useState(false)
  const [fileDownload,setFileDownload] = useState([]) as any
  const [refreshPage,setFreshPage]=useState(false)

  const DetailRaiser = async() => {
    setLoading(true)
    try{
      const respone = await GetDetailRaiser(Number(id))
      setRaisers(respone);
      setRaiserImages(respone?.raiserImages);
      if (respone){
        setLoading(false);
      } 
      else{
        navigate(-1);
      }
    }catch(e){
      console.log(e); 
    }
  } 

  const _downloadFiles = async (imageType: string) => {
    
    const images = raiserImages.filter(r => r.imageType === imageType);

    for (let i = 0; i < images.length; i++) {
      const imageUrl = images[i].imageUrl;
      const originalFileName = images[i].originalImageName;

      if (imageUrl === undefined) {
        alert("파일 URL 오류 !");
        return;
      }
      if (originalFileName === undefined) {
        alert("파일 origin name 오류 !");
        return;
      }
      fileDownloadFromS3(imageUrl, originalFileName);
    }
  }

  useEffect( () => {
    DetailRaiser()
  },[refreshPage])

  const firstLabel = [
      {label:'ID',content:raiser?.id},
      {label:'등록일자',content: raiser?.createDt?.slice(0,10)},
      {label:'상태',content: raiser?.inProgress},
      {label:'2차 제출 일자', content: `${raiser?.step2CompletedDate && raiser?.step2CompletedDate?.split("T")[0]} ${raiser?.step2CompletedDate && raiser?.step2CompletedDate?.split("T")[1]}`},
  ]
  const secondLabel = [
    {label:'이용약관동의(필수)',content:`${raiser?.serviceTerms1 === true ? 'O' : raiser?.serviceTerms1 === false ? 'X' : ''}`},
    {label:'개인정보 처리방침(필수)',content:`${raiser?.serviceTerms2 === true ? 'O' : raiser?.serviceTerms2 === false ? 'X' : ''}`},
    {label:'개인정보 수집이용(선택)',content:`${raiser?.serviceTerms3 === true ? 'O' : raiser?.serviceTerms3 === false ? 'X' : ''}`},
  ]
  const thirdLabel = [
    {label:'이름',content: `${raiser?.identification ? raiser?.name : raiser?.applicantName}`},
    {label:'농장주', content: `${(raiser?.identification === true) ? 'O' : (raiser?.identification === false) ? 'X' : ''}`}, 
    {label:'e-mail',content:raiser?.email},
    {label:'휴대전화번호',content: raiser?.phoneNumber},
  ]
  const fourthLabel = [
    {label:'농장주',content:raiser?.name},
    {label:'농장명',content: raiser?.raiserName},
    {label:'지역',content:raiser?.raiserProvince},
    {label:'주소',content:raiser?.raiserAddress},
  ]
  const fifthLabel = [
    {label:'자가여부',content: `${(raiser?.ownRaiser === true) ? 'O' : (raiser?.ownRaiser === false) ? 'X' : ''}`},  /// what mean on data respone
    {label:'법인사업자 여부',content:`${(raiser?.corporateBusinessOwners === true) ? 'O' : (raiser?.corporateBusinessOwners === false) ? 'X' : ''}`},
    {label:'농장유형',content: raiser?.breedingType},
    {label:'규모',content:raiser?.population},
    {label:'사료',content:raiser?.fodder}, /// not have respone 
  ]
  const handleUpdateProcess2 = async () =>{
    try{   const params :UpdateProcess2Type={
      inProgress:"STEP2_START"
    }
    await UpdateProgressRaiser(Number(id),params)
    Swal.fire(
      "SUCCESS",
      "THIS PAGE WILL RELOAD")
      window.location.reload()
    }catch{
      Swal.fire(
        "ERROR",
        "ERROR")
    }
  }
  const handleVisitDate = async () =>{
    if(visitDate === '') return Swal.fire(
      "실사 일자를 확인해 주세요.",
      "ERROR"
    )
    if (raiser?.investigationRequestDate && raiser?.investigationRequestDate?.indexOf(visitDate) <= -1) {
      return Swal.fire(
        `실자 일자가 가능한 날이 아닙니다. 선택한 날짜: ${visitDate}`,
        "ERROR"
      )
    }
    try{
      const params :VisitDate={
        visitDate: visitDate
      }
      await UpdateVisitDate(Number(id),params)
      Swal.fire(
        "SUCCESS",
        "SUCCESS")
        setVisitDate('')
        setFreshPage(true)
    }catch{
      Swal.fire(
        "ERROR",
        "ERROR")
    }
   
  }
  const handleUpdateProcessDone = async () =>{
    try{   const params :UpdateProcess2Type={
      inProgress:"PROCESS_DONE"
    }
    await UpdateProgressRaiser(Number(id),params)
    Swal.fire(
      "SUCCESS",
      "THIS PAGE WILL RELOAD")
      window.location.reload()
    }catch{
      Swal.fire(
        "ERROR",
        "ERROR")
    }
  }
  const handleSetFileDownload = (e: any) => {
          const tempList = [...fileDownload];
          tempList.push(e);
          setFileDownload(tempList)
  }
  const handleDownloadFile = async (singleFile: string) =>{
    try{
      const params :DownloadFileImgType={
        file: singleFile
      }
      await DownloadFileImg(Number(id),params)
      Swal.fire(
        "SUCCESS",
        "SUCCESS")
    }catch{
      Swal.fire(
        "ERROR",
        "ERROR")
    }
  }
  const handleDownloadAllFile = async () =>{
    setWaitDownload(true)
    try{
      const params :DownloadFileImgType={
        file: `${raiser?.raiserFiles?.map((f: any)=>(
            f.fileUrl
        ))}`
      }
      await DownloadFileImg(Number(id),params)
      Swal.fire(
        "SUCCESS",
        "SUCCESS")
      setWaitDownload(false)
    }catch{
      setWaitDownload(false)
      Swal.fire(
        "ERROR",
        "ERROR")
    }
  }
  const handleDownloadAllImg = async (f: any) =>{
    try{
      const params :DownloadFileImgType={
        file: `${f?.map((f: any)=>(
            f.imageUrl
        ))}`
      }
      await DownloadFileImg(Number(id),params)
      Swal.fire(
        "SUCCESS",
        "SUCCESS")
    }catch{
      Swal.fire(
        "ERROR",
        "ERROR")
    }
}
  return (
    <>
    {loading ? <Loading><Spin indicator={antIcon} /></Loading> : 
     <div>
     <TitlePage>입점문의내용</TitlePage>
      <ComfirmModal handleUpdateProcess2={handleUpdateProcess2} disabled={raiser?.inProgress ==='STEP1_COMPLETED' ? true : false} inProgress={raiser?.inProgress} position='1164px' approvedbutton='승인하기' titlepopup='승인하기' content='해당 입점신청의 1차승인을 진행하시겠습니까?'/>
     <Wrapper>
       {firstLabel.map((x:any)=> 
           <LabelContent 
           label={x.label}
           content={x.content}
           />
       )}
     </Wrapper>
     <Divider/>
     <PageHeader
       title="약관동의 정보"
     />
      <Wrapper>
       {secondLabel.map((x:any)=>
           <LabelContent 
           label={x.label}
           content={x.content}
           />
       )}
     </Wrapper>
     <Divider/>
     <PageHeader
       title="개인 정보"
     />
      <Wrapper>
       {thirdLabel.map((x:any)=>
           <LabelContent 
           label={x.label}
           content={x.content}
           />
       )}
     </Wrapper>
     <Divider/>
     <PageHeader
       title="농장 정보"
     />
       <Wrapper>
         {fourthLabel.map((x:any)=>
             <LabelContent 
             label={x.label}
             content={x.content}
             />
         )}
       </Wrapper>
       <Wrapper>
        {fifthLabel.map((x:any)=>
           <LabelContent 
           label={x.label}
           content={x.content}
           />
       )}
     </Wrapper>
      <Divider/>

      <PageHeader
       title="농장사진"
     />
     <WrapperInput>
         <UploadPicture
          loadingButton = {waitDownload}
          label='농장 외부'
          buttonLabel='전체 다운로드'
          img={raiser?.raiserImages?.filter((x:any)=>x.imageType === "FARM_OUTSIDE")}
          handleDownloadAllImg={() => _downloadFiles('FARM_OUTSIDE')}
         />
         <UploadPicture
          loadingButton = {waitDownload}
          handleDownloadAllImg={() => _downloadFiles('FARM_INSIDE')}
          label='농장 내부'
          buttonLabel='전체 다운로드'
          img={raiser?.raiserImages?.filter((x:any)=>x.imageType === "FARM_INSIDE")}
         />
     </WrapperInput>
      <PageHeader
       title="비품사진"
     />
       <WrapperInput>
         <UploadPicture
         loadingButton = {waitDownload}
         handleDownloadAllImg={() => _downloadFiles("BUCKET")}
         label='물통'
         buttonLabel='전체 다운로드'
         img={raiser?.raiserImages?.filter((x:any)=>x.imageType === "BUCKET")}
         />
         <UploadPicture
          handleDownloadAllImg={() => _downloadFiles('FLOOR')}
          loadingButton = {waitDownload}
         label='우사바닥'
         buttonLabel='전체 다운로드'
         img={raiser?.raiserImages?.filter((x:any)=>x.imageType === "FLOOR")}
         />
     </WrapperInput>
     <WrapperInput>
         <UploadPicture
          handleDownloadAllImg={() => _downloadFiles('VENTILATION_FAN')}
         label='환풍기 '
         loadingButton = {waitDownload}
         buttonLabel='전체 다운로드'
         img={raiser?.raiserImages?.filter((x:any)=>x.imageType === "VENTILATION_FAN")}
         />
         <UploadPicture
         loadingButton = {waitDownload}
          handleDownloadAllImg={() => _downloadFiles('LIVESTOCK_PERMIT')}
         label='축산업 허가증'
         buttonLabel='전체 다운로드'
         img={raiser?.raiserImages?.filter((x:any)=>x.imageType === "LIVESTOCK_PERMIT")}
         />
     </WrapperInput> 
     <StatusCheck 
        status={true} 
        handleVisitDate={handleVisitDate} 
        inProgress={raiser?.inProgress} 
        files={raiser?.raiserFiles}  
        visitDateButtonActive={raiser?.visitDateButtonActive}
        investigationRequestDate={raiser?.investigationRequestDate}
        processDoneDate={raiser?.processDone}
        pickedVisitDate={raiser?.visitDate}
        processDone={raiser?.processDone}

        handleSetFileDownload={handleSetFileDownload}    
        handleDownloadFile={handleDownloadFile} 
        loadingButton = {waitDownload}
        handleDownloadAllFile={handleDownloadAllFile}
        handleUpdateProcessDone={handleUpdateProcessDone}
        setVisitDate={setVisitDate} 
        visitDate={visitDate}/>
   </div>
    
    }
    </>
  )
}
