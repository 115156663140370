import { useEffect, useState } from "react";
import { Grid, Box, Typography, Pagination, Checkbox } from "@mui/material";
import GTHeader from "../../components/gridtable/GTHeader";
import GridItem from "../../components/gridtable/GTItem";
import GTselector from "../../components/gridtable/GTselector";
import {
  axiosGetAllEvent,
  axiosRemoveEvents,
  axiosRemoveOneEvent,
} from "../../api/event";
import { RootState } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { setEventlist, setTotalpage } from "../../slices/event/eventSlice";
import { format } from "date-fns";
import Swal from "sweetalert2";

const Event = () => {
  const dispatch = useDispatch();
  const [isDelete, setIsDelete] = useState(false);
  const [checked, setChecked] = useState<number[]>([]);
  const totalpage = useSelector((state: RootState) => state.event.totalpage);
  const eventlist = useSelector((state: RootState) => state.event.eventlist);
  const [queries, setQueries] = useState({
    search_page: 1,
    search_count: 10,
  })

  const handleCheck = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    // 목록에 없으면
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const pageNation = async (page: number) => {
    setQueries({
      ...queries,
        search_page: page + 1,
    })
  };

  const deleteEvent = async () => {
    if (!checked.length) return;
    let res;
    if (1 === checked.length) {
      res = await axiosRemoveOneEvent(checked[0]);
    } else {
      res = await axiosRemoveEvents(checked);
    }
    if (res) {
      Swal.fire("삭제 완료", "이벤트 삭제가 완료되었습니다.", "success");
      const data = await axiosGetAllEvent(0);
      if (data) {
        dispatch(setEventlist(data.content));
        dispatch(setTotalpage(data.totalPages));
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "삭제 실패",
        text: "이벤트 삭제를 실패하였습니다.",
      });
    }
  };

  const _loadData = async () => {
    const data = await axiosGetAllEvent(queries);
    if (data) {
      dispatch(setEventlist(data.content));
      dispatch(setTotalpage(data.totalPages));
    }
  }

  useEffect(() => {
    _loadData();
  }, [queries]);

  return (
    <Box sx={{ height: "100%", position: "relative" }}>
      <Typography variant="h4" component="h2">
        이벤트 관리
      </Typography>
      <GTselector
        isDelete={isDelete}
        setIsDelete={setIsDelete}
        setChecked={setChecked}
        delfunc={deleteEvent}
      />
      <GTHeader>
        <GridItem das={1}>ID</GridItem>
        <GridItem das={4}>제목</GridItem>
        <GridItem das={3}>이벤트 기간</GridItem>
        <GridItem das={1}>상태</GridItem>
        <GridItem das={1}>작성자</GridItem>
        <GridItem das={2}>작성일</GridItem>
      </GTHeader>
      {eventlist.map((item) => (
        <Grid
          key={item.id}
          container
          sx={{
            height: "40px",
            marginTop: 0.5,
            backgroundColor: "#e8e8e8",
          }}
        >
          {isDelete ? (
            <Grid item xs={1} sx={{ textAlign: "center" }}>
              <Checkbox
                onClick={handleCheck(item.id)}
                checked={checked.indexOf(item.id) !== -1}
                tabIndex={-1}
                disableRipple
              />
            </Grid>
          ) : (
            <GridItem das={1}>{item.id}</GridItem>
          )}
          <GridItem das={4} id={item.id}>
            {item.title}
          </GridItem>
          <GridItem das={3}>{`${item.startDate} ~ ${item.endDate}`}</GridItem>
          <GridItem das={1}>{`${item?.status}`}</GridItem>
          <GridItem das={1}>{item?.username}</GridItem>
          <GridItem das={2}>
            {format(new Date(item.createDate), "yyyy.MM.dd HH:MM")}
          </GridItem>
        </Grid>
      ))}
      <Pagination
        count={totalpage}
        onChange={(event, page) => pageNation(page - 1)}
        color="primary"
        size="large"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          bottom: 80,
        }}
      />
    </Box>
  );
};

export default Event;
