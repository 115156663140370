import React,{ useEffect, useState} from 'react'
import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import {PageHeader,Spin} from "antd";


const fileMap = new Map();
fileMap.set('STRUCTURAL_DIAGRAM', '축사 시설 배치도/평면도');
fileMap.set('SHIPPING_REPORT', '전년도 1년간 출하 성적서');
fileMap.set('APPRAISED_VALUE_LAND', '공시지가 확인원');
fileMap.set('CERTIFIED_COPY_LAND_REGISTER', '토지/축사 등기부등본');

interface Props {
    file?: any,
    handleDownloadFile: any
}

const TypeFiled = styled.div`
background-color: rgba(0, 16, 61, 0.06);
border-radius: 100px;
width: atuo;
align-items: end;
`;


const TitleType = styled.div`
display:flex;
gap:10px;
`;

const LabelTypeFile = styled.p`
cursor: pointer;
position:relative;
top:9px;
color: #2C2D2E;
font-weight: 400;
font-size: 15px;
`;

export default function TypeFileComponent(Props:Props) {
    const {file,handleDownloadFile} =Props
    const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
    const [isLoading,setIsLoading] =useState(false)
    const handleDownloadSingleFile = (file : any) =>{
            setIsLoading(true)
            handleDownloadFile(file)
    }
    useEffect(()=>{
        setTimeout(() => {
          setIsLoading(false)
        },3000)
    },[isLoading])
    
  return (
    <div>
        <TitleType>
        <Checkbox  color="default" size="small" value={file.fileUrl}/>
            <LabelTypeFile onClick={()=>handleDownloadSingleFile(file.fileUrl)}>
            {isLoading ? <Spin indicator={antIcon} />: fileMap.get(file.fileType)} 
            </LabelTypeFile>
        </TitleType>
        <br/>
        <TypeFiled>{file.originalFileName}</TypeFiled>
</div>
  )
}
