import React ,{useState,useEffect} from 'react'
import {PageHeader,Spin} from "antd";
import styled from 'styled-components';
import Divider from '@mui/material/Divider';
import ComfirmModal from '../components/Modal/ComfirmModal';
import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import TypeFileComponent from './TypeFile';
import { fileDownloadFromS3 } from '../service/awsConfig';
import { fileListProps } from '../api/raiser';

interface Props {
    status?: boolean
    setVisitDate?: any
    handleVisitDate?: ()=>{}
    handleUpdateProcessDone?: ()=>{}
    inProgress?: string
    files?: fileListProps[]
    visitDateButtonActive?: boolean
    handleSetFileDownload?: any
    handleDownloadFile: (singleFile: string)=> void
    handleDownloadAllFile: any
    loadingButton: boolean
    visitDate?: string
    investigationRequestDate?: string
    processDoneDate?: string
    pickedVisitDate?: string
    processDone?: string
}

    const Wrapper = styled.div`
    display: flex;
    gap:10px;
    margin-top:50px
    `;

    const DateLabel = styled.p`
    font-weight: 500;
    font-size: 16px;
    color: #999999;
    `;
    const DatePicked = styled.p`
    font-weight: 700;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.3);
    `;

    const Button = styled.button`
    width: 122px;
    height:30px;
    border-radius: 4px;
    background-color: rgba(0, 16, 61, 0.06);
    border-style: none
    `;

    const TypeFiled = styled.div`
    background-color: rgba(0, 16, 61, 0.06);
    border-radius: 100px;
    width: atuo;
    align-items: end;
    `;

    const ChooseFileOption = styled.div`
    display:flex;
    flex-direction: colunm;
    gap:20px;
    margin-bottom:30px
    `;
    const Pictkime = styled.div`
    display:flex;
    flex-direction: colunm;
    margin-top:20px;    
    gap:100px 
    `;

    const DatePickerDisplay = styled.div`
    display:flex;
    gap:20px 
    `;
    const LabelTypeFile = styled.p`
     cursor: pointer;
     position:relative;
     top:9px;
     color: #2C2D2E;
     font-weight: 400;
     font-size: 15px;
    `;
    const TitleType = styled.div`
    display:flex;
    gap:10px;
   `;

export default function StatusCheck(Props:Props) {
    const {
        setVisitDate,
        status,
        handleVisitDate,
        inProgress,
        files,
        visitDateButtonActive,
        handleSetFileDownload,
        handleDownloadFile,
        visitDate,
        handleDownloadAllFile,
        loadingButton,
        handleUpdateProcessDone, 
        investigationRequestDate,
        processDoneDate,
        pickedVisitDate,
        processDone
    }= Props;

    const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
    const onChange: DatePickerProps['onChange'] = (date, dateString) => {

    };
    const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
        setVisitDate(dateString)
    };

    const _downloadFiles = async (fileType?: string) => {
        if (fileType === undefined) return;
        if (files === undefined) return;

        const fileList = files.filter(f => f.fileType === fileType);
    
        for (let i = 0; i < fileList.length; i++) {
            const fileUrl = fileList[i].fileUrl;
            const originalFileName = fileList[i].originalFileName;

            if (fileUrl === undefined) {
                alert("파일 URL 오류 !");
                return;
            }
            if (originalFileName === undefined) {
                alert("파일 origin name 오류 !");
                return;
            }
            fileDownloadFromS3(fileUrl, originalFileName);
        }
    }

    const _downloadAllFiles = async () => {
        if (files === undefined) return;

        for (let i = 0; i < files.length; i++) {
            const fileUrl = files[i].fileUrl;
            const originalFileName = files[i].originalFileName;

            if (fileUrl === undefined) {
                alert("파일 URL 오류 !");
                return;
            }
            if (originalFileName === undefined) {
                alert("파일 origin name 오류 !");
                return;
            }
            fileDownloadFromS3(fileUrl, originalFileName);
        }
    }
    
    let investigation_date_arr: string[] = [];
    if (investigationRequestDate) {
        investigationRequestDate.split(",").forEach(date => investigation_date_arr.push(date));
    }

    const ButtonConfirm = styled.button`
    width: 66px;
    height: 30px;
    background: #005FF9;
    border-radius: 4px;
    border-style: none;
    color:#FFFFFF;
    opacity: ${inProgress ==='STEP2_COMPLETED' ?'1':'0.48'};
    cursor: ${inProgress  ==='STEP2_COMPLETED' ?'pointer':'not-allowed'};
    `;
    const ButtonDiligence  = styled.button`
    width: 92px;
    height: 30px;
    background:${visitDateButtonActive?'#005FF9':'rgba(0, 16, 61, 0.06)'}; 
    border-radius: 4px;
    border-radius: 4px;
    border-style: none;
    color:#FFFFFF;
    opacity: ${visitDateButtonActive?'1':'0.48'};
    cursor: ${visitDateButtonActive ?'pointer':'not-allowed'};
    `;

  return (
    <>
    {status &&
    <div>
        <Wrapper>
            <PageHeader
                title= '서류'
            />
             {loadingButton?<Spin indicator={antIcon} />:<Button onClick={() => _downloadAllFiles()}>전체 다운로드</Button>}
        </Wrapper>
        <ChooseFileOption>
                {files?.map((f)=>(
                    <TypeFileComponent
                        file={f}
                        handleDownloadFile={() => _downloadFiles(f?.fileType)}
                    />
                ))}
        </ChooseFileOption>
        <Divider/>
        <Pictkime>
            <div>
                <PageHeader
                    title="신청 시 선택한 실사일정"
                />
                <DatePickerDisplay>
                    {investigation_date_arr && investigation_date_arr.length > 0 ? ( 
                        investigation_date_arr.map((date, idx) => <input key={idx} type="date" value={date} /> )
                        // <DatePicker key={idx} onChange={onChange} value={date} />
                        // <DatePicker onChange={onChange} />
                        // <DatePicker onChange={onChange} />
                    ) : (
                        <>
                            <DatePicker onChange={onChange} />
                            <DatePicker onChange={onChange} />
                            <DatePicker onChange={onChange} />
                        </>
                    )}
                </DatePickerDisplay>
            </div>
            <div>
                <PageHeader
                    title="확정된 실사일정"
                />
                <DatePickerDisplay>
                    {
                        pickedVisitDate ? 
                            <input type="date" value={pickedVisitDate} /> 
                        : 
                            <DatePicker 
                                id={'pickedVisitDate'} 
                                onChange={onChangeDate} 
                                disabled={inProgress ==='STEP2_COMPLETED' ? false : true }
                            />
                    }
                    <ComfirmModal 
                        handleUpdateProcessDone={handleVisitDate} 
                        disabled={visitDateButtonActive === false ? true : pickedVisitDate ? false : true} 
                        approvedbutton='확정' 
                        position='10px' 
                        content='실사일정을 확정하시겠습니까?' 
                        titlepopup='실사일정 확정'/>
                    <ComfirmModal 
                        handleUpdateProcessDone={handleUpdateProcessDone} 
                        disabled={!pickedVisitDate ? false : !processDone ? true : false} 
                        approvedbutton='실사완료' 
                        position='10px' 
                        content='해당농장의 실사를 완료하셨나요?' 
                        titlepopup='실사완료'/>
                    <DateLabel>실사완료일자</DateLabel>
                    <DatePicked>
                        {processDoneDate && `${processDoneDate.split("T")[0]} ${processDoneDate.split("T")[1]}`}
                        {/* {visitDate === '' ?'yyyy.mm.dd 00:00:00' : visitDate} */}
                    </DatePicked>
                </DatePickerDisplay>
                    
            </div>
        </Pictkime>

    </div>
    }
    </>
  )
}
