import { configureStore } from "@reduxjs/toolkit";
import { farmerSlice } from "../slices/farm/farmerSlice";
import { addFarmerSlice } from "../slices/farm/addFarmerSlice";
import { editFarmerSlice } from "../slices/farm/editFarmerSlice";
import { eventSlice } from "../slices/event/eventSlice";
import { addEventSlice } from "../slices/event/addEventSlice";
import { editEventSlice } from "../slices/event/editEventSlice";
import {
  addFarmApi,
  editFarmWithImgApi,
  editFarmWithNoImgApi,
  farmDetailApi,
  farmListApi,
} from "../service/api";

const store = configureStore({
  reducer: {
    farmer: farmerSlice.reducer,
    addFarmer: addFarmerSlice.reducer,
    editFarmer: editFarmerSlice.reducer,
    event: eventSlice.reducer,
    addEvent: addEventSlice.reducer,
    editEvent: editEventSlice.reducer,
    [farmListApi.reducerPath]: farmListApi.reducer,
    [addFarmApi.reducerPath]: addFarmApi.reducer,
    [farmDetailApi.reducerPath]: farmDetailApi.reducer,
    [editFarmWithImgApi.reducerPath]: editFarmWithImgApi.reducer,
    [editFarmWithNoImgApi.reducerPath]: editFarmWithNoImgApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(farmListApi.middleware)
      .concat(addFarmApi.middleware)
      .concat(farmDetailApi.middleware)
      .concat(editFarmWithImgApi.middleware);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
