import { PlusOutlined } from '@ant-design/icons';
import {Button, Col, DatePicker, Drawer, Form, Input, List, Row, Select, Space, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import './category.scss';
import Category from "../../../interface/Category";
import {addFaqCategory, deleteFaqCategory, getFaqCategoryList, updateFaqCategory} from "../../../api/category";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

const { Option } = Select;

type CategoryDrawerProps = {
    visible: boolean,
    onClose?: (boolean: boolean) => any,
}

export default function CategoryDrawer({visible, onClose}:CategoryDrawerProps) {
    const [form] = Form.useForm();
    const [list, setList] = useState<Category[]>([]);
    const [showForm, setShowForm] = useState<string>();
    const [categoryDetail, setCategoryDetail] = useState<Category>();
    const [submitting, setSubmitting] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const _onClose = () => {
        onClose && onClose(false);
    };
    const _onFinish = async (values:any) => {
        setSubmitting(true);
        if (Boolean(categoryDetail)) {
            const data = await updateFaqCategory(categoryDetail?.id, values);
            if (data) {
                _loadData();
                setShowForm(undefined);
            }
        } else {
            const data = await addFaqCategory(values);
            if (data) {
                _loadData();
                setShowForm(undefined);
            }
        }
        setSubmitting(false);
    }
    const _loadData = async () => {
        const data: Category[] = await getFaqCategoryList();
        if (data) {
            setList(data);
        }
    }
    const _doDelete = async () => {
        setDeleting(true);
        const data = await deleteFaqCategory(categoryDetail?.id);
        setDeleting(false);
        setShowDeleteConfirm(false);
        if (data) {
            _loadData();
            setCategoryDetail(undefined);
        }
    }
    useEffect(() => {
        _loadData();
    }, [])
    return (
        <>
            <Drawer
                title="Category Management"
                width={512}
                onClose={_onClose}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <div style={{
                    marginBottom: '16px',
                }}>
                    {!showForm && (
                        <Button type="primary" ghost onClick={() => {
                            setCategoryDetail(undefined);
                            form.resetFields();
                            setShowForm('add');
                        }}>
                            Add
                        </Button>
                    )}
                    {!!showForm && (
                        <>
                            <Form layout="horizontal" onFinish={_onFinish} form={form}>
                                <Form.Item
                                    name="category"
                                    label="Category"
                                    rules={[{ required: true, message: 'Please enter category' }]}
                                >
                                    <Input placeholder="Please enter category" />
                                </Form.Item>
                                <Form.Item wrapperCol={{span: 24}}>
                                    <div className="flex justify-end">
                                        <Button type="primary" danger ghost onClick={() => {
                                            setShowForm(undefined);
                                            setCategoryDetail(undefined);
                                        }} disabled={submitting}>
                                            Cancel
                                        </Button>
                                        <Button type="primary" htmlType={"submit"} ghost style={{marginLeft: '8px'}}
                                            loading={submitting} disabled={submitting}>
                                            Save
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Form>
                        </>
                    )}
                </div>
                <List
                    header={<div>Category List</div>}
                    bordered
                    dataSource={list}
                    renderItem={item => (
                        <List.Item>
                            <Row gutter={16} style={{width: '100%'}}>
                                <Col span={12}>
                                    {item.category}
                                </Col>
                                <Col span={12}>
                                    <div className="flex justify-end">
                                        <Button type="primary" ghost size="small" onClick={() => {
                                            setCategoryDetail(item);
                                            setShowForm('edit');
                                            form.resetFields();
                                            form.setFieldsValue({
                                                category: item.category
                                            });
                                        }}>
                                            Detail
                                        </Button>
                                        <Button type="primary" className="ml-8"
                                                danger ghost size="small" onClick={() => {
                                                    setCategoryDetail(item);
                                                    setShowDeleteConfirm(true);
                                        }}>
                                            Delete
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </Drawer>
            <Dialog
                open={showDeleteConfirm}
                onClose={() => {
                    setShowDeleteConfirm(false);
                    setCategoryDetail(undefined);
                }}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    삭제
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure want to the delete this item "{categoryDetail?.category}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        setShowDeleteConfirm(false);
                        setCategoryDetail(undefined);
                    }} disabled={deleting}>
                        취소
                    </Button>
                    <Button onClick={_doDelete} loading={deleting} disabled={deleting} danger>
                        확인하다
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}