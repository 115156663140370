import {EditOutlined, UploadOutlined} from '@ant-design/icons';
import "antd/dist/antd.css";
import {Button, DatePicker, Form, Image, Input, Modal, PageHeader, Select, Switch} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { axiosAddManager, axiosChangeAuthority, Imanager } from "../../api/auth";
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import Swal from "sweetalert2";
import {addBanner, getBannerDetail, updateBannerImage} from "../../api/banner";
import Banner from "../../interface/Banner";
import BhEvent from "../../interface/BhEvent";
import {axiosGetAllEvent, axiosGetEventDetail} from "../../api/event";
import {useNavigate, useParams} from "react-router-dom";
import Constant from "../../constant/Constant";
import EditBannerViewSeqModal from "./modal/EditBannerViewSeqModal";
import EditBannerEventModal from "./modal/EditBannerEventModal";
import EditBannerExposeModal from "./modal/EditBannerExposeModal";

// const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

type BannerDetailProps = {

}

export default function (props: BannerDetailProps) {
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const fileInputRef = useRef<any>();
  const fileMobileInputRef = useRef<any>();
  const [selectedImageFile, setSelectedImageFile] = useState<File>();
  const [selectedMobileImageFile, setSelectedMobileImageFile] = useState<File>();
  const [bannerDetail, setBannerDetail] = useState<Banner|undefined>();
  const [events, setEvents] = useState<BhEvent[]>([]);
  const [eventDetail, setEventDetail] = useState<BhEvent>();
  const [showPopup, setShowPopup] = useState({
    event: false,
    visible: false,
    order: false,
  });
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string|undefined>();

  const params = useParams();
  const navigate = useNavigate();

  const _loadEvents = async () => {
    const res = await axiosGetAllEvent({search_count: 50, search_page: 1});
    if (res) {
      setEvents(res.content);
    }
  }

  const _loadEventDetail = async (id:any) => {
    const res = await axiosGetEventDetail(id);
    if (res) {
      setEventDetail(res);
    }
  }

  const _loadData = async () => {
    const res:Banner = await getBannerDetail(params.id);
    if (res) {
      setBannerDetail(res);
      _loadEventDetail(res.eventId);
    }
  }

  useEffect(() => {
    _loadData();
    _loadEvents();
  }, []);

  const onFinish = async (fieldsValue: any) => {
    // Should format date value before submit.
    setSubmitting(true);
    const formData = new FormData();
    if (selectedImageFile) {
      formData.append('banner_image', selectedImageFile as Blob);
    }
    if (selectedMobileImageFile) {
      formData.append('mobile_banner_image', selectedMobileImageFile as Blob);
    }
    const data = await updateBannerImage(params.id, formData);
    if (!data) {
      return Swal.fire(
          "오류",
          "프로세스 실패",
          "error"
      ).then(() => {
        setSubmitting(false);
      });
    }
    setSubmitting(false);
    _loadData();
    if (selectedImageFile) {
      setSelectedImageFile(undefined);
    }
    if (selectedMobileImageFile) {
      setSelectedMobileImageFile(undefined);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const _onImageFileChange = (event: any) => {
    if (event.target.files.length > 0) {
      setSelectedImageFile(event.target.files[0]);
    } else {
      setSelectedImageFile(undefined);
    }
    return event;
  }
  const _onMobileImageFileChange = (event: any) => {
    if (event.target.files.length > 0) {
      setSelectedMobileImageFile(event.target.files[0]);
    } else {
      setSelectedMobileImageFile(undefined);
    }
    return event;
  }

  return (
    <>
      <PageHeader
          title="Banner Detail"
      />
      <Form {...layout} onFinish={(data:any) => console.log(data)} onFinishFailed={onFinishFailed}>
        <Form.Item
            label="Order"
        >
          <div className="flex items-center">
            <div className="mr-8">{
              // @ts-ignore
              bannerDetail?.viewSeq
            }</div>
            <Button
                type="primary"
                htmlType="button"
                icon={<EditOutlined />}
                ghost
                size="small"
                onClick={() => setShowPopup({
                  ...showPopup,
                  order: true,
                })}
            >
            </Button>
          </div>
        </Form.Item>
        <Form.Item
            label="Expose"
        >
          <div className="flex items-center">
            <div className="mr-8">{
              // @ts-ignore
              bannerDetail?.viewYn === 1 ? 'Visible' : 'Hidden'
            }</div>
            <Button
                type="primary"
                htmlType="button"
                icon={<EditOutlined />}
                ghost
                size="small"
                onClick={() => setShowPopup({
                  ...showPopup,
                  visible: true,
                })}
            >
            </Button>
          </div>
        </Form.Item>
        <Form.Item
            label="Event"
        >
          <div className="flex items-center">
            <div className="mr-8">{
              // @ts-ignore
              eventDetail?.title
            }</div>
            <Button
                type="primary"
                htmlType="button"
                icon={<EditOutlined />}
                ghost
                size="small"
                onClick={() => setShowPopup({
                  ...showPopup,
                  event: true,
                })}
            >
            </Button>
          </div>
        </Form.Item>
      </Form>

      <PageHeader
          title="Banner Images"
      />

      <Form  {...layout} form={form} name="control-hooks" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item label="Banner Image" name="banner_image" htmlFor="banner_image" {
          ...!selectedImageFile && {
            rules: [{ required: false, message: '이미지를 첨부해주세요' }]
          }
        }>
          <>
            <Button icon={<UploadOutlined />} onClick={() => fileInputRef?.current?.click()}>
              {!!selectedImageFile || !!bannerDetail?.imageUrl ? 'Update' : 'Upload'}
            </Button>
            <div style={{ marginTop: 8 }}>
              {(!!selectedImageFile) ? (
                  <img
                      src={URL.createObjectURL(selectedImageFile)} width={150}
                      style={{ objectFit: 'contain', cursor: "pointer" }} alt={'preview'}
                      onClick={() => setImagePreviewUrl(URL.createObjectURL(selectedImageFile))}
                  />
              ) : (
                  <img
                      src={bannerDetail?.imageUrl} width={150}
                      style={{ objectFit: 'contain', cursor: "pointer" }} alt={'preview'}
                      onClick={() => setImagePreviewUrl(bannerDetail?.imageUrl)}
                  />
              )}
            </div>
            <input type="file" id="banner_image" onChange={_onImageFileChange} accept="image/*" ref={fileInputRef} hidden />
          </>
        </Form.Item>
        <Form.Item label="Mobile Banner" name="mobile_banner_image" htmlFor="mobile_banner_image" {
          ...!selectedMobileImageFile && {
            rules: [{ required: false, message: '이미지를 첨부해주세요' }]
          }
        } >
          <>
            <Button icon={<UploadOutlined />} onClick={() => fileMobileInputRef?.current?.click()}>
              {!!selectedMobileImageFile || !!bannerDetail?.mobileImageUrl ? 'Update' : 'Upload'}
            </Button>
            <div style={{ marginTop: 8 }}>
              {(!!selectedMobileImageFile) ? (
                  <img
                      src={URL.createObjectURL(selectedMobileImageFile)} width={150}
                      style={{ objectFit: 'contain', cursor: 'pointer' }} alt={'preview'}
                      onClick={() => setImagePreviewUrl(URL.createObjectURL(selectedMobileImageFile))}
                  />
              ) : (
                  <img
                      src={bannerDetail?.mobileImageUrl} width={150}
                      style={{ objectFit: 'contain', cursor: "pointer" }} alt={'preview'}
                      onClick={() => setImagePreviewUrl(bannerDetail?.mobileImageUrl)}
                  />
              )}
            </div>
            <input type="file" id="mobile_banner_image" onChange={_onMobileImageFileChange} accept="image/*" ref={fileMobileInputRef} hidden />
          </>
        </Form.Item>
        <Form.Item
            {...tailLayout}
            className="btn-wrap"
        >
          <Button htmlType="button" danger onClick={() => navigate(-1)} disabled={submitting} >
            취소
          </Button>
          <Button type="primary" htmlType="submit" loading={submitting} disabled={submitting} >
            저장
          </Button>
        </Form.Item>
      </Form>
      <Image
          width={200}
          style={{ display: 'none' }}
          src={imagePreviewUrl}
          preview={{
            visible: Boolean(imagePreviewUrl),
            src: imagePreviewUrl,
            onVisibleChange: value => {
              setImagePreviewUrl(undefined);
            },
          }}
      />
      <EditBannerViewSeqModal
          visible={showPopup.order}
          onClose={() => setShowPopup({ ...showPopup, order: false })}
          onDone={_loadData}
          detail={bannerDetail}
      />
      <EditBannerEventModal
          visible={showPopup.event}
          events={events}
          onClose={() => setShowPopup({ ...showPopup, event: false })}
          onDone={_loadData} detail={bannerDetail}
      />
      <EditBannerExposeModal
          visible={showPopup.visible}
          onClose={() => setShowPopup({ ...showPopup, visible: false })}
          onDone={_loadData}
          detail={bannerDetail}
      />
    </>
  );
}