import axios from "axios";
import axiosClient from "../service/axiosClient";
import httpRequest from "../service/httpRequest";

interface defaultResponse {
  data: {
    result: boolean;
    message: string;
  };
  status: string;
}

// 농가 생성
export const axiosAddFarm = async (formData: FormData) => {
  try {
    const res = await httpRequest({
      url: `farm/add`,
      method: "post",
      data: formData,
    });
    return res?.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

export interface Ifarm {
  id: number;
  title: string;
  status: boolean;
  farm_name: string;
  ceo_name: string;
  user_name: string;
  create_date: string;
}

export interface Ipagenation {
  data: {
    content: Ifarm[];
    number: number;
    totalElements: number;
    totalPages: number;
  };
  status: string;
}

// 농가정보 불러오기
export const axiosGetFarmer = async (page: number) => {
  try {
    return await httpRequest({
      url: `farm`,
      method: "get",
      params: {page},
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

interface IfarmDetail {
  data: {
    id: number;
    title: string;
    status: boolean;
    farm_name: string;
    ceo_name: string;
    content: string;
    farm_image: string;
    farm_ceo_image: string;
    user_name: string;
    create_date: string;
  };
  status: string;
}

// 농가 상세보기
export const axiosGetFarmDetail = async (id: string) => {
  try {
    return await httpRequest({
      url: `farm/${id}`,
      method: "get",
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

//이벤트 수정 이미지 있을 경우
export const axiosEditFarmwidthimg = async (formData: FormData) => {
  try {
    const res = await httpRequest({
      url: `farm/edit`,
      method: "post",
      data: formData,
    });
    return res.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

interface Inoimg {
  id: number;
  farm_name: string;
  ceo_name: string;
  title: string;
  content: string;
  status: boolean;
}

//이벤트 수정 이미지 없을 경우
export const axiosEditFarmnoimg = async (datas: Inoimg) => {
  try {
    const res = await httpRequest({
      url: `farm/edit`,
      method: "patch",
      data: datas,
    });
    return res.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

// 단일 농가 삭제
export const axiosRemoveOneFarm = async (id: number) => {
  try {
    const res = await httpRequest({
      url: `farm/${id}`,
      method: "delete",
    });
    return res.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

// 복수 농가 삭제
export const axiosRemoveFarms = async (id: number[]) => {
  try {
    const res = await httpRequest({
      url: `farm/delete`,
      method: "delete",
      data: { id },
    });
    return res.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};
