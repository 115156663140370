import {EventImage} from "../../interface/BhEvent";
import {Button, Card, Col, Row} from "antd";
import React, {useRef} from "react";
import {axiosAddEventImages, axiosDeleteEventImage, axiosEditEventImage} from "../../api/event";
import {useParams} from "react-router-dom";
import ConfirmDialog from "../../components/dialog/ConfirmDialog";

type EventImageEditorProps = {
    list: EventImage[];
    onChange: VoidFunction;
}
export default function EventImageEditor(props: EventImageEditorProps) {
    const {
        list,
        onChange,
    } = props;
    const params = useParams();
    const addFileRef = useRef<any>();
    const editFileRef = useRef<any>();
    const [toEditFile, setToEditFile] = React.useState<EventImage>();
    const [adding, setAdding] = React.useState(false);
    const [updating, setUpdating] = React.useState<any>();
    const [toDeleteFile, setToDeleteFile] = React.useState<EventImage>();
    const [deleting, setDeleting] = React.useState(false);
    const _deleteSelected = async () => {
        setDeleting(true);
        const res = await axiosDeleteEventImage(toDeleteFile?.id);
        if (res) {
            onChange && onChange();
        }
        setDeleting(false);
        setToDeleteFile(undefined);
    }
    const _addImageFileChange = async (e: any) => {
        const files = [...e.target.files];
        if (files.length > 0) {
            setAdding(true);
            const formData = new FormData();
            files.forEach((file: any) => {
                formData.append('event_image', file);
            });
            const res = await axiosAddEventImages(params.id, formData);
            setAdding(false);
            if (res) {
                onChange && onChange();
            }
        }
    }
    const _editImageFileChange = async (e: any) => {
        if (e.target.files.length > 0) {
            setUpdating(toEditFile?.id);
            const file = e.target.files[0];
            const formData = new FormData();
            formData.append('event_image', file);
            const res = await axiosEditEventImage(toEditFile?.id, formData);
            setUpdating(undefined);
            if (res) {
                onChange && onChange();
                setToEditFile(undefined);
            }
        }
    }
  return (
    <>
        <Card>
            <h3>Event Images</h3>
            <Button type="primary" className="mb-16" onClick={() => addFileRef?.current?.click()} loading={adding} disabled={adding}>
                Add Images
            </Button>
            <input type="file" name="add-file" multiple hidden onChange={_addImageFileChange} accept="image/*" ref={addFileRef} />
            <input type="file" name="edit-file" hidden onChange={_editImageFileChange} accept="image/*" ref={editFileRef} />
            <div className="flex flex-wrap">
                <Row gutter={[16, 16]}>
                    {list && list.length > 0 && list.map(eventImage => (
                        <Col key={eventImage.id.toString()} span={6}>
                            <EventImageItem
                                key={eventImage.id.toString()}
                                url={eventImage.imageUrl}
                                onEdit={() => {
                                    editFileRef?.current?.click();
                                    setToEditFile(eventImage);
                                }}
                                onRemove={() => setToDeleteFile(eventImage)}
                                loading={updating === eventImage.id}
                                disabled={updating === eventImage.id}
                            />
                        </Col>
                    ))}
                </Row>
            </div>
        </Card>
        <ConfirmDialog
            visible={Boolean(toDeleteFile)}
            onClose={() => setToDeleteFile(undefined)}
            title="삭제"
            content="선택한 항목을 모두 삭제하시겠습니까?"
            onConfirm={_deleteSelected}
            loading={deleting}
        />
    </>
  );
}
type EventImageItemProps = {
    url: string;
    onEdit?: VoidFunction;
    onRemove?: VoidFunction;
    showEdit?: boolean,
    loading?: boolean,
    disabled?: boolean,
}
function EventImageItem ({url, onEdit, onRemove, showEdit = true, loading, disabled}: EventImageItemProps) {
    return (
        <div style={{width: 'fit-content'}} className="mb-16 event-image-container">
            <img src={url} alt="event-image" className="image-contain border-1"/>
            <div className="flex mt-16">
                {showEdit && (
                    <Button
                        type="primary"
                        className="full-width"
                        onClick={onEdit}
                        style={{marginRight: '8px'}}
                        disabled={disabled}
                    >
                        Edit
                    </Button>
                )}
                <Button
                    danger
                    className="full-width"
                    onClick={onRemove}
                    loading={loading}
                    disabled={disabled}
                >
                    Remove
                </Button>
            </div>
        </div>
    )
}