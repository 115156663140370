import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { Ifarm } from "../api/farm";

const baseApi = process.env.REACT_APP_BASE_API;
const token = sessionStorage.getItem("token");

interface Ipagenation {
  data: {
    content: Ifarm[];
    number: number;
    totalElements: number;
    totalPages: number;
  };
  status: string;
}

interface DefaultResponse {
  data: {
    result: boolean;
    message: string;
  };
  status: string;
}

interface IfarmDetail {
  data: {
    id: number;
    title: string;
    status: boolean;
    farm_name: string;
    ceo_name: string;
    content: string;
    farm_image: string;
    farm_ceo_image: string;
    user_name: string;
    create_date: string;
  };
  status: string;
}

interface Inoimg {
  id: number;
  farm_name: string;
  ceo_name: string;
  title: string;
  content: string;
  status: boolean;
}

//GET REQUEST
export const farmListApi = createApi({
  reducerPath: "farmList",
  baseQuery: fetchBaseQuery({ baseUrl: baseApi }),
  endpoints: (build) => ({
    getFarmList: build.query<Ipagenation, number>({
      query: (page) => ({
        url: `farm?page=${page}`,
        headers: {
          TOKEN: `${token}`,
        },
      }),
    }),
  }),
});

// POST REQUEST
export const addFarmApi = createApi({
  reducerPath: "addFarm",
  baseQuery: fetchBaseQuery({ baseUrl: baseApi }),
  endpoints: (build) => ({
    addFarm: build.mutation<DefaultResponse, FormData>({
      query: (formData) => ({
        url: `farm/add`,
        method: "POST",
        body: formData,
        headers: {
          TOKEN: `${token}`,
        },
      }),
    }),
  }),
});

export const farmDetailApi = createApi({
  reducerPath: "farmDetail",
  baseQuery: fetchBaseQuery({ baseUrl: baseApi }),
  endpoints: (build) => ({
    farmDetail: build.query<IfarmDetail, string>({
      query: (id) => ({
        url: `farm/${id}`,
        headers: {
          TOKEN: `${token}`,
        },
      }),
    }),
  }),
});

export const editFarmWithImgApi = createApi({
  reducerPath: "editImageFarm",
  baseQuery: fetchBaseQuery({ baseUrl: baseApi }),
  endpoints: (build) => ({
    editImageFarm: build.mutation<DefaultResponse, FormData>({
      query: (formData) => ({
        url: `farm/edit`,
        method: "POST",
        body: formData,
        headers: {
          TOKEN: `${token}`,
        },
      }),
    }),
  }),
});

export const editFarmWithNoImgApi = createApi({
  reducerPath: "editNoImageFarm",
  baseQuery: fetchBaseQuery({ baseUrl: baseApi }),
  endpoints: (build) => ({
    editNoImageFarm: build.mutation<DefaultResponse, Inoimg>({
      query: (datas) => ({
        url: `farm/edit`,
        method: "PATCH",
        body: datas,
        headers: {
          TOKEN: `${token}`,
        },
      }),
    }),
  }),
});

export const { useEditNoImageFarmMutation } = editFarmWithNoImgApi;
export const { useEditImageFarmMutation } = editFarmWithImgApi;
export const { useGetFarmListQuery } = farmListApi;
export const { useAddFarmMutation } = addFarmApi;
export const { useFarmDetailQuery } = farmDetailApi;
