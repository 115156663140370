import { Button, Input, Modal } from 'antd';
import "antd/dist/antd.css";
import React from "react";
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import type { DatePickerProps } from 'antd';

const { confirm } = Modal;
const { TextArea } = Input;


export default function QnaModal() {

  // const onChange = (value: [moment, moment], dateString: [string, string]) => {
  //   console.log('Selected Time: ', value);
  //   console.log('Formatted Selected Time: ', dateString)
  // }

  const onChange: DatePickerProps['onChange'] = (date : any, dateString :any) => {
    console.log(date, dateString);
  };

  function onCalendarChange(dates: any) {
    // TODO: date value input;
    // console.log("onCalendarChange", moment(dates).format('YYYY-MM-DD'), typeof(dates));
  }

  const showConfirm = () => {
    confirm({
      title: '리뷰 추가',
      icon: '',
      content: <div>
        <div className="input-wrap">
          <label htmlFor="title">
            구매자 이름: <Input type="text" id="title" />
          </label>
        </div>
        <div className="input-wrap">
          <label htmlFor="url">
            구매한 농장 이름: <Input type="text" id="url" />
          </label>
        </div>
        <div className="input-wrap">
          <label htmlFor="url">
            구매한 소 이름: <Input type="text" id="url" />
          </label>
        </div>
        <div className="input-wrap">
          <label htmlFor="url">
            리뷰 내용: <TextArea rows={4} placeholder="리뷰 내용 작성" />
          </label>
        </div>
        <div className="input-wrap">
          <label htmlFor="url">
            리뷰 표시 날짜:  <DatePicker onChange={onChange} />
          </label>
        </div>
      </div>,
      wrapClassName: 'review-add-popup',
      // onOk() {
      //   console.log('OK');
      // },
      // onCancel() {
      //   console.log('Cancel');
      // },
      cancelText: '취소',
      okText: '저장'
    });
  };
  ;
  return (
    <>
      <Button onClick={showConfirm}>추가</Button>
    </>
  );
}
