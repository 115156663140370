import axios from "axios";
import httpRequest from "../service/httpRequest";

export interface RaiserImageList {
    originalImageName?: string;
    imageUrl?: string;
    fileOrderNum?: number;
    imageType?: string;
}

export interface fileListProps {
    originalFileName?: string;
    fileUrl?: string;
    fileType?: string;
}

export interface RaiserDetail {
    visitDateButtonActive?:boolean;
    applicantName?:string;
    businessLicense?:boolean,
    id?: number,
    phoneNumber?: string,
    email?: string,
    pageNum?: number,
    name?: string,
    raiserName?: string,
    raiserProvince?: string,
    raiserIdentityNo?: string,
    raiserAddress?: string,
    breedingType?: string,
    population?: string,
    identification?: boolean,
    ownRaiser?: boolean,
    corporateBusinessOwners?: boolean,
    fodder?: string,
    serviceTerms1?: boolean,
    serviceTerms2?: boolean,
    serviceTerms3?: boolean,
    inProgress?:string,
    createDt?: string,
    processDone?: string,
    step1CompletedDate?: string,
    step2CompletedDate?: string,
    visitDate?: string,
    investigationRequestDate?: string,
    raiserImages?:[ {
      originalImageName?: string,
      imageUrl?: string,
      fileOrderNum?: number,
      imageType?: string
    }],
    raiserFiles?: [ {
        originalFileName?: string,
        fileUrl?: string,
        fileType?: string
      } ]

}
export interface VisitDate {
    visitDate?:string
  }

export interface DownloadFileImgType{
    file?: any
}
  export interface UpdateProcess2Type{
    inProgress?: string
}

export const imageDownloadApi = async () => {
    try {
        return await httpRequest({
            url: `manager/raisers/image-download/${111}`,
            method: "get",
            params: {"imageType": "FARM_OUTSIDE"},
            baseURL: process.env.REACT_APP_BASE_HOME_API,
            responseType: "blob"
        });
    }  catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
}

export const getRaiserList = async (queries: any) => {
    try {
        return await httpRequest({
            url: `manager/raisers`,
            method: "get",
            params: queries,
            baseURL: process.env.REACT_APP_BASE_HOME_API
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};


export const UpdateVisitDate = async (id:number,params:VisitDate) => {
    console.log("UpdateVisitDate: ", id, params);
    try {
        return await httpRequest({
            url: `manager/raisers/visit-date/${id}`,
            method: "patch",
            data: params,
            baseURL: process.env.REACT_APP_BASE_HOME_API

        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};

export const UpdateProgressRaiser = async (id:number,params:UpdateProcess2Type) => {
    try {
        return await httpRequest({
            url:  `manager/raisers/progress/${id}`,
            method: "patch",
            data: params,
            baseURL: process.env.REACT_APP_BASE_HOME_API
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};

export const DownloadFileImg = async (id:number,params:DownloadFileImgType) => {
    try {
        return await httpRequest({
            url:  `manager/raisers/progress/${id}`,
            method: "post",
            data: params,
            baseURL: process.env.REACT_APP_BASE_HOME_API
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};

export const GetDetailRaiser = async (id:number) => {
    try {
        return await httpRequest({
            url:  `manager/raisers/${id}`,
            method: "get",
            baseURL: process.env.REACT_APP_BASE_HOME_API
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};


// NO API FOR THAT
export const deleteRaiser = async (list: any) => {
    try {
        return await httpRequest({
            url: `manager/raisers`,
            method: "delete",
            baseURL: process.env.REACT_APP_BASE_HOME_API,
            data: { ids: list }
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
   }
}
