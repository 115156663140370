import {Button, Form, Modal, Select} from "antd";
import BhEvent from "../../../interface/BhEvent";
import React, {useEffect, useState} from "react";
import {axiosEditEventType} from "../../../api/event";
import Constant from "../../../constant/Constant";
import Banner from "../../../interface/Banner";
import {updateBannerEvent} from "../../../api/banner";

type EditBannerEventModalProps = {
    visible: boolean;
    onDone?: VoidFunction;
    onClose?: (val?: boolean) => void;
    detail?: Banner;
    events: BhEvent[];
};

export default function EditBannerEventModal(props: EditBannerEventModalProps) {
    const {
        visible,
        onDone,
        onClose,
        detail,
        events = [],
    } = props;
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [eventNull, setEventNull] = useState(0);
    const onFinish = async (values: any) => {
        setSubmitting(true);
        const response = await updateBannerEvent(detail?.id, values.event_id);
        console.log(values.event_id)
        setSubmitting(false);
        if (response) {
            onDone && onDone();
            _close();
        }
    }
    const onReset = async () => {
      setSubmitting(true);
      const response = await updateBannerEvent(detail?.id, 0);
      setSubmitting(false);
      if (response) {
          onDone && onDone();
          _close();
      }
  }
    const onFailed = (data:any) => {
        console.log('failed', data);
    }
    const _close = () => {
        onClose && onClose(false);
    }
    useEffect(() => {
        if (visible) {
            form.setFieldsValue({
                event_id: `${detail?.eventId}`
            })
        }
    }, [visible]);
    return (
        <Modal
            title="Edit Banner Event"
            visible={visible}
            centered={true}
            maskClosable={false}
            closable={false}
            className='form-modal'
            footer={null}
        >
            <Form labelCol={{span: 5}} wrapperCol={{span: 9}} form={form} name="edit-event-type" onFinish={onFinish} onFinishFailed={onFailed}>
                <Form.Item
                    initialValue={`${detail?.eventId}`}
                    label="Event"
                    name="event_id"
                    rules={[{ required: true, message: 'Event 선택해주세요' }]}
                >
                    <Select>
                        {events.map((event, index) => (
                            <Select.Option key={`${index}`} value={`${event.id}`}>{event.title}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
            <Form.Item
                wrapperCol={{ span: 24 }}
                className="btn-wrap"
                style={{textAlign: 'end'}}
            >
                <Button htmlType="button" danger onClick={() => onReset()} disabled={submitting}>
                    이벤트 없음
                </Button>
                <Button htmlType="button" danger onClick={_close} disabled={submitting}>
                    취소
                </Button>
                <Button type="primary" onClick={() => form.submit()} htmlType="button" loading={submitting} disabled={submitting} >
                    저장
                </Button>
            </Form.Item>
        </Modal>
    );
}