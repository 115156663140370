import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Modal, Select, Upload } from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import {deletePopupImageRequest, getOnePopupRequest, postPopupRequest, putPopupRequest} from "../../api/popup";
import Swal from "sweetalert2";
import moment from "moment";

const { RangePicker }:any = DatePicker;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

type data = {
  id?: number,
  title?: string,
  url?: string,
  startDate?: string,
  endDate?: string,
  deleteYn?: string,
  image?: string,
}

type paramsDataType = {
  paramsData?: data,
  isOpen: boolean,
  isClose: (value: boolean) => void,
  onDone?: VoidFunction;
}


const EditPopupModal = ({ paramsData, isOpen, isClose, onDone }: paramsDataType) => {
  const [visible, setVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [form] = Form.useForm();
  const [detail, setDetail] = useState<data|undefined>(paramsData);

  const fileInputRef = useRef<any>();

  const _loadData = async () => {
    const data = await getOnePopupRequest(paramsData?.id);
    if (data) {
      setDetail(data);
    }
    return data;
  }

  const setFormData = (data:data|undefined) => {
    if (!data) {
      data = detail;
    }
    form.setFieldsValue({
      title: data?.title,
      url: data?.url,
      'range-picker': [moment(data?.startDate, 'YYYY-MM-DD'), moment(data?.endDate, 'YYYY-MM-DD')],
      delete_yn: `${data?.deleteYn}`,
    });
  }

  useEffect(() => {
    showModal();
    _loadData().then((data) => {
      setFormData(data);
    });
  }, []);

  // console.log('받은 데이터 : ', paramsData?.age, ' / isOpen : ', isOpen, ' / typeof : ', typeof(paramsData))

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
    isClose(false);
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = async (fieldsValue: any) => {
    // Should format date value before submit.
    const rangeValue = fieldsValue['range-picker'];
    const values = {
      ...fieldsValue,
      'range-picker': [rangeValue[0].format('YYYY-MM-DD'), rangeValue[1].format('YYYY-MM-DD')],
    };
    const formData = new FormData();
    if (selectedFile) {
      formData.append("popup_image", selectedFile as Blob);
    }
    formData.append(
        "popup_request",
        new Blob(
            [
              JSON.stringify({
                id: detail?.id,
                title: values.title,
                url: values.url,
                start_date: values['range-picker'][0],
                end_date: values['range-picker'][1],
                delete_yn: values.delete_yn,
              })
            ],
            { type: "application/json" }
        )
    );
    setSubmitting(true);
    const res = await putPopupRequest(formData);
    setSubmitting(false);
    if (res) {
      onDone && onDone();
      setVisible(false);
      onReset();
      setSelectedFile(undefined);
      hideModal();
      Swal.fire(
          "등록 완료",
          "팝업 업데이트가 완료되었습니다.",
          "success"
      );
    } else {
      Swal.fire({
        icon: "error",
        title: "등록 실패",
        text: "팝업 업데이트에 실패했습니다."
      });
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };
  const rangeConfig = {
    rules: [{ type: 'array' as const, required: true, message: '기간을 선택해주세요' }],
  };
  const optionConfig = {
    rules: [{ type: 'string' as const, required: true, message: '노출여부를 선택해주세요' }],
  };
  const imageConfig = {
    rules: [{ type: 'array' as const, required: true, message: '이미지를 첨부해주세요' }],
  };

  const onChange = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string,
  ) => {
  };
  
  const onOk = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
    // console.log('onOk: ', value);
  };

  const onFileChange = (event:any) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    } else {
      setSelectedFile(undefined);
    }
  }

  return (
    <div>
      <Modal
        title="팝업 수정"
        visible={visible}
        onOk={hideModal}
        onCancel={hideModal}
        centered={true}
        maskClosable={false}
        closable={false}
        okText="确认"
        cancelText="取消"
        className='form-modal'
        footer={null}
      >
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item
            label="제목"
            name="title"
            rules={[{ required: true, message: '제목을 입력해 주세요' }]}
          >
            <Input type="text" id="title" />
          </Form.Item>
          <Form.Item
            label="url"
            name="url"
            // rules={[{ required: false, message: 'url을 입력해 주세요' }]}
          >
            <Input type="text" id="url" />
          </Form.Item>

          <Form.Item name="range-picker" label="노출 기간" {...rangeConfig}>
            <RangePicker
              format="YYYY-MM-DD"
              onChange={onChange}
              onOk={onOk}
            />
          </Form.Item>
          <Form.Item label="노출여부" name="delete_yn" {...optionConfig}>
            <Select>
              <Select.Option value="0">노출</Select.Option>
              <Select.Option value="1">비노출</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="이미지 첨부" name="image" >
            <>
              <Button icon={<UploadOutlined />} onClick={() => fileInputRef?.current?.click()}>
                {!!detail?.image ? 'Update' : 'Upload'}
              </Button>
              <div style={{marginTop: 8}}>
                {(!selectedFile || !!detail?.image) && (
                    <img
                        src={selectedFile ? URL.createObjectURL(selectedFile) : detail?.image}
                        width={150} style={{objectFit: 'contain'}}  alt={'preview'}/>
                )}
              </div>
              <input type="file" id="image" accept="image/*" onChange={onFileChange} hidden ref={fileInputRef}/>
            </>
          </Form.Item>
          <Form.Item
            {...tailLayout}
            className="btn-wrap"
          >
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
            <Button htmlType="button" danger onClick={hideModal} >
              취소
            </Button>
            <Button type="primary" htmlType="submit" loading={submitting} disabled={submitting} >
              저장
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* <Button onClick={showModal} type="primary">수정</Button> */}
    </div>
  )
}

export default EditPopupModal