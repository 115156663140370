import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {Button} from "antd";
import React from "react";

type ConfirmDialogProps = {
    visible: boolean;
    loading?: boolean;
    onClose?: (value:boolean) => any;
    onConfirm?: VoidFunction;
    title?: any;
    content?: any;
    cancelText?: string;
    confirmText?: string;
}

export default function ConfirmDialog (props:ConfirmDialogProps) {
    const {
        visible,
        loading,
        onClose,
        onConfirm,
        title,
        content,
        cancelText,
        confirmText
    } = props;
    const _close = () => {
        onClose && onClose(false);
    }
    return (
        <Dialog
            open={visible}
            onClose={_close}
        >
            {!!title && (
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {title}
                </DialogTitle>
            )}
            {!!content && (
                <DialogContent>
                    <DialogContentText>
                        {content}
                    </DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                <Button autoFocus onClick={_close}>
                    {cancelText || "아니오"}
                </Button>
                <Button onClick={onConfirm} loading={loading} disabled={loading} danger>
                    {confirmText || "예"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}