import qs from "qs";
import axiosClient from "../service/axiosClient";
import axios from "axios";
import httpRequest from "../service/httpRequest";

interface defaultResponse {
  data: {
    result: boolean;
    message: string;
  };
  status: string;
}

// 이벤트 등록
export const axiosAddEvent = async (formData: FormData) => {
  try {
    const res = await httpRequest({
      url: `event/add`,
      method: "post",
      data: formData,
    });
    return res?.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

export interface Ievent {
  id: number;
  title: string;
  content: string;
  url: string;
  image: string;
  status: boolean;
  username: string;
  startDate: string;
  endDate: string;
  createDate: string;
}

interface IAllEvent {
  data: {
    content: Ievent[];
    number: number;
    totalElements: number;
    totalPages: number;
  };
  status: string;
}

// 이벤트 불러오기
export const axiosGetAllEvent = async (queries:any) => {
  try {
    return await httpRequest({
      url: `event/admin/list`,
      method: "get",
      params: queries,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

interface IeventDetail {
  data: Ievent;
  status: string;
}

// 이벤트 상세보기
export const axiosGetEventDetail = async (id: any) => {
  try {
    return await httpRequest({
      url: `event/admin/detail/${id}`,
      method: "get",
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const axiosEditEvent = async (id: any, requestPayload: any) => {
  try {
    const res = await httpRequest({
      url: `event/content/${id}`,
      method: "patch",
      data: requestPayload,
    });
    return res?.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

//이벤트 수정 이미지 있을 경우
export const axiosEditEventwidthimg = async (formData: FormData) => {
  try {
    const res = await httpRequest({
      url: `event/edit`,
      method: "post",
      data: formData,
    });
    return res?.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

interface Inoimg {
  id: number;
  title: string;
  content: string;
  url: string;
  start_date: string;
  end_date: string;
  status: boolean;
}

//이벤트 수정 이미지 없을 경우
export const axiosEditEventnoimg = async (datas: Inoimg) => {
  try {
    const res = await httpRequest({
      url: `event/edit`,
      method: "patch",
      data: datas,
    });
    return res?.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

// 단일 이벤트 삭제
export const axiosRemoveOneEvent = async (id: number) => {
  try {
    const res = await httpRequest({
      url: `event/${id}`,
      method: "delete",
    });
    return res?.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

// 복수 이벤트 삭제
export const axiosRemoveEvents = async (id: number[]) => {
  try {
    const res = await httpRequest({
      url: `event/delete`,
      method: "delete",
      data: { id }
    });
    return res?.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const axiosEditEventType = async (id: any, type: string) => {
  try {
    const res = await httpRequest({
      url: `event/type/${id}`,
      method: "patch",
      params: { event_type: type }
    });
    return res?.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const axiosEditEventExportType = async (id: any, type: string) => {
  try {
    const res = await httpRequest({
      url: `event/export/type/${id}`,
      method: "patch",
      params: { export_type: type }
    });
    return res?.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const axiosEditEventViewSeq = async (id: any, order: any) => {
  try {
    const res = await httpRequest({
      url: `event/view/order/${id}`,
      method: "patch",
      params: { view_seq: order }
    });
    return res?.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const axiosEditEventViewExpose = async (id: any, expose: any) => {
  try {
    const res = await httpRequest({
      url: `event/view/expose/${id}`,
      method: "patch",
      params: { view_yn: expose }
    });
    return res?.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const axiosAddEventImages = async (id: any, formData: FormData) => {
  try {
    const res = await httpRequest({
      url: `eventImage/add/${id}`,
      method: "post",
      data: formData
    });
    return res?.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const axiosEditEventImage = async (id: any, formData: FormData) => {
  try {
    const res = await httpRequest({
      url: `eventImage/edit/${id}`,
      method: "post",
      data: formData
    });
    return res?.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const axiosEditEventImageOrder = async (id: any, order: any) => {
  try {
    const res = await httpRequest({
      url: `eventImage/view/order/${id}`,
      method: "patch",
      params: {view_seq: order},
    });
    return res?.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const axiosEditEventImageExpose = async (id: any, expose: any) => {
  try {
    const res = await httpRequest({
      url: `eventImage/view/expose/${id}`,
      method: "patch",
      params: {view_yn: expose},
    });
    return res?.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const axiosDeleteEventImage = async (id: any) => {
  try {
    const res = await httpRequest({
      url: `eventImage/delete/${id}`,
      method: "delete",
      params: {delete_yn: 1},
    });
    return res?.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};
