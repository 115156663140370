import { useEffect, useState } from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";
import { Button } from 'antd';
import {
  addQnaRequest,
  deleteFarmRequestDetail,
  FarmQnaDetail,
  getFarmRequestDetail
} from "../../api/qna";
import { styled } from "@mui/material/styles";
import Delete from "../../components/button/Delete";
import List from "../../components/button/List";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

const FarmRequestDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<FarmQnaDetail>();

  const [category, setCategory] = useState('INVESTMENT');
  const [qna_name, setQna_name] = useState('KimJiHun');
  const [phone_number, setPhone_number] = useState('010-1234-5678');
  const [email, setEmail] = useState('gmldnr2222@naver.com');
  const [title, setTitle] = useState('투자 문의 입니다.');
  const [message, setMessage] = useState('이메일 혹은 전화로 연락 부탁드립니다.');
  const [lock, setLock] = useState('0');
  const [lockpassword, setLockpassword] = useState('djqwo1245');
  const [reply_type, setReply_type] = useState('REPLY');

  // useEffect(() => {
  //   (async () => {
  //     const data = await getFarmRequestDetail(id);
  //     setData(data);
  //   })();
  //   console.log(data?.data.availableDate);
  // }, []);

  type QnaData = {
    qna_category: string;
    name: string;
    phoneNumber: string;
    email: string;
    title: string;
    message: string;
    checked: boolean;
  };

  const HeaderItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    fontWeight: "bold"
  }));

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary
  }));

  const gobackToList = () => {
    navigate("/manager/admin/qna");
  };

  const deleteQna = async () => {
    const result = await deleteFarmRequestDetail(id);
    alert(result?.data.message);
    gobackToList();
  };

  console.log('cc', category, qna_name, phone_number, email, title, message, lock, lockpassword, reply_type);

  const addQna = async () => {
    var formData = new FormData();
    var cleanScript = {
      'type': 'script',
      'api_key': 11,
      'data': 222,
      'inputs': 333,
      'timeoutSeconds': 444
  };
  var jsonse = JSON.stringify(cleanScript);
    formData.append(
      "farm_request",
     new Blob(
        [
          // jsonse
          JSON.stringify({
            category,
            qna_name,
            phone_number,
            email,
            title,
            message,
            lock,
            lockpassword,
            reply_type
            // 'type': 'script',
            // 'api_key': 11,
            // 'data': 222,
            // 'inputs': 333,
            // 'timeoutSeconds': 444
            // cleanScript
          })
        ],
        { type: "application/json" }
      )
    );
    // console.log('data', data);
    const res = await addQnaRequest(formData);

    if (res) {
      Swal.fire(
        "등록 완료",
        "새로운 농가 등록이 완료되었습니다.",
        "success"
      ).then(() => {
        navigate(-1);
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "등록 실패",
        text: "농가 등록에 실패하였습니다."
      });
    }
  }

  return (
    <div>
      <Typography variant="h4" component="h2">
        Qna 상세11
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          marginTop: 1,
          marginBottom: 8
        }}
      >
        <Button onClick={gobackToList}>목록</Button>
        <Button onClick={addQna} type="primary">생성</Button>
        <Button onClick={deleteQna} type="primary" danger>삭제</Button>
        {/* <Delete func={deleteQna}></Delete> */}
      </Box>
      category
      <select name="" id="" onChange={(e) => setCategory(e.target.value)}>
        <option value="BUG">BUG</option>
        <option value="PARTNER">PARTNER</option>
        <option value="INVESTMENT">INVESTMENT</option>
        <option value="ETC">ETC</option>
      </select>
      qna_name
      <input type="text" value={qna_name} onChange={(e) => setQna_name(e.target.value)} />
      phone_number
      <input type="test" value={phone_number} onChange={(e) => setPhone_number(e.target.value)} />
      email
      <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
      title
      <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
      message
      <input type="text" value={message} onChange={(e) => setMessage(e.target.value)} />
      항상 비공개
      <input type="text" value={lock} onChange={(e) => setLock(e.target.value)} />
      패스워드
      <input type="password" value={lockpassword} onChange={(e) => setLockpassword(e.target.value)} />
      reply_type
      <select onChange={(e) => setReply_type(e.target.value)}>
        <option value="REPLY">답글</option>
        <option value="EMAIL">이메일</option>
        <option value="SMS">SMS</option>
      </select>
    </div>
  );
};

export default FarmRequestDetail;
