import type { DatePickerProps } from 'antd';
import { Button, DatePicker, Form, Input, Modal } from 'antd';
import "antd/dist/antd.css";
import React, { useEffect, useState } from 'react';
import {axiosEditReview, axiosGetReviewDetail} from "../../api/reviewer";
import moment from "moment";

const { TextArea } = Input;

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

type data = {
  id?: number,
  buyerName?: string,
  raiserName?: string,
  cattleName?: string,
  comment?: string,
  reviewDate?: string,
  createBy?: string,
  updateBy?: string,
  deleteYn?: number,
  deleteDt?: string,
  deleteBy?: string,
  createDate?: string,
  updateDate?: string,
}

type paramsDataType = {
  paramsData? : data,
  isOpen: boolean,
  isClose : (value: boolean) => void ,
  onDone?: VoidFunction,
}

const EditReviewModal = ({ paramsData, isOpen,  isClose, onDone } : paramsDataType) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [detail, setDetail] = useState<data|undefined>(paramsData);
  const [submitting, setSubmitting] = useState(false);

  const loadDetail = async (): Promise<data|any> => {
    const data = await axiosGetReviewDetail(paramsData?.id);
    if (data) {
      setDetail(data);
    }
    return data;
  }

  const setFormData = (data:data|undefined) => {
    if (!data) {
      data = detail;
    }
    form.setFieldsValue({
      comment: data?.comment,
      review_date: moment(data?.reviewDate, 'YYYY-MM-DD'),
      raiser_name: data?.raiserName,
      buyer_name: data?.buyerName,
      cattle_name: data?.cattleName,
    })
  }

  useEffect(() => {
    showModal();
    loadDetail().then((data) => {
      setFormData(data);
    });
  },[]);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
    isClose(false)
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = async (fieldsValue: any) => {
    // Should format date value before submit.
    const values = {
      ...fieldsValue,
      review_date: fieldsValue['review_date'].format('YYYY-MM-DD'),
    };
    console.log('Received values of form: ', values);

    setSubmitting(true);
    const data = await axiosEditReview(paramsData?.id, values);
    if (data) {
      onDone && onDone();
      setVisible(false);
      onReset();
      hideModal();
    }
    setSubmitting(false);

  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  // const onChange = (value: [moment, moment], dateString: [string, string]) => {
  //   console.log('Selected Time: ', value);
  //   console.log('Formatted Selected Time: ', dateString)
  // }

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    console.log(date, dateString);
  };

  function onCalendarChange(dates: any) {
    // TODO: date value input;
    // console.log("onCalendarChange", moment(dates).format('YYYY-MM-DD'), typeof(dates));
  }

  return (
    <div>
      <Modal
        title="리뷰 추가"
        visible={visible}
        onOk={hideModal}
        onCancel={hideModal}
        centered={true}
        maskClosable={false}
        closable={false}
        okText="确认"
        cancelText="取消"
        className='form-modal'
        footer={null}
      >
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item
            label="구매자 이름"
            name="buyer_name"
            rules={[{ required: true, message: '구매자 이름을 입력해 주세요' }]}
          >
            <Input type="text" id="buyer_name" />
          </Form.Item>
          {/*<Form.Item*/}
          {/*  label="구매한 농장 이름"*/}
          {/*  name="raiser_name"*/}
          {/*  rules={[{ required: true, message: '구매한 농장 이름을 입력해주세요' }]}*/}
          {/*>*/}
          {/*  <Input type="text" id="raiser_name" />*/}
          {/*</Form.Item>*/}
          {/*<Form.Item*/}
          {/*    label="소 이름"*/}
          {/*    name="cattle_name"*/}
          {/*    rules={[{ required: true, message: '구매한 소의 이름을 입력하세요.' }]}*/}
          {/*>*/}
          {/*  <Input type="text" id="cattle_name" />*/}
          {/*</Form.Item>*/}
          <Form.Item
            label="리뷰 내용"
            name="comment"
            rules={[{ required: true, message: '리뷰를 입력해주세요' }]}
          >
            <TextArea rows={4} placeholder="리뷰 내용 작성" id="comment" />
          </Form.Item>
          <Form.Item
            label="리뷰 표시 날짜"
            name="review_date"
          // rules={[{ required: true, message: '리뷰를 입력해주세요' }]}
          >
            <DatePicker onChange={onChange} id="review_date" />
          </Form.Item>

          <Form.Item
            {...tailLayout}
            className="btn-wrap"
          >
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
            <Button htmlType="button" danger onClick={hideModal} >
              취소
            </Button>
            <Button type="primary" htmlType="submit" loading={submitting} disabled={submitting} >
              저장
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* <Button onClick={showModal} type="primary">추가</Button> */}
    </div>
  );
}

export default EditReviewModal;