import React, { useState, useEffect } from 'react';
import { Button, PageHeader, Tabs } from "antd";
import { deleteFaqs, getAdminFaqs, getClientFaqs } from "../../api/qna";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import AddFaqpModal from './AddFaqpModal';
import styled from 'styled-components';
import moment from "moment";
import ConfirmDialog from "../../components/dialog/ConfirmDialog";
import FAQ from "../../interface/FAQ";
import Swal from "sweetalert2";

export type TableWrapProps = {
  type?: number;
}

const pageSize = 10;

const Faq = () => {
  const { TabPane } = Tabs;

  const [isDelete, setIsDelete] = useState(false);
  const [checked, setChecked] = useState<number[]>([]);

  const [loading, setLoading] = useState(true);
  const [clientList, setClientList] = useState<FAQ[]>([]);
  const [adminList, setAdminList] = useState<FAQ[]>([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [clientQueries, setClientQueries] = useState({
    faq_category_id: 0,
    title: "",
    search_count: 10,
    search_page: 1
  });
  const [adminQueries, setAdminQueries] = useState({
    faq_category_id: 0,
    title: "",
    search_count: 10,
    search_page: 1
  });
  const [clientTotalElements, setClientTotalElements] = useState(0);
  const [adminTotalElements, setAdminTotalElements] = useState(0);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [activeTab, setActiveTab] = useState('client');

  interface clientQnaList {
    createDate: string;
    faqCategory: string;
    id: number;
    message: string;
    title: string;
    updateData: Date;
    username: string;
  }

  const [callData, setCallData] = useState<FAQ | undefined>(undefined);

  const columns: GridColDef[] = [
    { field: 'id', headerName: '아이디', width: 70, sortable: false, disableColumnMenu: true },
    { field: 'title', headerName: '제목', width: 130, sortable: false, disableColumnMenu: true },
    { field: 'message', headerName: '내용', width: 130, sortable: false, disableColumnMenu: true },
    { field: 'category', headerName: '카테고리', width: 130, sortable: false, disableColumnMenu: true, },
    { field: 'username', headerName: '작성자 이름', width: 130, sortable: false, disableColumnMenu: true, },
    {
      field: 'createDate', headerName: '생성일', sortable: false, disableColumnMenu: true, width: 200,
      valueGetter: (params) => moment(params.value).format("DD MMM YYYY, hh:mm a"),
    },
    {
      field: 'actions', headerName: '', width: 90, sortable: false, disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <Button type="primary" ghost onClick={(e) => _onDetailClicked(params.row, e)}>
            수정
          </Button>
        </>
      ),
    }
  ];

  const _deleteSelected = async () => {
    setSubmitting(true);
    const res = await deleteFaqs(selectedIds);
    setSubmitting(false);
    setShowDeleteConfirm(false);
    if (res) {
      Swal.fire("삭제 완료", "고객센터 삭제가 완료되었습니다.", "success");
      _loadData(activeTab);
    } else {
      Swal.fire({
        icon: "error",
        title: "삭제 실패",
        text: "고객센터 삭제를 실패하였습니다."
      });
    }
  }

  const _onDetailClicked = (params: FAQ, e: any) => {
    e.stopPropagation();
    setCallData(params);
  }

  useEffect(() => {
    _loadData(activeTab);
  }, [clientQueries]);

  const _loadData = async (key: string) => {
    if (!key) {
      key = activeTab;
    }
    if (!loading) setLoading(true);
    if (key === 'client') {
      const res = await getClientFaqs(clientQueries);
      if (res) {
        setClientList(res.content);
        setClientTotalElements(res.totalElements);
      }
    } else {
      const res = await getAdminFaqs(adminQueries);
      if (res) {
        setAdminList(res.content);
        setAdminTotalElements(res.totalElements);
      }
    }
    setLoading(false);
    // setCallData(res && res?.data?.content);
  }

  const pageNation = (page: number) => {
    setClientQueries({
      ...clientQueries,
      search_page: page + 1,
    })
  }

  const onSelectionChanged = (data: any) => {
    setSelectedIds(data);
  }

  const _onTabChanged = (newKey: string) => {
    setActiveTab(newKey);
    _loadData(newKey);
  }

  // console.log('calldata', callData);

  return (
    <div>
      <PageHeader
        // className="팝업 관리"
        title="고객센터"
        // breadcrumb={{ routes }}
        subTitle="자주묻는 질문"
      />

      <div className="flex" style={{ marginBottom: '10px'}}>
        <AddFaqpModal onDone={() => _loadData(activeTab)} isUpdate={Boolean(callData)} faqDetail={callData}
          onClose={() => setCallData(undefined)} isClient={activeTab === 'client'} />
        <Button type="primary" danger style={{ marginLeft: '8px' }}
          onClick={() => setShowDeleteConfirm(true)}
          disabled={selectedIds.length === 0}
        > 삭제 </Button>
      </div>

      <TableWrap type={pageSize}>
        <DataGrid
          className='popup-list-table'
          rows={clientList}
          rowCount={clientTotalElements}
          columns={columns}
          pageSize={clientQueries.search_count}
          rowsPerPageOptions={[clientQueries.search_count]}
          loading={loading}
          onPageChange={pageNation}
          onSelectionModelChange={onSelectionChanged}
          paginationMode="server"
          pagination
          checkboxSelection
        />
      </TableWrap>

      {/* <Tabs defaultActiveKey="client" onChange={_onTabChanged}>
        <TabPane tab="Client" key="client">
        </TabPane>

        <TabPane tab="Admin" key="admin">
          <TableWrap type={pageSize}>
            <DataGrid
              className='popup-list-table'
              rows={adminList}
              rowCount={adminTotalElements}
              columns={columns}
              pageSize={adminQueries.search_count}
              rowsPerPageOptions={[adminQueries.search_count]}
              loading={loading}
              onPageChange={pageNation}
              onSelectionModelChange={onSelectionChanged}
              paginationMode="server"
              pagination
              checkboxSelection
            />
          </TableWrap>
        </TabPane>
      </Tabs> */}
      <ConfirmDialog
        visible={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        title="삭제"
        content="선택한 항목을 모두 삭제하시겠습니까?"
        onConfirm={_deleteSelected}
        loading={submitting}
      />
    </div>
  );
};

const TableWrap = styled.div<TableWrapProps>`
  height: calc(${props => props.type} * 65px);
`

export default Faq;
