import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Modal, Select, Upload, DatePickerProps } from 'antd';
import FarmQna from "../../interface/FarmQna";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getFarmQnaDetail, replyFarmQnaEmail } from "../../api/partner";
import { MyCustomUploadAdapterPlugin } from "../../classes/MyUploadAdapter";
import CustomCKEditor from "../../components/input/CustomCKEditor";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

type paramsDataType = {
  paramsData?: FarmQna,
  isOpen: boolean,
  isClose: (value: boolean) => void,
  onClose: (value: boolean) => void,
  onDone: VoidFunction,
}

const DetailPartnerModal = ({ paramsData, isOpen, isClose, onClose, onDone }: paramsDataType) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [detail, setDetail] = useState(paramsData);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    showModal();
    _loadData();
  }, []);

  const _loadData = async () => {
    const res = await getFarmQnaDetail(paramsData?.id);
    if (res) {
      setDetail(res);
    }
    return res;
  }

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
    isClose(false);
    onClose && onClose(false);
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = async (fieldsValue: any) => {
    setSubmitting(true);
    const res = await replyFarmQnaEmail(paramsData?.id, fieldsValue);
    setSubmitting(false);
    if (res) {
      hideModal();
      onDone && onDone();
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <Modal
        title="문의 상세보기"
        visible={visible}
        onOk={hideModal}
        onCancel={hideModal}
        centered={true}
        maskClosable={false}
        closable={false}
        okText="确认"
        cancelText="取消"
        className='form-modal'
        footer={null}
      >
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item label="이름">
            <span>{detail?.farm_qna_name}</span>
          </Form.Item>
          <Form.Item label="전화번호">
            <span>{detail?.phone_number}</span>
          </Form.Item>
          <Form.Item label="이메일">
            <span>{detail?.email ? detail?.email : '-'}</span>
          </Form.Item>
          <Form.Item label="사료명">
            <span>{detail?.feed_name}</span>
          </Form.Item>
          <Form.Item label="농장 이름">
            <span>{detail?.farm_name ? detail?.farm_name : '-'}</span>
          </Form.Item>
          <Form.Item label="농장 주소">
            <span>{detail?.farm_address ? detail?.farm_address : '-'}</span>
          </Form.Item>
          <Form.Item label="사육 두수">
            <span>{detail?.cow_num}</span>
          </Form.Item>
          {
            detail?.checked &&
            <>
              <Form.Item label="답변 내용">
                <span>{detail?.reply_answer}</span>
              </Form.Item>
              <Form.Item label="답변 작성일">
                <span>{detail?.reply_create_date}</span>
              </Form.Item>
              <Form.Item label="답변자">
                <span>{detail?.reply_manager_username}</span>
              </Form.Item>
            </>
          }
          {/* {
            detail?.email &&
              <Form.Item label="이메일">
                <span>{detail?.email}</span>
              </Form.Item>
          } */}
          {
            !detail?.checked &&
            <>
              {/* <Form.Item
                label="제목"
                name="mail_title"
                rules={[{ required: true, message: '제목을 입력해 주세요' }]}
              >
                <Input type="text" id="mail_title" />
              </Form.Item> */}
              <Form.Item
                label="답변"
                name="answer"
                // valuePropName='data'
                // getValueFromEvent={(event, editor) => editor.getData()}
                rules={[{ required: true, message: '답변 입력해 주세요' }]}
              >
                <Input.TextArea id="answer" rows={4} />
              </Form.Item>
            </>
          }
          {
            detail?.reply_answer &&
            <Form.Item label="답변">
              <p>{detail?.reply_answer}</p>
            </Form.Item>
          }
          <Form.Item
            {...tailLayout}
            className="btn-wrap"
          >
            {
              !detail?.checked ? (
                <>
                  <Button htmlType="button" onClick={onReset} disabled={submitting} >
                    Reset
                  </Button>
                  <Button htmlType="button" danger onClick={hideModal} disabled={submitting} >
                    취소
                  </Button>
                  <Button type="primary" htmlType="submit" loading={submitting} disabled={submitting} >
                    저장
                  </Button>
                </>
              ) : (
                <Button htmlType="button" danger onClick={hideModal} disabled={submitting} >
                  취소
                </Button>
              )
            }
          </Form.Item>
        </Form>
      </Modal>
      {/* <Button onClick={showModal} type="primary">수정</Button> */}
    </div>
  )
}

export default DetailPartnerModal