import { UploadOutlined } from '@ant-design/icons';
import "antd/dist/antd.css";
import {Button, DatePicker, Form, Input, InputNumber, Modal, Select, Switch} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { axiosAddManager, axiosChangeAuthority, Imanager } from "../../api/auth";
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import Swal from "sweetalert2";
import {addBanner, getBannerDetail} from "../../api/banner";
import Banner from "../../interface/Banner";
import BhEvent from "../../interface/BhEvent";
import {axiosGetAllEvent} from "../../api/event";

// const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

type AddUserListModalProps = {
  onDone?: VoidFunction;
  onClose?: VoidFunction;
  detail?: Banner;
}

const AddBannerModal = ({ onDone, detail, onClose }: AddUserListModalProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const fileInputRef = useRef<any>();
  const fileMobileInputRef = useRef<any>();
  const [selectedImageFile, setSelectedImageFile] = useState<File>();
  const [selectedMobileImageFile, setSelectedMobileImageFile] = useState<File>();
  const [events, setEvents] = useState<BhEvent[]>([]);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
    onClose && onClose();
  };

  const onReset = () => {
    form.resetFields();
  };

  const _loadEvents = async () => {
    const res = await axiosGetAllEvent({search_count: 50, search_page: 1});
    if (res) {
      setEvents(res.content);
    }
  }

  useEffect(() => {
    _loadEvents();
  }, [detail]);

  const onFinish = async (fieldsValue: any) => {
    // Should format date value before submit.
    setSubmitting(true);
    const formData = new FormData();
    // Request
    formData.append('banner_request', new Blob([JSON.stringify({
      view_seq: fieldsValue.view_seq,
      view_yn : fieldsValue.view_yn ? 1 : 0,
      event_id: fieldsValue.event_id
    })], {type: 'application/json'}));
    // File
    if (selectedImageFile) {
        formData.append('banner_image', selectedImageFile as Blob);
    }
    if (selectedMobileImageFile) {
      formData.append('mobile_banner_image', selectedMobileImageFile as Blob);
    }
    const data = await addBanner(formData);
    if (!data) {
      return Swal.fire(
          "오류",
          "프로세스 실패",
          "error"
      ).then(() => {
        setSubmitting(false);
      });
    }
    setSubmitting(false);
    onDone && onDone();
    hideModal();
    onReset();
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const _onImageFileChange = (event: any) => {
    if (event.target.files.length > 0) {
      setSelectedImageFile(event.target.files[0]);
    } else {
      setSelectedImageFile(undefined);
    }
    return event;
  }
  const _onMobileImageFileChange = (event: any) => {
    if (event.target.files.length > 0) {
      setSelectedMobileImageFile(event.target.files[0]);
    } else {
      setSelectedMobileImageFile(undefined);
    }
    return event;
  }

  return (
    <div>
      <Modal
        title={"배너 추가"}
        visible={visible}
        // onOk={hideModal}
        // onCancel={hideModal}
        centered={true}
        maskClosable={false}
        closable={false}
        // okText="确认"
        // cancelText="取消"
        className='form-modal'
        footer={null}
        destroyOnClose
      >
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item
            label="순서"
            name="view_seq"
            rules={[{ required: true, message: 'view sequence 입력해 주세요' }]}
          >
            <InputNumber size="large" min={1} max={100000} />
          </Form.Item>
          <Form.Item
              label="노출 여부"
              name="view_yn"
              valuePropName="checked"
              initialValue={true}
          >
            <Switch id="view_yn" />
          </Form.Item>
          <Form.Item
              label="이벤트 연결"
              name="event_id"
              rules={[{ required: true, message: 'Event 선택해주세요' }]}
          >
            <Select >
              {events.map((event, index) => (
                <Select.Option key={`${index}`} value={`${event.id}`}>{event.title}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="pc 이미지" name="banner_image" htmlFor="banner_image" {
            ...!selectedImageFile && {
              rules: [{ required: true, message: '이미지를 첨부해주세요' }]
            }
          }>
            <>
              <Button icon={<UploadOutlined />} onClick={() => fileInputRef?.current?.click()}>
                {!!selectedImageFile ? 'Update' : 'Upload'}
              </Button>
              <div style={{ marginTop: 8 }}>
                {(!!selectedImageFile) && (
                    <img src={URL.createObjectURL(selectedImageFile)} width={150} style={{ objectFit: 'contain' }} alt={'preview'} />
                )}
              </div>
              <input type="file" id="banner_image" onChange={_onImageFileChange} accept="image/*" ref={fileInputRef} hidden />
            </>
          </Form.Item>
          <Form.Item label="모바일 이미지" name="mobile_banner_image" htmlFor="mobile_banner_image" {
            ...!selectedMobileImageFile && {
              rules: [{ required: true, message: '이미지를 첨부해주세요' }]
            }
          } >
            <>
              <Button icon={<UploadOutlined />} onClick={() => fileMobileInputRef?.current?.click()}>
                {!!selectedMobileImageFile ? 'Update' : 'Upload'}
              </Button>
              <div style={{ marginTop: 8 }}>
                {(!!selectedMobileImageFile) && (
                    <img src={URL.createObjectURL(selectedMobileImageFile)} width={150} style={{ objectFit: 'contain' }} alt={'preview'} />
                )}
              </div>
              <input type="file" id="mobile_banner_image" onChange={_onMobileImageFileChange} accept="image/*" ref={fileMobileInputRef} hidden />
            </>
          </Form.Item>
          <Form.Item
            {...tailLayout}
            className="btn-wrap"
          >
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
            <Button htmlType="button" danger onClick={hideModal} >
              취소
            </Button>
            <Button type="primary" htmlType="submit" loading={submitting} disabled={submitting} >
              저장
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={showModal} type="primary">추가</Button>
    </div>
  );
}

export default AddBannerModal;