import {Button, Form, InputNumber, Modal} from "antd";
import React, {useEffect, useState} from "react";
import Banner from "../../../interface/Banner";
import {updateBannerViewSeq} from "../../../api/banner";

type EditBannerViewSeqModalProps = {
    visible: boolean;
    onDone?: VoidFunction;
    onClose?: (val?: boolean) => void;
    detail?: Banner;
};

export default function EditBannerViewSeqModal(props: EditBannerViewSeqModalProps) {
    const {
        visible,
        onDone,
        onClose,
        detail
    } = props;
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const onFinish = async (values: any) => {
        setSubmitting(true);
        const response = await updateBannerViewSeq(detail?.id, values.view_seq);
        setSubmitting(false);
        if (response) {
            onDone && onDone();
            _close();
        }
    }
    const onFailed = (data:any) => {
        console.log('failed', data);
    }
    const _close = () => {
        onClose && onClose(false);
    }
    useEffect(() => {
        if (visible) {
            form.setFieldsValue({
                view_seq: detail?.viewSeq
            })
        }
    }, [visible]);
    return (
        <Modal
            title="Edit Banner View Sequence"
            visible={visible}
            centered={true}
            maskClosable={false}
            closable={false}
            className='form-modal'
            footer={null}
        >
            <Form labelCol={{span: 5}} wrapperCol={{span: 9}} form={form} name="edit-event-view-seq" onFinish={onFinish} onFinishFailed={onFailed}>
                <Form.Item
                    name="view_seq"
                    label="Order"
                    rules={[{ required: true, message: 'view sequence 입력해 주세요' }]}
                    initialValue={1}
                >
                    <InputNumber size="large" min={1} max={100000} />
                </Form.Item>
            </Form>
            <Form.Item
                wrapperCol={{ span: 24 }}
                className="btn-wrap"
                style={{textAlign: 'end'}}
            >
                <Button htmlType="button" danger onClick={_close} disabled={submitting}>
                    취소
                </Button>
                <Button type="primary" onClick={() => form.submit()} htmlType="button" loading={submitting} disabled={submitting} >
                    저장
                </Button>
            </Form.Item>
        </Modal>
    );
}