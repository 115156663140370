import React, {useEffect, useState} from 'react'
import { Button, PageHeader } from 'antd'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import styled from 'styled-components';
import AddNodwBancow from './AddNodwBancow';
import EditNowBancow from './EditNowBancow';
import BhEvent from "../../interface/BhEvent";
import {axiosGetAllEvent, axiosRemoveEvents, axiosRemoveOneEvent} from "../../api/event";
import ConfirmDialog from "../../components/dialog/ConfirmDialog";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";

export type TableWrapProps = {
  type?: number;
}

const NoWBancow = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [chkid, setChkid] = useState(-1);
  const [paramsData, setParamsData] = useState<BhEvent>()
  const [queries, setQueries] = useState({
    search_page: 1,
    search_count: 10,
  });
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<BhEvent[]>([]);
  const [totalElements, setTotalElements] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const _deleteSelected = async () => {
    setSubmitting(true);
    let res;
    for (let i = 0; i < selectedIds.length; i++) {
        res = await axiosRemoveOneEvent(selectedIds[i]);
    }
    setSubmitting(false);
    setShowDeleteConfirm(false);
    // await axiosRemoveEvents(selectedIds);
    if (res) {
      Swal.fire("삭제 완료", "나우뱅카우 삭제가 완료되었습니다.", "success");
    } else {
      Swal.fire({
        icon: "error",
        title: "삭제 실패",
        text: "나우뱅카우 삭제를 실패하였습니다."
      });
    }
    _loadData();
  }

  const _loadData = async () => {
    if (!loading) setLoading(true);
    const res = await axiosGetAllEvent(queries);
    setLoading(false);
    if (res) {
      setList(res.content);
      setTotalElements(res.totalElements);
    }
    // setCallData(res && res?.data?.content);
  }

  const pageNation = (page: number) => {
    setQueries({
      ...queries,
      search_page: page+1,
    })
  }

  const onSelectionChanged = (data:any) => {
    setSelectedIds(data);
  }

  const clickbutton = (params: any, e: any) => {
    e.stopPropagation();
    // setParamsData(params.row);
    navigate(location.pathname + "/" + params.row.id);
  }

  const isClose = (pageNum: boolean) => {
    setIsOpen(pageNum);
  }

  useEffect(() => {
    _loadData();
  }, [queries]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: '아이디', width: 70, sortable: false, disableColumnMenu: true },
    { field: 'title', headerName: '제목', width: 400, sortable: false, disableColumnMenu: true },
    { field: 'startDate', headerName: '시작 날짜', width: 130, sortable: false, disableColumnMenu: true },
    { field: 'endDate', headerName: '종료 날짜', width: 130, sortable: false, disableColumnMenu: true, },
    {
      field: 'viewYn', headerName: '노출 여부', width: 100, sortable: false, disableColumnMenu: true,
      renderCell: (params) => (
        // NOTE: (kay) params 콘솔 찍어보면 데이터 나와요.
        <Tag color={params.formattedValue} >
          {
            params.formattedValue === 1 ? '노출중' : '비노출'
          }
        </Tag>
      ),
    },
    // { field: 'eventType', headerName: '이벤트 종류', width: 130, sortable: false, disableColumnMenu: true, },
    { field: 'eventType', headerName: '이벤트 종류', width: 130, sortable: false, disableColumnMenu: true, },
    {
      field: 'actions', headerName: '', width: 90, sortable: false, disableColumnMenu: true,
      renderCell: (params) => (
          <Button type="primary" ghost onClick={(e) => clickbutton(params, e)}>
            수정
          </Button>
      ),
    }
  ];

  const pageSize = 10;

  return (
    <>
      <div>
        <PageHeader
          // className="팝업 관리"
          title="나우뱅카우"
          // breadcrumb={{ routes }}
          subTitle="나우뱅카우 소식"
        />

        <div style={{display: 'flex', marginBottom: '10px'}}>
          <Link to={location.pathname+"/add"} style={{marginRight: '8px'}}>
            <Button type="primary">추가</Button>
          </Link>
          <Button type="primary" danger disabled={selectedIds.length === 0} onClick={() => setShowDeleteConfirm(true)} > 삭제 </Button>
        </div>

        <TableWrap type={pageSize}>
          <DataGrid
            className='popup-list-table'
            rows={list}
            rowCount={totalElements}
            columns={columns}
            pageSize={queries.search_count}
            rowsPerPageOptions={[queries.search_count]}
            loading={loading}
            onPageChange={pageNation}
            onSelectionModelChange={onSelectionChanged}
            paginationMode="server"
            pagination
            checkboxSelection
          // onSelectionChanged={onSelectionChanged}
          // onSelectionModelChange={(newSelection:any) => {
          //   setSelection(newSelection.rows);
          // }}
          // onRowClick={()=>test(rows.values())}
          // selectionModel={select}
          />
        </TableWrap>
        <ConfirmDialog
            visible={showDeleteConfirm}
            onClose={() => setShowDeleteConfirm(false)}
            title="삭제"
            content="선택한 항목을 모두 삭제하시겠습니까?"
            onConfirm={_deleteSelected}
            loading={submitting}
        />
      </div>
    </>
  )
}

const TableWrap = styled.div<TableWrapProps>`
  height: calc(${props => props.type} * 63px);
`

const Tag = styled.div<{ color?: number }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 30px; 
  font-size: 12px;
  color: ${props => props.color === 1 ? '#18AB56' : '#EB5757'};
  background: ${props => props.color === 1 ? '#F0FFF8' : '#FFF0F0'};
  border-radius: 8px;
  font-weight: 600;
  padding: 0 12px;
`;

export default NoWBancow