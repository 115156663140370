import axios from "axios";
import qs from "qs";
import axiosClient from "../service/axiosClient";
import httpRequest from "../service/httpRequest";

export const getFarmQnaList = async (queries:any) => {
    try {
        return await httpRequest({
            url: `farmqna`,
            method: "get",
            params: queries,
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};

export const getFarmQnaDetail = async (id:any) => {

    try {
        return await httpRequest({
            url: `farmqna/${id}`,
            method: "get",
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};

export const replyFarmQnaEmail = async (id:any, requestData: any) => {
    try {
        return await httpRequest({
            url: `farmqna/${id}/reply`,
            method: "post",
            data: requestData,
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};

export const replyFarmQnaTextMessage = async (id:any) => {
    try {
        return await httpRequest({
            url: `farmqna/send/${id}`,
            method: "get",
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};

export const deleteFarmQnaList = async (id:any[]) => {
    try {
        return await httpRequest({
            url: `farmqna/delete/`,
            method: "delete",
            data: {id}
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};

export const downloadFarmQnaCSV = async () => {
    try {
        return await httpRequest({
            url: `farmqna/excel`,
            method: "get",
            responseEncoding: 'utf-8',
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};