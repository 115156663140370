import React, {useEffect, useRef, useState} from 'react';
import {DeleteOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, DatePicker, Form, Input, Modal, Select, InputNumber, PageHeader} from 'antd';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import {axiosAddEvent} from "../../api/event";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import Constant from "../../constant/Constant";
import CustomCKEditor from "../../components/input/CustomCKEditor";

const { RangePicker }: any = DatePicker;
const { confirm } = Modal;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const AddNodwBancow = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const fileInputRef = useRef<any>();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [submitting, setSubmitting] = useState(false);

  const onFileChange = (event:any) => {
    setSelectedFiles([...event.target.files]);
  }
  const onReset = () => {
    form.resetFields();
  };

  const onFinish = async (fieldsValue: any) => {
    setSubmitting(true);
    // Should format date value before submit.
    // const rangeValue = fieldsValue['range-picker'];
    const values = {
      ...fieldsValue,
      // start_date: rangeValue[0].format('YYYY-MM-DD'),
      // end_date: rangeValue[1].format('YYYY-MM-DD'),
    };
    // delete values['range-picker'];
    delete values.image;

    const formData = new FormData();
    formData.append('event_request', new Blob(
        [
          JSON.stringify(values),
        ],
        { type: "application/json" }
    ));
    if (selectedFiles && selectedFiles.length > 0) {
      selectedFiles.forEach(file => {
        formData.append('event_image', file);
      });
    }
    const res = await axiosAddEvent(formData);
    setSubmitting(false);
    if (res) {
      Swal.fire(
          "등록 완료",
          "새로운 이벤트 등록이 완료되었습니다.",
          "success"
      ).then(() => {
        navigate(-1);
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "등록 실패",
        text: "이벤트 등록에 실패하였습니다.",
      });
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  // const onChange = (value: [moment, moment], dateString: [string, string]) => {
  //   console.log('Selected Time: ', value);
  //   console.log('Formatted Selected Time: ', dateString)
  // }

  // const onChange: DatePickerProps['onChange'] = (date, dateString) => {
  //   console.log(date, dateString);
  // };
  const onChange = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string,
  ) => {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
  };
  
  const onOk = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
    console.log('onOk: ', value);
  };

  const onNumberChange = (value: number | null) => {
    console.log('changed', value);
  };

  const _loadData = async () => {
    // return await axiosGetEventDetail(detail?.id);
  }

  useEffect(() => {
    // _loadData().then(res => {
    //   if (res) {
    //     form.setFieldsValue(res);
    //   }
    // })
  }, []);
  
  const rangeConfig = {
    rules: [{ type: 'array' as const, required: true, message: '기간을 선택해주세요' }],
  };
  const optionConfig = {
    rules: [{ type: 'string' as const, required: true, message: '노출여부를 선택해주세요' }],
  };

  return (
    <div>
      <PageHeader
          title="나우뱅카우-소식 추가"
      />
      {/*<Modal*/}
      {/*  title="나우뱅카우-소식 추가"*/}
      {/*  visible={visible}*/}
      {/*  onOk={hideModal}*/}
      {/*  onCancel={hideModal}*/}
      {/*  centered={true}*/}
      {/*  maskClosable={false}*/}
      {/*  closable={false}*/}
      {/*  okText="确认"*/}
      {/*  cancelText="取消"*/}
      {/*  className='form-modal'*/}
      {/*>*/}
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item
            label="제목"
            name="title"
            rules={[{ required: true, message: '제목을 입력해 주세요' }]}
          >
            <Input type="text" id="title" />
          </Form.Item>
          <Form.Item
            label="내용"
            name="content"
            valuePropName='data'
            getValueFromEvent={(event, editor) => editor.getData()}
            rules={[{ required: true, message: '내용을 입력해주세요' }]}
          >
            <CustomCKEditor
                id="content"
            />
          </Form.Item>
          <Form.Item
              label="Url"
              name="url"
              rules={[{ required: false, message: 'Url 입력해 주세요' }]}
          >
            <Input type="text" id="url" />
          </Form.Item>
          <Form.Item
              label="Youtube Link"
              name="youtube_link"
          >
            <Input type="text" id="youtube_link" />
          </Form.Item>
          {/*<Form.Item name="range-picker" label="노출 기간" {...rangeConfig}>*/}
          {/*  <RangePicker*/}
          {/*    format="YYYY-MM-DD"*/}
          {/*    onChange={onChange}*/}
          {/*    onOk={onOk}*/}
          {/*  />*/}
          {/*</Form.Item>*/}
          <Form.Item
              label="노출 여부"
              name="view_yn"
              rules={[{ required: true, message: '노출 여부를 선택해주세요.' }]}
          >
            <Select>
              <Select.Option key={0} value={0}>노출멈춤</Select.Option>
              <Select.Option key={1} value={1}>노출</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="이벤트 타입" name="event_type" {...optionConfig}>
            <Select>
              {Object.keys(Constant.EVENT_TYPES).map((key) => (
                  // @ts-ignore
                  <Select.Option key={key} value={key}>{Constant.EVENT_TYPES[key]}</Select.Option>
              ))}
              {/*<Select.Option value="SNS">SNS</Select.Option>*/}
              {/*<Select.Option value="NEWS">뉴스</Select.Option>*/}
              {/*<Select.Option value="BANCOW_CONTENTS">뱅카우 콘텐츠</Select.Option>*/}
              {/*<Select.Option value="NOTICE">공지사항</Select.Option>*/}
            </Select>
          </Form.Item>
          <Form.Item label="Export 타입" name="export_type" initialValue="NOTHING" {...optionConfig}>
            <Select>
              {Object.keys(Constant.EVENT_EXPORT_TYPES).map((key) => (
                  // @ts-ignore
                  <Select.Option key={key} value={key}>{Constant.EVENT_EXPORT_TYPES[key]}</Select.Option>
              ))}
              {/*<Select.Option value="NOTHING">NOTHING</Select.Option>*/}
              {/*<Select.Option value="BANCOW">BANCOW </Select.Option>*/}
            </Select>
          </Form.Item>
          <Form.Item
              name="event_view_seq"
              label="이벤트 뷰번호"
              rules={[{ required: true, message: '이벤트 뷰번호 입력해 주세요' }]}
              initialValue={1}
          >
            <InputNumber size="large" min={1} max={100000} onChange={onNumberChange} />
          </Form.Item>
          <Form.Item label="이미지 첨부" name="image" {
            ...selectedFiles.length === 0 && {
              rules: [{ required: true, message: '이미지를 첨부해주세요' }]
            }
          } >
            <>
              <Button icon={<UploadOutlined />} onClick={() => fileInputRef?.current?.click()}>
                Upload
              </Button>
              <div style={{marginTop: 8, display: "flex", flexWrap: 'wrap'}}>
                {selectedFiles && selectedFiles.length > 0 && (
                    selectedFiles.map((file, index) => (
                        <div key={index.toString()} style={{marginBottom: '10px', marginLeft: '5px', marginRight: '5px', textAlign: 'center'}}>
                          <div style={{marginBottom: '8px'}}>
                            <img
                                src={URL.createObjectURL(file)}
                                width={150} style={{objectFit: 'contain'}}  alt={'preview'}/>
                          </div>
                          <Button htmlType="button" danger
                                  onClick={() => setSelectedFiles(selectedFiles.filter((_, eIndex) => eIndex !== index))}
                                  icon={<DeleteOutlined />} style={{width: '100%'}}
                          >Remove</Button>
                        </div>
                    )
                ))}
              </div>
              <input type="file" id="image" accept="image/*" onChange={onFileChange} hidden multiple ref={fileInputRef}/>
            </>
          </Form.Item>
          <Form.Item
            {...tailLayout}
            className="btn-wrap"
            style={{textAlign: 'end'}}
          >
            <Button htmlType="button" onClick={onReset} disabled={submitting}>
              Reset
            </Button>
            <Button htmlType="button" danger onClick={() => navigate(-1)} disabled={submitting} >
              취소
            </Button>
            <Button type="primary" htmlType="submit" loading={submitting} disabled={submitting} >
              저장
            </Button>
          </Form.Item>
        </Form>
      {/*</Modal>*/}
      {/*<Button onClick={showModal} type="primary">추가</Button>*/}
    </div>
  );
}

export default AddNodwBancow;