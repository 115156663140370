import React, { ReactElement, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { Menu, SubMenuProps } from "antd";
import './leftGNB.scss';
import "antd/dist/antd.css";
import classNames from "classnames";

const { SubMenu } = Menu;

const LeftGNB = () => {
  const [current, setCurrent] = useState("home");
  const [navSelect, setNavSelect] = useState('');
  const [location, setLocation] = useState('');

  useEffect(() => {
    // setCurrent(current);
    let a = window.location.href;
    let b = a.split('/');
    setLocation(b[5]);
  }, [window.location.href]);

  const titleClick = (e: any) => {
    // console.log('aa', current);
  }

  const select = (event: any) => {
    setNavSelect(event.key);
  }

  // ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ
  // 이번 스펙에선 out 
  // <SubMenu key="analysis" title="애널리틱스">
  //         <Menu.Item key="analysis">애널리틱스</Menu.Item>
  //       </SubMenu> 


  return (
    <div>
      <Nav className="nav">
        <Menu
          defaultOpenKeys={['0']}
          defaultSelectedKeys={['0']}
          style={{ width: 200 }}
          mode="inline"
          selectedKeys={[current]}
          // onTitleClick={titleClick}
          onClick={titleClick}
          onSelect={select}
        >
          <Menu.Item key="popup" className={classNames("", {
            'is-select': location === 'popup'
          })}>
            <Link to="/manager/admin/popup">팝업 관리</Link>
          </Menu.Item>
          {/* <SubMenu key="popup" title="팝업 관리" className=""> </SubMenu> */}
          <Menu.Item key="review" className={classNames("", {
            'is-select': location === 'review'
          })}>
            <Link to="/manager/admin/review">고객후기 관리</Link>
          </Menu.Item>
          {/* <SubMenu key="review" title="고객후기 관리"> </SubMenu> */}
          <SubMenu key="qna" title="고객 센터">
            <Menu.Item key="qna" className={classNames("", {
              'is-select': location === 'howto'
            })}>
              <Link to="/manager/admin/howto/inquiry">1:1 문의</Link>
            </Menu.Item>
            <Menu.Item key="faq" className={classNames("", {
              'is-select': location === 'faq'
            })}>
              <Link to="/manager/admin/faq">자주묻는 질문</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="nowbancow" className={classNames("", {
            'is-select': location === 'nowbancow'
          })}>
            <Link to="/manager/admin/nowbancow">나우뱅카우</Link>
          </Menu.Item>
          {/* <SubMenu key="nowbancow" title="나우 뱅카우"> </SubMenu> */}
          <Menu.Item key="partner" className={classNames("", {
            'is-select': location === 'partner'
          })}>
            <Link to="/manager/admin/partner">입점 문의</Link>
          </Menu.Item>
          {/* <SubMenu key="partner" title="입점/제휴 문의"> </SubMenu> */}
          <Menu.Item key="banner" className={classNames("", {
            'is-select': location === 'banner'
          })}>
            <Link to="/manager/admin/banner">배너 관리</Link>
          </Menu.Item>
          <Menu.Item key="manager" className={classNames("", {
            'is-select': location === 'manager'
          })}>
            <Link to="/manager/admin/manager">계정 관리</Link>
          </Menu.Item>
					<Menu.Item
						key="raiser"
						className={classNames("", {
							"is-select": location === "raiser",
						})}
					>
					  <Link to="/manager/admin/raisers">입점문의-test</Link>
					</Menu.Item>
          {/* <SubMenu key="manager" title="계정 관리"> </SubMenu> */}
        </Menu>
      </Nav>
    </div>
  );
}

const Nav = styled.div`
  height: calc(100vh - 65px);
`

export default LeftGNB;