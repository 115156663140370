import { createSlice } from "@reduxjs/toolkit";

interface EditFarmer {
  farm_name: string;
  ceo_name: string;
  title: string;
  content: string;
  filefarm: File | string;
  fileceo: File | string;
  isEmpty: boolean;
  status: boolean | any;
  username: string;
  createdate: string;
}

const initialState: EditFarmer = {
  farm_name: "",
  ceo_name: "",
  title: "",
  content: "",
  filefarm: "",
  fileceo: "",
  isEmpty: false,
  status: false,
  username: "",
  createdate: "",
};

export const editFarmerSlice = createSlice({
  name: "editFarmer",
  initialState,
  reducers: {
    setFarmname: (state, { payload }) => {
      state.farm_name = payload;
    },
    setCeoname: (state, { payload }) => {
      state.ceo_name = payload;
    },
    setTitle: (state, { payload }) => {
      state.title = payload;
    },
    setContent: (state, { payload }) => {
      state.content = payload;
    },
    setFilesfarm: (state, { payload }) => {
      state.filefarm = payload;
    },
    setFilesceo: (state, { payload }) => {
      state.fileceo = payload;
    },
    setIsEmpty: (state, { payload }) => {
      state.isEmpty = payload;
    },
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    setUsername: (state, { payload }) => {
      state.username = payload;
    },
    setCreatedate: (state, { payload }) => {
      state.createdate = payload;
    },
  },
});

const { actions } = editFarmerSlice;
export const {
  setContent,
  setTitle,
  setCeoname,
  setCreatedate,
  setFarmname,
  setFilesceo,
  setFilesfarm,
  setUsername,
  setStatus,
  setIsEmpty,
} = actions;
