import { UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Modal, Select, Upload } from 'antd';
import React, {useRef, useState} from 'react';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import {postPopupRequest} from "../../api/popup";
import Swal from "sweetalert2";

// const { Option } = Select;
const { RangePicker }:any = DatePicker;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

type AddPopupModalProps = {
  onDone?: VoidFunction,
}


const AddPopupModal = ({onDone}:AddPopupModalProps) => {
  const [visible, setVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [form] = Form.useForm();
  const fileInputRef = useRef<any>();

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = async (fieldsValue: any) => {
    // Should format date value before submit.
    const rangeValue = fieldsValue['range-picker'];
    const values = {
      ...fieldsValue,
      'range-picker': [rangeValue[0].format('YYYY-MM-DD'), rangeValue[1].format('YYYY-MM-DD')],
    };
    const formData = new FormData();
    formData.append("popup_image", selectedFile as Blob);
    formData.append(
        "popup_request",
        new Blob(
            [
              JSON.stringify({
                title: values.title,
                url: values.url,
                start_date: values['range-picker'][0],
                end_date: values['range-picker'][1],
                delete_yn: values.delete_yn,
              })
            ],
            { type: "application/json" }
        )
    );
    setSubmitting(true);
    const res = await postPopupRequest(formData);
    setSubmitting(false);
    if (res) {
      onDone && onDone();
      setVisible(false);
      onReset();
      setSelectedFile(undefined);
      Swal.fire(
          "등록 완료",
          "새로운 팝업 등록이 완료되었습니다.",
          "success"
      );
    } else {
       Swal.fire({
        icon: "error",
        title: "등록 실패",
        text: "팝업 등록에 실패하였습니다."
      });
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const rangeConfig = {
    rules: [{ type: 'array' as const, required: true, message: '기간을 선택해주세요' }],
  };
  const optionConfig = {
    rules: [{ type: 'string' as const, required: true, message: '노출여부를 선택해주세요' }],
  };
  const imageConfig = {
    rules: [{ type: 'array' as const, required: true, message: '이미지를 첨부해주세요' }],
  };
  const onChange = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string,
  ) => {
    // console.log('Selected Time: ', value);
    // console.log('Formatted Selected Time: ', dateString);
  };
  
  const onOk = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
    console.log('onOk: ', value);
  };

  const onFileChange = (event:any) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    } else {
      setSelectedFile(undefined);
    }
    return event;
  }

  return (
    <div>
      <Modal
        title="팝업 추가"
        visible={visible}
        onOk={hideModal}
        onCancel={hideModal}
        centered={true}
        maskClosable={false}
        closable={false}
        okText="确认"
        cancelText="取消"
        className='form-modal'
        footer={null}
      >
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item
            label="제목"
            name="title"
            rules={[{ required: true, message: '제목을 입력해 주세요' }]}
          >
            <Input type="text" id="title" />
          </Form.Item>
          <Form.Item
            label="url"
            name="url"
            rules={[{ required: true, message: 'url을 입력해 주세요' }]}
          >
            <Input type="text" id="url" />
          </Form.Item>

          <Form.Item name="range-picker" label="노출 기간" {...rangeConfig}>
            <RangePicker
              format="YYYY-MM-DD"
              onChange={onChange}
              onOk={onOk}
            />
          </Form.Item>
          <Form.Item label="노출여부" name="delete_yn" {...optionConfig}>
            <Select id="delete_yn">
              <Select.Option value="0">노출</Select.Option>
              <Select.Option value="1">비노출</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="이미지 첨부" name="image" htmlFor="image" >
            <>
              <Button icon={<UploadOutlined />} onClick={() => fileInputRef?.current?.click()}>
                {!!selectedFile ? 'Update' : 'Upload'}
              </Button>
              <div style={{marginTop: 8}}>
                {(!!selectedFile) && (
                    <img src={URL.createObjectURL(selectedFile)} width={150} style={{objectFit: 'contain'}}  alt={'preview'}/>
                )}
              </div>
              <input type="file" id="image" onChange={onFileChange} accept="image/*" ref={fileInputRef} hidden/>
            </>
          </Form.Item>
          <Form.Item
            {...tailLayout}
            className="btn-wrap"
          >
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
            <Button htmlType="button" danger onClick={hideModal} >
              취소
            </Button>
            <Button type="primary" htmlType="submit" loading={submitting} disabled={submitting} >
              저장
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={showModal} type="primary">추가</Button>
    </div>
  )
}

export default AddPopupModal