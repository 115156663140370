import axios from "axios";
import qs from "qs";


const getAuthInfo = async () => {
    // Call API get token
    return sessionStorage.getItem("token") || "";
};
const handleUnauthorized = async (response:any) => {
    if (response?.data?.code === "TK001" || response?.data?.code === "TK005") {
        sessionStorage.removeItem("token");
        sessionStorage.clear();
        document.location.href="/";
    }
    // if ([403].includes(status)) {
    //     sessionStorage.removeItem("token")
        // @ts-ignore
        // document.location.href="/";
    // }
};
// Set up default config for http requests here
// Please have a look at here `https://github.com/axios/axios#request- config` for the full list of configs
const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_API,
    headers: {
        "content-type": "application/json",
    },
    paramsSerializer: (params:any) => qs.stringify(params),
});

axiosClient.interceptors.request.use(async (config:any) => {
    const token = await getAuthInfo();
    if (token) {
        config.headers["TOKEN"] = `${token}`;
    }

    return config;
});

axiosClient.interceptors.response.use(
    async (response) => {
        await handleUnauthorized(response?.data?.status);
        return response;
    },
    async (error) => {
        // console.log("REQUEST ERROR", {...error});
        // Handle errors
        // const status = error?.status || error?.response?.status;
        const response = error?.response;
        await handleUnauthorized(response);
        throw error;
    }
);

export default axiosClient;
