import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { PageHeader, Space, Button } from "antd";
import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  deleteQnas, downloadQnaCSV, getQnaList
} from "../../api/qna";
import DetailQnaModal from './DetailQnaModal';
import QnaModal from './QnaModal';
import ConfirmDialog from "../../components/dialog/ConfirmDialog";
import Swal from "sweetalert2";
import Util from "../../utilities/Util";

export type TableWrapProps = {
  type?: number;
}

const QnaList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [chkid, setChkid] = useState(-1);
  const [paramsData, setParamsData] = useState({});

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<any[]>([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [queries, setQueries] = useState({
    search_count: 10,
    search_page: 1
  });
  const [totalElements, setTotalElements] = useState(0);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    _loadData();
  }, [queries]);

  const _downloadCSV = async () => {
    setDownloading(true);
    const res = await downloadQnaCSV();
    await Util.downloadQnaCSV(res);
    setDownloading(false);
  }

  const _deleteSelected = async () => {
    setSubmitting(true);
    const res = await deleteQnas(selectedIds);
    setSubmitting(false);
    setShowDeleteConfirm(false);
    if (res) {
      Swal.fire("삭제 완료", "고객센터 삭제가 완료되었습니다.", "success");
      _loadData();
    } else {
      Swal.fire({
        icon: "error",
        title: "삭제 실패",
        text: "고객센터 삭제를 실패하였습니다."
      });
    }
  }

  const _loadData = async () => {
    if (!loading) setLoading(true);
    const res = await getQnaList(queries);
    setLoading(false);
    if (res) {
      setList(res.content);
      setTotalElements(res.totalElements);
    }
    // setCallData(res && res?.data?.content);
  }

  const pageNation = (page: number) => {
    setQueries({
      ...queries,
      search_page: page + 1,
    })
  }

  const onSelectionChanged = (data: any) => {
    setSelectedIds(data);
  }

  const clickbutton = (params: any, e: any) => {
    e.stopPropagation();
    // console.log('pr : ', params.row);
    // console.log('typeof:', typeof (params.row))
    // console.log('id', params.id);
    setIsOpen(true);
    setChkid(params.id);
    setParamsData(params.row);
  }

  const isClose = (pageNum: boolean) => {
    setIsOpen(pageNum);
  }
  const pageSize = 10;

  const Type = (type: string) => {
    let result;
    
    if (type === 'INVESTMENT') {
      result = '뱅카우 개선사항 건의'
    }
    if (type === 'BUG') {
      result = '뱅카우 사용 시 불편사항'
    }
    if (type === 'ETC') {
      result = '기타'
    }
    console.log('type', result);
    return result;
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70, sortable: false, disableColumnMenu: true },
    {
      field: 'category', headerName: '카테고리', width: 200, sortable: true, disableColumnMenu: false,
      // valueFormatter: (params) => { params.value === 'INVESTMENT' && '뱅카우 사용 시 불편사항', params.value === 'INVESTMENT' && '뱅카우 사용 시 불편사항' }
      valueFormatter: (params) => params.value === 'INVESTMENT' ? '뱅카우 개선사항 건의' : params.value === 'BUG' ? '뱅카우 사용 시 불편사항' : params.value === 'ETC' ? '기타' : ''
    },
    { field: 'title', headerName: '문의 제목', width: 200, sortable: false, disableColumnMenu: true },
    { field: 'message', headerName: '문의 내용', width: 200, sortable: false, disableColumnMenu: true },
    { field: 'qna_name', headerName: '이름', width: 130, sortable: false, disableColumnMenu: true, },
    { field: 'replyType', headerName: '답변채널', width: 150, sortable: false, disableColumnMenu: true },
    // { field: 'message', headerName: '내용', width: 350, sortable: false, disableColumnMenu: true },
    {
      field: 'checked', headerName: '답변 완료', width: 100, sortable: false, disableColumnMenu: true,
      valueFormatter: (params) => params.value ? '답변완료' : '답변대기'
    },
    {
      field: 'actions', headerName: '상세보기', width: 110, sortable: false, disableColumnMenu: true,
      renderCell: (params) => (
        <>
          {
            params.row.checked ?
              <Button type="primary" ghost onClick={(e) => clickbutton(params, e)}>
                답변보기
              </Button> :
              <Button type="primary" onClick={(e) => clickbutton(params, e)}>
                답변하기
              </Button>
          }
          {
            (isOpen && params.id === chkid) &&
            <DetailQnaModal paramsData={paramsData} isOpen={isOpen} isClose={isClose} onDone={_loadData} />
          }
        </>
      ),
    }
    // {
    //   field: 'fullName', headerName: 'Full name', description: 'This column has a value getter and is not sortable.', sortable: false, width: 160,
    //   valueGetter: (params: GridValueGetterParams) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  const openDetail = () => {
    return (
      <QnaModal />
    )
  }

  return (
    <div>
      <PageHeader
        // className="팝업 관리"
        title="고객센터 "
        // breadcrumb={{ routes }}
        subTitle="1:1 문의 목록"
      />

      <div style={{ display: "flex", justifyContent: 'space-between', marginBottom: "20px" }}>
        <Button
          type="primary" danger style={{ marginLeft: '8px' }} disabled={selectedIds.length === 0}
          onClick={() => setShowDeleteConfirm(true)}
        > 삭제 </Button>
        <Button
            type="primary" style={{ marginRight: '8px', marginLeft: '8px' }}
            onClick={_downloadCSV} loading={downloading} disabled={downloading}
        > Export CSV </Button>
      </div>

      <Space direction="vertical">
        {/* <Search
            placeholder="이름, 휴대폰번호"
            allowClear
            onSearch={onSearch}
            style={{
              width: 200,
            }}
          /> */}
      </Space>
      <div>
        <TableWrap type={pageSize}>
          <DataGrid
            className='popup-list-table'
            rows={list}
            rowCount={totalElements}
            columns={columns}
            pageSize={queries.search_count}
            rowsPerPageOptions={[queries.search_count]}
            loading={loading}
            onPageChange={pageNation}
            onSelectionModelChange={onSelectionChanged}
            paginationMode="server"
            pagination
            checkboxSelection
            onRowClick={openDetail}
          />
        </TableWrap>
      </div>
      <ConfirmDialog
        visible={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        title="삭제"
        content="선택한 항목을 모두 삭제하시겠습니까?"
        onConfirm={_deleteSelected}
        loading={submitting}
      />
    </div>
  );
};

const TableWrap = styled.div<TableWrapProps>`
  height: calc(${props => props.type} * 63px);
`

export default QnaList;
