import { createSlice } from "@reduxjs/toolkit";
import { Ievent } from "../../api/event";

interface Event {
  totalpage: number;
  eventlist: Ievent[];
}

const initialState: Event = {
  totalpage: 0,
  eventlist: [],
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setTotalpage: (state, { payload }) => {
      state.totalpage = payload;
    },
    setEventlist: (state, { payload }) => {
      state.eventlist = payload;
    },
  },
});

const { actions } = eventSlice;
export const { setEventlist, setTotalpage } = actions;
