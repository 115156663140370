import {Button, Form, Modal, Select} from "antd";
import BhEvent from "../../../interface/BhEvent";
import React, {useEffect, useState} from "react";
import {axiosEditEventExportType, axiosEditEventType} from "../../../api/event";
import Constant from "../../../constant/Constant";

type EditEventExportTypeModalProps = {
    visible: boolean;
    onDone?: VoidFunction;
    onClose?: (val?: boolean) => void;
    detail?: BhEvent;
};

const optionConfig = {
    rules: [{ type: 'string' as const, required: true, message: '노출여부를 선택해주세요' }],
};

export default function EditEventExportTypeModal(props: EditEventExportTypeModalProps) {
    const {
        visible,
        onDone,
        onClose,
        detail
    } = props;
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const onFinish = async (values: any) => {
        setSubmitting(true);
        const response = await axiosEditEventExportType(detail?.id, values.export_type);
        setSubmitting(false);
        if (response) {
            onDone && onDone();
            _close();
        }
    }
    const onFailed = (data:any) => {
        console.log('failed', data);
    }
    const _close = () => {
        onClose && onClose(false);
    }
    useEffect(() => {
        if (visible) {
            form.setFieldsValue({
                export_type: detail?.exportType
            })
        }
    }, [visible]);
    return (
        <Modal
            title="Edit Event Export Type"
            visible={visible}
            centered={true}
            maskClosable={false}
            closable={false}
            className='form-modal'
            footer={null}
        >
            <Form labelCol={{span: 5}} wrapperCol={{span: 9}} form={form} name="edit-event-export-type" onFinish={onFinish} onFinishFailed={onFailed}>
                <Form.Item label="Export 타입" name="export_type" initialValue="NOTHING" {...optionConfig}>
                    <Select>
                        {Object.keys(Constant.EVENT_EXPORT_TYPES).map((key) => (
                            // @ts-ignore
                            <Select.Option key={key} value={key}>{Constant.EVENT_EXPORT_TYPES[key]}</Select.Option>
                        ))}
                        {/*<Select.Option value="NOTHING">NOTHING</Select.Option>*/}
                        {/*<Select.Option value="BANCOW">BANCOW </Select.Option>*/}
                    </Select>
                </Form.Item>
            </Form>
            <Form.Item
                wrapperCol={{ span: 24 }}
                className="btn-wrap"
                style={{textAlign: 'end'}}
            >
                <Button htmlType="button" danger onClick={_close} disabled={submitting}>
                    취소
                </Button>
                <Button type="primary" onClick={() => form.submit()} htmlType="button" loading={submitting} disabled={submitting} >
                    저장
                </Button>
            </Form.Item>
        </Modal>
    );
}