import { Button, Form, Input, Modal } from 'antd';
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import { getQnaDetail, postQnaAnswer } from "../../api/qna";
import CustomCKEditor from "../../components/input/CustomCKEditor";
import styled from 'styled-components';

const { confirm } = Modal;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

type data = {
  id?: number,
  category?: string,
  email?: string,
  title?: string,
  message?: string,
  device?: string,
  checked?: boolean,
  open?: number,
  openPassword?: string,
  replyType?: string,
  qna_name?: string,
  phone_number?: string,
  create_date?: string
  reply_answer?: string;
}

type paramsDataType = {
  paramsData?: data,
  isOpen: boolean,
  isClose: (value: boolean) => void,
  onDone?: VoidFunction
}

const DetailQnaModal = ({ paramsData, isOpen, isClose, onDone }: paramsDataType) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [detail, setDetail] = useState<data | undefined>(paramsData);
  const [submitting, setSubmitting] = useState(false);

  const _loadData = async () => {
    const res = await getQnaDetail(paramsData?.id);
    if (res) {
      setDetail(res);
    }
    return res;
  }

  useEffect(() => {
    showModal();
    _loadData();
  }, []);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
    isClose(false)
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = async (fieldsValue: any) => {
    // Should format date value before submit.
    setSubmitting(true);
    const res = await postQnaAnswer(paramsData?.id, fieldsValue);
    if (res) {
      hideModal();
      onDone && onDone();
    }
    setSubmitting(false);
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <Modal
        title="1:1 문의 답변"
        visible={visible}
        onOk={hideModal}
        onCancel={hideModal}
        centered={true}
        maskClosable={false}
        closable={false}
        okText="确认"
        cancelText="取消"
        className='form-modal'
        footer={null}
      >
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item
            label="카테고리"
          >
            <p>{detail?.category}</p>
          </Form.Item>
          <Form.Item
            label="운영체제"
          >
            <p>{detail?.device}</p>
          </Form.Item>
          <Form.Item
            label="작성자 명"
          >
            <p>{detail?.qna_name}</p>
          </Form.Item>
          <Form.Item
            label="휴대전화번호"
          >
            <p>{detail?.phone_number}</p>
          </Form.Item>
          {
            detail?.email &&
            <Form.Item
              label="이메일"
            >
              <p>{detail?.email}</p>
            </Form.Item>

          }
          <Form.Item
            label="답변채널"
          >
            <p>{detail?.replyType}</p>
          </Form.Item>
          <Form.Item
            label="질문 작성일"
          >
            <p>{detail?.create_date}</p>
          </Form.Item>
          <Form.Item
            label="제목"
          >
            <p>{detail?.title}</p>
          </Form.Item>
          <Form.Item
            label="내용"
          >
            <p>{detail?.message}</p>
          </Form.Item>
          {/* {(detail?.replyType === 'EMAIL') && (
            <Form.Item
              label="제목"
              name="mail_title"
              rules={[{ required: true, message: '제목을 입력해 주세요' }]}
            >
              <Input type="text" id="mail_title" />
            </Form.Item>
          )} */}
          {detail?.checked ? null :
            detail?.replyType === 'SMS' ? (
              <Form.Item
                label="답변"
                name="answer"
                rules={[{ required: true, message: '답변 내용을 입력해 주세요' }]}
              >
                <TextArea rows={4} placeholder="답변" id="answer" />
              </Form.Item>
            ) : (
              <Form.Item
                label="답변"
                name="answer"
                valuePropName='data'
                getValueFromEvent={(event: any, editor: any) => editor.getData()}
                rules={[{ required: true, message: '답변 입력해 주세요' }]}
              >
                <CustomCKEditor
                  id="answer"
                />
              </Form.Item>
            )
          }
          {
            detail?.reply_answer &&
            <Form.Item
              label="답변"
            >
              <p>{detail?.reply_answer}</p>
            </Form.Item>
          }
          <Form.Item
            {...tailLayout}
            className="btn-wrap"
          >
            {
              !detail?.checked &&
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
            }
            <Button htmlType="button" danger onClick={hideModal} >
              취소
            </Button>
            {
              !detail?.checked &&
              <Button type="primary" htmlType="submit" loading={submitting} disabled={submitting} >
                답변
              </Button>
            }
          </Form.Item>
        </Form>
      </Modal>
      {/* <Button onClick={showModal} type="primary">수정</Button> */}
    </div>
  );
}

const Flex = styled.div`
  display: flex;
`

const Title = styled.p`
  margin-right: 8px;
  font-weigth: bold;
  width: 80px;
`



export default DetailQnaModal;