import axios, { AxiosRequestConfig, Method } from "axios";
import httpRequest from "../service/httpRequest";
// const baseApi = `manager/`;
const baseApi = process.env.REACT_APP_BASE_API + "manager/";

interface IuserInfo {
  email: string;
  username: string;
  password: string;
  password2: string;
}

interface defaultResponse {
  data: {
    result: boolean;
    message: string;
  };
  status: string;
}

// 회원 가입
export const axiosSignup = async (userInfo: IuserInfo) => {
  try {
    const res = await httpRequest({
      url: `${baseApi}register`,
      method: "POST",
      data: userInfo,
    });
    return res.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

interface ISigninInfo {
  email: string;
  password: string;
}

interface signinSuccess {
  data: {
    accessToken: string;
    refreshToken: string;
    message: string;
  };
  status: string;
}

// 로그인
export const axiosSignin = async (userInfo: ISigninInfo) => {
  try {
    return await httpRequest({
      url: `${baseApi}login`,
      method: "POST",
      data: userInfo,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

// 로그아웃
export const axiosLogout = async () => {
  try {
    const res = await httpRequest({
      url: `${baseApi}logout`,
      method: "POST",
    });
    return res?.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

interface IAuth {
  email: string;
  username: string;
}

// 비밀번호 변경 인증
export const axiosMyAuth = async (userInfo: IAuth) => {
  try {
    const res = await httpRequest({
      url: `${baseApi}findmanager`,
      data: userInfo,
      method: "POST",
    });
    return res?.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

interface IChangePassword {
  password1: string;
  password2: string;
  sendEmail?: string;
}

// 비밀번호 변경
export const axiosChangePassword = async (userInfo: IChangePassword) => {
  console.log('dd', userInfo.sendEmail);
  const token = sessionStorage.getItem("refreshToken");
  const email = sessionStorage.getItem("user");
  const data1 = {
    email: userInfo.sendEmail,
    password: userInfo.password1,
    confirmPassword: userInfo.password2,
  };
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      'token': token
    }
  };
  try {
    // @ts-ignore
    const res = await axios({
      url: `${baseApi}change/password`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'token': token
      },
      data: data1,
    });
    // console.log("res", res);
    const result = res;
    return result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

// 계정 정보 수정
export const axiosChangeManger = async (token: string, status: string) => {
  try {
    return await httpRequest({
      url: `${baseApi}change/password`,
      method: "patch",
      params: { status },
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};
export interface Imanager {
  id: number;
  email: string;
  username: string;
  managerStatus: string;
  createDate: string;
  updateDate: string;
}

interface Iallmanager {
  data: {
    content: Imanager[];
    number: number; // page
    totalElements: number; // 계산 안된
    totalPages: number; // 계산된
  };
  status: string;
}

// 모든 메니저 정보 가져오기
export const axiosGetallmanager = async (params: {
  search_page: number;
  search_count: number;
}) => {
  try {
    const data = await httpRequest({
      url: `${baseApi}list`,
      params: params,
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

// 권한 변경
export const axiosChangeAuthority = async (id: number, status: string) => {
  try {
    return await httpRequest({
      url: `${baseApi}status/${id}`,
      method: "patch",
      params: { status },
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

// Add manager
export const axiosAddManager = async (inputData: any) => {
  try {
    return await httpRequest({
      url: `${baseApi}add/manager`,
      method: "post",
      data: inputData,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};

export const axiosDeleteManager = async (id: any) => {
  try {
    return await httpRequest({
      url: `${baseApi}${id}`,
      method: "delete",
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new Error("different error than axios");
    }
  }
};
