import { createSlice } from "@reduxjs/toolkit";

interface AddFarmer {
  farm_name: string;
  ceo_name: string;
  title: string;
  content: string;
  filefarm: File | string;
  fileceo: File | string;
  isEmpty: boolean;
}

const initialState: AddFarmer = {
  farm_name: "",
  ceo_name: "",
  title: "",
  content: "",
  filefarm: "",
  fileceo: "",
  isEmpty: true,
};

export const addFarmerSlice = createSlice({
  name: "addFarmer",
  initialState,
  reducers: {
    setFarmname: (state, { payload }) => {
      state.farm_name = payload;
    },
    setCeoname: (state, { payload }) => {
      state.ceo_name = payload;
    },
    setTitle: (state, { payload }) => {
      state.title = payload;
    },
    setContent: (state, { payload }) => {
      state.content = payload;
    },
    setFilesfarm: (state, { payload }) => {
      state.filefarm = payload;
    },
    setFilesceo: (state, { payload }) => {
      state.fileceo = payload;
    },
    setIsEmpty: (state, { payload }) => {
      state.isEmpty = payload;
    },
  },
});

const { actions } = addFarmerSlice;
export const {
  setIsEmpty,
  setContent,
  setCeoname,
  setFarmname,
  setFilesceo,
  setFilesfarm,
  setTitle,
} = actions;
