
const Constant = Object.freeze({
    EVENT_TYPES: {
        SNS: 'SNS',
        NEWS: '뉴스',
        BANCOW_CONTENTS: '뱅카우 콘텐츠',
        NOTICE: '공지사항',
    },
    EVENT_EXPORT_TYPES: {
        NOTHING: 'NOTHING',
        BANCOW: 'BANCOW'
    }
})

export default Constant;