import { createSlice } from "@reduxjs/toolkit";

interface EditEvent {
  title: string;
  content: string;
  url: string;
  file: File | string;
  status: boolean | any;
  username: string;
  createdate: string;
  isEmpty: boolean;
}

const initialState: EditEvent = {
  title: "",
  content: "",
  url: "",
  file: "",
  status: false,
  username: "",
  createdate: "",
  isEmpty: false,
};

export const editEventSlice = createSlice({
  name: "editEvent",
  initialState,
  reducers: {
    setTitle: (state, { payload }) => {
      state.title = payload;
    },
    setContent: (state, { payload }) => {
      state.content = payload;
    },
    setUrl: (state, { payload }) => {
      state.url = payload;
    },
    setFiles: (state, { payload }) => {
      state.file = payload;
    },
    setIsEmpty: (state, { payload }) => {
      state.isEmpty = payload;
    },
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    setUsername: (state, { payload }) => {
      state.username = payload;
    },
    setCreatedate: (state, { payload }) => {
      state.createdate = payload;
    },
  },
});

const { actions } = editEventSlice;
export const {
  setCreatedate,
  setContent,
  setTitle,
  setStatus,
  setFiles,
  setIsEmpty,
  setUrl,
  setUsername,
} = actions;
