import axios from "axios";
import axiosClient from "../service/axiosClient";
import httpRequest from "../service/httpRequest";

export const getFaqCategoryList = async () => {
    try {
        return await httpRequest({
            url: `faq/category/admin/list`,
            method: "get",
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};

export const addFaqCategory = async (requestData:{category: string}) => {
    try {
        return await httpRequest({
            url: `faq/category/add`,
            method: "post",
            data: requestData,
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};

export const updateFaqCategory = async (id: any, requestData:{category: string}) => {
    try {
        return await httpRequest({
            url: `faq/category/${id}`,
            method: "patch",
            data: requestData,
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};

export const deleteFaqCategory = async (id: any) => {
    try {
        return await httpRequest({
            url: `faq/category/${id}`,
            method: "delete",
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log(error.message);
        } else {
            throw new Error("different error than axios");
        }
    }
};