import { Button, DatePicker, Form, Input, Modal, Select, Upload, Space } from 'antd';
import "antd/dist/antd.css";
import React, {useEffect, useState} from 'react';
import CategoryDrawer from "./category/CategoryDrawer";
import Category from "../../interface/Category";
import {getFaqCategoryList} from "../../api/category";
import {addFaq, getAdminFaqDetail, getClientFaqDetail, updateFaq} from "../../api/qna";
import Swal from "sweetalert2";
import FAQ from "../../interface/FAQ";
import CustomCKEditor from "../../components/input/CustomCKEditor";

const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

type AddFaqpModalProps = {
  onDone?: VoidFunction|any,
  onClose?: VoidFunction|any,
  isUpdate?: boolean,
  faqDetail?: FAQ,
  isClient?: boolean,
}

const AddFaqpModal = ({onDone, isUpdate, faqDetail, onClose, isClient}: AddFaqpModalProps) => {

  // const onChange = (value: [moment, moment], dateString: [string, string]) => {
  //   console.log('Selected Time: ', value);
  //   console.log('Formatted Selected Time: ', dateString)
  // }
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [showCategoryDrawer, setShowCategoryDrawer] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setVisible(Boolean(faqDetail));
  }, [faqDetail]);

  useEffect(() => {
    if (isUpdate) {
      _loadDetail().then((res) => {
        let data = res;
        if (!res) {
          data = faqDetail;
        }
        const category = categories.find(item => item.category === data.category);
        form.setFieldsValue({
          faq_category_id: category ? `${category?.id}` : '',
          title: data.title,
          message: data.message
        })
      });
    }
  }, [isUpdate])

  useEffect(() => {
    _loadCategories();
  }, []);

  const _loadDetail = async () => {
    if (isClient) {
      return await getClientFaqDetail(faqDetail?.id);
    } else {
      return await getAdminFaqDetail(faqDetail?.id);
    }
  }

  const _loadCategories = async () => {
    const data = await getFaqCategoryList();
    if (data) {
      setCategories(data);
    }
  };

  const showModal = () => {
    setVisible(true);
    onReset();
  };

  const hideModal = () => {
    setVisible(false);
    onClose && onClose();
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = async (fieldsValue: any) => {
    let res;
    if (isUpdate) {
      res = await updateFaq(faqDetail?.id, fieldsValue);
    } else {
      res = await addFaq(fieldsValue);
    }
    setSubmitting(false);
    if (res) {
      hideModal();
      onDone && onDone();
    } else {
      Swal.fire({
        icon: "error",
        title: "오류",
        text: "알 수없는 오류가 발생했습니다."
      });
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <Modal
        title="FAQ"
        visible={visible}
        onOk={hideModal}
        onCancel={hideModal}
        centered={true}
        maskClosable={false}
        closable={false}
        okText="确认"
        cancelText="取消"
        className='form-modal'
        footer={null}
      >
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <div style={{display: 'flex', justifyContent: 'end', marginBottom: '8px'}}>
            <Button type="primary" ghost size="small" onClick={() => setShowCategoryDrawer(true)}>Manage Category</Button>
          </div>
          <Form.Item
              label="카테고리"
              name="faq_category_id"
              style={{width: '100%', marginRight: '10px'}}
              rules={[{ type: 'string' as const, required: true, message: '노출여부를 선택해주세요' }]}
          >
            <Select id="faq_category_id">
              {categories.map((item) => (
                  <Select.Option value={`${item.id}`} key={`${item.id}`}>{item.category}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="제목"
            name="title"
            rules={[{ required: true, message: '제목을 입력해 주세요' }]}
          >
            <Input type="text" id="title" />
          </Form.Item>
          <Form.Item
            label="내용 내용"
            name="message"
            valuePropName='data'
            getValueFromEvent={(event, editor) => editor.getData()}
            rules={[{ required: true, message: '내용 입력해주세요' }]}
          >
            <CustomCKEditor
                id="message"
            />
          </Form.Item>
          <Form.Item
            {...tailLayout}
            className="btn-wrap"
          >
            <Button htmlType="button" onClick={onReset} disabled={submitting}>
              Reset
            </Button>
            <Button htmlType="button" danger onClick={hideModal} disabled={submitting}>
              취소
            </Button>
            <Button type="primary" htmlType="submit" loading={submitting} disabled={submitting}>
              저장
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <CategoryDrawer visible={showCategoryDrawer} onClose={(value) => setShowCategoryDrawer(value)}/>
      <Button onClick={showModal} type="primary">추가</Button>
    </div>
  );
}

export default AddFaqpModal;