import './EditEvent.scss';
import {EditOutlined} from '@ant-design/icons';
import {Button, DatePicker, Form, Input, Modal, PageHeader, Select} from 'antd';
import React, { useEffect, useState } from 'react';
import "antd/dist/antd.css";
import {useNavigate, useParams} from "react-router-dom";
import {axiosEditEvent, axiosGetEventDetail} from "../../api/event";
import BhEvent, {EventImage} from "../../interface/BhEvent";
import moment from "moment";
import Swal from "sweetalert2";
import EventImageEditor from "./EventImageEditor";
import EditEventTypeModal from "./modal/EditEventTypeModal";
import EditEventExportTypeModal from "./modal/EditEventExportTypeModal";
import EditEventViewSeqModal from "./modal/EditEventViewSeqModal";
import Constant from "../../constant/Constant";
import CustomCKEditor from "../../components/input/CustomCKEditor";
import EditEventViewModal from "./modal/EditEventViewModal";

const { RangePicker }:any = DatePicker;
const { confirm } = Modal;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

type paramsDataType = {
  // paramsData? : data,
  // isOpen: boolean,
  // isClose : (value: boolean) => void
}

const EditNowBancow = (props : paramsDataType) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const params = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [eventImages, setEventImages] = useState<EventImage[]>([]);
  const [detail, setDetail] = useState<BhEvent>();
  const [showEditEventView, setShowEditEventView] = useState(false);
  const [showEditEventType, setShowEditEventType] = useState(false);
  const [showEditEventExportType, setShowEventExportType] = useState(false);
  const [showEditEventViewSeq, setShowEditEventViewSeq] = useState(false);

  const _loadData = async () => {
    const response: BhEvent = await axiosGetEventDetail(params.id);
    if (response) {
      setDetail(response);
      // const dateRange = [];
      // if (!!response.startDate) {
      //   dateRange.push(moment(response.startDate, 'YYYY-MM-DD'));
      // }
      // if (!!response.endDate) {
      //   dateRange.push(moment(response.endDate, 'YYYY-MM-DD'));
      // }
      form.setFieldsValue({
        title: response.title,
        content: response.content,
        url: response.url,
        youtube_link: response.youtubeLink || '',
        // view_yn: response.viewYn,
        // 'range-picker': dateRange
      });
      setEventImages(response.eventImage);
    } else {
      navigate(-1);
    }
  }

  const onReset = () => {
    form.resetFields();
  };
  const onFinish = async (fieldsValue: any) => {
    setSubmitting(true);
    // Should format date value before submit.
    // const rangeValue = fieldsValue['range-picker'];
    const values = {
      ...fieldsValue,
      // start_date: rangeValue[0].format('YYYY-MM-DD'),
      // end_date: rangeValue[1].format('YYYY-MM-DD'),
    };
    // delete values['range-picker'];
    const res = await axiosEditEvent(params.id, values);
    setSubmitting(false);
    if (res) {
      Swal.fire("수정 완료", "이벤트 수정이 완료되었습니다.", "success");
      _loadData();
    } else {
      Swal.fire({
        icon: "error",
        title: "수정 실패",
        text: "이벤트 수정이 실패하였습니다.",
      });
    }

  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const rangeConfig = {
    rules: [{ type: 'array' as const, required: true, message: '기간을 선택해주세요' }],
  };

  useEffect(() => {
    _loadData();
  }, [])

  return (
    <div>
      <PageHeader
          title="나우뱅카우-소식 수정"
      />
      {/*<Modal*/}
      {/*  title="나우뱅카우-소식 수정"*/}
      {/*  visible={visible}*/}
      {/*  onOk={hideModal}*/}
      {/*  onCancel={hideModal}*/}
      {/*  centered={true}*/}
      {/*  maskClosable={false}*/}
      {/*  closable={false}*/}
      {/*  okText="确认"*/}
      {/*  cancelText="取消"*/}
      {/*  className='form-modal'*/}
      {/*>*/}
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item
            label="제목"
            name="title"
            rules={[{ required: true, message: '제목을 입력해 주세요' }]}
          >
            <Input type="text" id="title" />
          </Form.Item>
          <Form.Item
              label="내용"
              name="content"
              valuePropName='data'
              getValueFromEvent={(event, editor) => editor.getData()}
              rules={[{ required: true, message: '내용을 입력해주세요' }]}
          >
            <CustomCKEditor
                id="content"
            />
          </Form.Item>
          <Form.Item
              label="Url"
              name="url"
              rules={[{ required: false, message: 'Url 입력해 주세요' }]}
          >
            <Input type="text" id="url" />
          </Form.Item>
          <Form.Item
              label="Youtube Link"
              name="youtube_link"
          >
            <Input type="text" id="youtube_link" />
          </Form.Item>
          {/*<Form.Item name="range-picker" label="노출 기간" {...rangeConfig}>*/}
          {/*  <RangePicker />*/}
          {/*</Form.Item>*/}
          <Form.Item label="노출 여부">
            <div className="flex items-center">
              <div className="mr-8">{
                // @ts-ignore
                detail?.viewYn == 1 ? '노출중' : '노출멈춤'
              }</div>
              <Button
                  type="primary"
                  htmlType="button"
                  icon={<EditOutlined />}
                  ghost
                  size="small"
                  onClick={() => setShowEditEventView(true)}
              >
              </Button>
            </div>
          </Form.Item>
          <Form.Item label="이벤트 타입">
            <div className="flex items-center">
              <div className="mr-8">{
                // @ts-ignore
                detail?.eventType ? Constant.EVENT_TYPES[`${detail?.eventType}`] : 'UNKNOWN'
              }</div>
              <Button
                type="primary"
                htmlType="button"
                icon={<EditOutlined />}
                ghost
                size="small"
                onClick={() => setShowEditEventType(true)}
              >
              </Button>
            </div>
          </Form.Item>
          <Form.Item label="Export 타입">
            <div className="flex items-center">
              <div className="mr-8">{
                // @ts-ignore
                detail?.exportType ? Constant.EVENT_EXPORT_TYPES[`${detail?.exportType}`] : 'NOTHING'
              }</div>
              <Button
                  type="primary"
                  htmlType="button"
                  icon={<EditOutlined />}
                  ghost
                  size="small"
                  onClick={() => setShowEventExportType(true)}
              >
              </Button>
            </div>
          </Form.Item>
          {/* <Form.Item label="이벤트 뷰번호">
            <div className="flex items-center">
              <div className="mr-8">{detail?.eventViewSeq}</div>
              <Button
                  type="primary"
                  htmlType="button"
                  icon={<EditOutlined />}
                  ghost
                  size="small"
                  onClick={() => setShowEditEventViewSeq(true)}
              >
              </Button>
            </div>
          </Form.Item> */}
          <Form.Item
            {...tailLayout}
            className="btn-wrap"
            style={{textAlign: 'end'}}
          >
            <Button htmlType="button" onClick={onReset} disabled={submitting}>
              Reset
            </Button>
            <Button htmlType="button" danger onClick={() => navigate(-1)} disabled={submitting} >
              취소
            </Button>
            <Button type="primary" htmlType="submit" loading={submitting} disabled={submitting} >
              저장
            </Button>
          </Form.Item>
        </Form>
      <EditEventViewModal visible={showEditEventView} onClose={() => setShowEditEventView(false)} onDone={_loadData} detail={detail}/>
      <EditEventTypeModal visible={showEditEventType} onClose={() => setShowEditEventType(false)} onDone={_loadData} detail={detail}/>
      <EditEventExportTypeModal visible={showEditEventExportType} onClose={() => setShowEventExportType(false)} onDone={_loadData} detail={detail}/>
      <EditEventViewSeqModal visible={showEditEventViewSeq} onClose={() => setShowEditEventViewSeq(false)} onDone={_loadData} detail={detail}/>
      <EventImageEditor list={eventImages} onChange={_loadData}/>
      {/*</Modal>*/}
      {/* <Button onClick={showModal} type="primary">추가</Button> */}
    </div>
  );
}

export default EditNowBancow;