import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Button, Image, PageHeader } from "antd";
import { useEffect, useState } from "react";
import styled from 'styled-components';
import moment from "moment";
import { deleteBanner, getBannerList } from "../../api/banner";
import Banner from "../../interface/Banner";
import ConfirmDialog from "../../components/dialog/ConfirmDialog";
import BannerFormModal from "./BannerFormModal";
import Util from "../../utilities/Util";
import { useLocation, useNavigate } from "react-router-dom";

export type TableWrapProps = {
  type?: number;
}

const pageSize = 10;

const BannerPage = () => {
  const [totalElements, setTotalElements] = useState(0);
  const [userlist, setUserlist] = useState<Banner[]>([]);
  const [queries, setQueries] = useState({
    search_count: 10,
    search_page: 1,
  });
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [bannerDetail, setBannerDetail] = useState<Banner | undefined>();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | undefined>();

  const navigate = useNavigate();
  const location = useLocation();

  const _renderImageCell = (params: any) => {
    if (Util.isValidURL(params.value)) {
      return (
        <img
          src={params.value}
          alt="thumbnail"
          height="30px" width="50px"
          style={{ objectFit: 'cover', cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            setImagePreviewUrl(params.value);
          }}
        />
      );
    } else {
      return params.value
    }
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70, sortable: false, disableColumnMenu: true },
    { field: 'imageUrl', headerName: 'pc 이미지 경로', width: 160, sortable: false, disableColumnMenu: true, renderCell: _renderImageCell },
    { field: 'mobileImageUrl', headerName: '모바일 이미지 경로', width: 160, sortable: false, disableColumnMenu: true, renderCell: _renderImageCell },
    { field: 'viewSeq', headerName: '순서', width: 130, sortable: false, disableColumnMenu: true, },
    {
      field: 'createDate', headerName: '생성 날짜', width: 200, sortable: false, disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => moment(params.row.create_date).format("DD MMM YYYY, hh:mm a")
    },
    {
      field: 'viewYn', headerName: '노출 여부', width: 100, sortable: false, disableColumnMenu: true,
      renderCell: (params) => (
        // NOTE: (kay) params 콘솔 찍어보면 데이터 나와요.
        <Tag color={params.formattedValue} >
          {
            params.formattedValue === 1 ? '노출중' : '비노출'
          }
        </Tag>
      ),
    },
    {
      field: 'edit', headerName: '', width: 70, sortable: false, disableColumnMenu: true,
      renderCell: (params) =>
        <Button type="primary" ghost onClick={(e) => _onInfoClick(params, e)}>수정</Button>
    },
  ];

  const _onInfoClick = (params: GridRenderCellParams, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    // setBannerDetail(params.row);
    navigate(location.pathname + "/" + params.row.id);
  }

  const _deleteSelected = async () => {
    setSubmitting(true);
    for (let i = 0; i < selectedIds.length; i++) {
      await deleteBanner(selectedIds[i]);
    }
    setSubmitting(false);
    setShowDeleteConfirm(false);
    _loadData();
  }

  const onSelectionChanged = (data: any, detail: any) => {
    setSelectedIds(data);
  }

  const pageNation = async (page: number, detail: any) => {
    setQueries({
      ...queries,
      search_page: page + 1
    })
  };

  const _loadData = async () => {
    if (!loading) setLoading(true);
    const data = await getBannerList(queries);
    setLoading(false);
    if (data) {
      setUserlist(data.content);
      setTotalElements(data.totalElements);
    }
  }

  useEffect(() => {
    _loadData();
  }, [queries]);

  return (
    <>
      <PageHeader
        title="배너 관리"
        // breadcrumb={{ routes }}
        subTitle="배너 목록"
      />
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <BannerFormModal onDone={_loadData} detail={bannerDetail} onClose={() => setBannerDetail(undefined)} />
        <Button
          type="primary" danger style={{ marginLeft: '8px' }} disabled={selectedIds.length === 0}
          onClick={() => setShowDeleteConfirm(true)}
        > 삭제 </Button>
      </div>
      <TableWrap type={pageSize}>
        <DataGrid
          className='popup-list-table'
          rows={userlist}
          rowCount={totalElements}
          columns={columns}
          page={queries.search_page - 1}
          pageSize={queries.search_count}
          rowsPerPageOptions={[queries.search_count]}
          checkboxSelection
          loading={loading}
          onPageChange={pageNation}
          onSelectionModelChange={onSelectionChanged}
          paginationMode="server"
          pagination
        />
      </TableWrap>
      <ConfirmDialog
        visible={showDeleteConfirm}
        title="삭제"
        content="선택한 항목을 모두 삭제하시겠습니까?"
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={_deleteSelected}
        loading={submitting}
      />
      <Image
        width={200}
        style={{ display: 'none' }}
        src={imagePreviewUrl}
        preview={{
          visible: Boolean(imagePreviewUrl),
          src: imagePreviewUrl,
          onVisibleChange: value => {
            setImagePreviewUrl(undefined);
          },
        }}
      />
    </>
  );
};

const TableWrap = styled.div<TableWrapProps>`
  height: calc(${props => props.type} * 63px);
`

const Tag = styled.div<{ color?: number }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 30px; 
  font-size: 12px;
  color: ${props => props.color === 0 ? '#18AB56' : '#EB5757'};
  background: ${props => props.color === 0 ? '#F0FFF8' : '#FFF0F0'};
  border-radius: 8px;
  font-weight: 600;
  padding: 0 12px;

`

export default BannerPage;
