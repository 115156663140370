import { createSlice } from "@reduxjs/toolkit";

interface AddEvent {
  title: string;
  content: string;
  url: string;
  file: File | string;
  isEmpty: boolean;
  youtube_link: string;
  event_type: string;
}

const initialState: AddEvent = {
  title: "",
  content: "",
  url: "",
  file: "",
  isEmpty: true,
  youtube_link: "",
  event_type: "SNS",
};

export const addEventSlice = createSlice({
  name: "addEvent",
  initialState,
  reducers: {
    setTitle: (state, { payload }) => {
      state.title = payload;
    },
    setContent: (state, { payload }) => {
      state.content = payload;
    },
    setUrl: (state, { payload }) => {
      state.url = payload;
    },
    setFiles: (state, { payload }) => {
      state.file = payload;
    },
    setIsEmpty: (state, { payload }) => {
      state.isEmpty = payload;
    },
    setYoutubelink: (state, { payload }) => {
      state.youtube_link = payload;
    },
    setEventtype: (state, { payload }) => {
      state.event_type = payload;
    },
  },
});

const { actions } = addEventSlice;
export const {
  setIsEmpty,
  setUrl,
  setFiles,
  setContent,
  setEventtype,
  setYoutubelink,
  setTitle,
} = actions;
