import { createSlice } from "@reduxjs/toolkit";
import { Ifarm } from "../../api/farm";

interface Farmer {
  totalpage: number;
  list: Ifarm[];
}

const initialState: Farmer = {
  totalpage: 0,
  list: [],
};

export const farmerSlice = createSlice({
  name: "farmer",
  initialState,
  reducers: {
    setTotalpage: (state, { payload }) => {
      state.totalpage = payload;
    },
    setList: (state, { payload }) => {
      state.list = payload;
    },
  },
});

const { actions } = farmerSlice;
export const { setList, setTotalpage } = actions;
