import { Route, Routes } from "react-router-dom";
import IntroPage from "../pages/IntroPage";
import ReviewList from "../pages/Review/ReviewList";
import Farmer from "../pages/Farmer/Farmer";
import Notice from "../pages/Notice";
import News from "../pages/News";
import Event from "../pages/Event/Event";
import Faq from "../pages/Faq/FaqList";
import PopupList from "../pages/Popup/PopupList";
import AddPopup from "../pages/Popup/AddPopup";
import Qna from "../pages/Qna/QnaList";
// import Regist from '../pages/inquiry/regist/Regist';
import ReviewDetail from "../pages/Review/ReviewDetail";
import Mypage from "../pages/Mypage";
import UserList from "../pages/UserList/UserList";
import FarmRequestDetail from "../pages/Qna/FarmRequestDetail";
import AddEvent from "../pages/Event/AddEvent";
import EditEvent from "../pages/Event/EditEvent";
import EditFarmer from "../pages/Farmer/EditFarmer";
import AddFarmer from "../pages/Farmer/AddFarmer";
import styled from "styled-components";
import NoWBancow from "../pages/NowBancow";
import Partner from "../pages/Partner";
import AddNodwBancow from "../pages/NowBancow/AddNodwBancow";
import EditNowBancow from "../pages/NowBancow/EditNowBancow";
import BannerPage from "../pages/banner/BannerPage";
import BannerDetail from "../pages/banner/BannerDetail";
import RaiserDetailPage from '../pages/RaiserDetailPage';
import EntranceInq from "../pages/EntranceInq/EntranceInq";

const MainArea = () => {
  
  return (
		<MainContentStyle className="content-area">
			<Routes>
				<Route path="/" element={<IntroPage />} />
				<Route path="mypage" element={<Mypage />} />
				<Route path="/review" element={<ReviewList />} />
				<Route path="/review/:id" element={<ReviewDetail />} />
				<Route path="/farmer" element={<Farmer />} />
				<Route path="/farmer/add" element={<AddFarmer />} />
				<Route path="/farmer/:id" element={<EditFarmer />} />
				<Route path="/notice" element={<Notice />} />
				<Route path="/news" element={<News />} />
				<Route path="/event" element={<Event />} />
				<Route path="/event/add" element={<AddEvent />} />
				<Route path="/event/:id" element={<EditEvent />} />
				<Route path="/faq" element={<Faq />} />
				<Route path="/howto/inquiry" element={<Qna />} />
				{/*<Route path="/inquiry/regist" element={<Regist />} />*/}
				<Route path="/popup" element={<PopupList />} />
				<Route path="/popup/add" element={<AddPopup />} />
				<Route path="/qna/:id" element={<FarmRequestDetail />} />
				<Route path="/nowbancow" element={<NoWBancow />} />
				<Route path="/nowbancow/add" element={<AddNodwBancow />} />
				<Route path="/nowbancow/:id" element={<EditNowBancow />} />
				<Route path="/partner" element={<Partner />} />
				<Route path="/manager" element={<UserList />} />
				<Route path="/howto/inquiry" element={<UserList />} />
				<Route path="/banner" element={<BannerPage />} />
				<Route path="/banner/:id" element={<BannerDetail />} />
				<Route path="/raisers" element={<EntranceInq />} />
      			  <Route path='/raiser/:id' element={<RaiserDetailPage/>} />
			</Routes>
		</MainContentStyle>
	);
};

const MainContentStyle = styled.div`
max-height: 100%;
flex-grow: 1;
padding: 40px 30px;
overflow: auto;

`

export default MainArea;
