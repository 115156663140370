
import "antd/dist/antd.css";
import { UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Modal, Select, Upload } from 'antd';
import styled from 'styled-components'
import React, { useState, useEffect } from 'react';

interface ComfirmModal {
  handleUpdateProcess2?: ()=> {} ;
  handleUpdateProcessDone?: ()=> {} ;
  inProgress?: string,
  disabled?: boolean,
  titlepopup?:string,
  content?:string,
  cancelbutton?:string,
  approvedbutton?: string,
  comfirmbutton?:string
  position?:string
}

const ComfirmModal = (props:ComfirmModal) => {
  const {handleUpdateProcess2,inProgress,handleUpdateProcessDone,titlepopup,disabled,content,approvedbutton,cancelbutton,comfirmbutton,position} =props
  const [visible, setVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleUpdatePropress = ()=>{
    setSubmitting(true)
    handleUpdateProcess2 && handleUpdateProcess2()
    handleUpdateProcessDone&&handleUpdateProcessDone()
  }

  useEffect(()=>{
    setTimeout(() => {
      setSubmitting(false)
      setVisible(false);
    },3000)
  },[submitting])


  const Content = styled.p`
  font-weight: 400;
  font-size: 15px;
  color: #2C2D2E
`;
const TitlePopUp = styled.p`
font-weight: 800;
font-size: 24px;
color: #000000;
`;

const Wrapper = styled.section`
  display: flex;
  margin-top:10px;
  gap:20px
`;

const ComfirmButton =styled.button`
background: #005FF9;
border-radius: 4px;
width: 54px;
height: 40px;
color:#ffff;
border-style:none
`;
const CancelButton =styled.button`
background: rgba(0, 16, 61, 0.06);
border-radius: 4px;
width: 82px;
height: 40px;
color:black;
border-style:none
`;

const ApprovedButton = styled.button`
width: 92px;
height: 30px;
background: ${titlepopup === '실사완료' ? 'rgba(0, 16, 61, 0.06)' : '#005FF9'};
border-radius: 4px;
border-style: none;
position: relative;
left:${position};
color: ${titlepopup === '실사완료' ? '#2C2D2E' : '#FFFFFF'};
opacity: ${disabled?'1':'0.48'};
cursor: ${disabled?'pointer':'not-allowed'};
`;

  return (
    <div>
      <Modal
        title={<TitlePopUp>{titlepopup}</TitlePopUp>}
        visible={visible}
        onOk={hideModal}
        onCancel={hideModal}
        centered={true}
        maskClosable={false}
        closable={false}
        className='form-modal'
        footer={null}
      >
      <Content>{content}</Content>
      <Wrapper>
          <ComfirmButton onClick={handleUpdatePropress}>네</ComfirmButton>
          <CancelButton  onClick={hideModal}>아니오</CancelButton>
      </Wrapper>
      </Modal>
      <ApprovedButton onClick={showModal} disabled={!disabled} >{approvedbutton}</ApprovedButton>

    </div>
  );
}

export default ComfirmModal;