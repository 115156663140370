import { Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getFarmQnaList } from "../api/partner";
import { getQnaList } from "../api/qna";
import './pageStyle.scss';

const IntroPage = () => {
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [list, setList] = useState<any[]>([]);
  const [notlist, setNotlist] = useState<any[]>([]);
  const [notlist1, setNotlist1] = useState<any[]>([]);
  const [list1, setList1] = useState<any[]>([]);
  const [totalElements, setTotalElements] = useState(0);
  const [totalElements1, setTotalElements1] = useState(0);
  const [queries, setQueries] = useState({
    search_count: 10,
    search_page: 1
  });
  const [queries1, setQueries1] = useState({
    search_count: 8,
    page: 0
  });

  useEffect(() => {
    // 1:1 문의 내역 
    const res = getQnaList(queries)
    res.then(
      (res) => getQnaList({
        search_count: res.totalElements,
        search_page: 1
      })
    ).then(
      (res) => setNotlist(
        res.content.sort(function (a: any, b: any) {
          return b.id - a.id
        }).filter((item: any) => item.checked === false)
      )
    )
  }, [queries]);

  useEffect(() => {
    // 농가입점문의
    const res = getFarmQnaList(queries1)
    res.then(
      (res) => getFarmQnaList({
        search_count: res.totalElements,
        page: 0
      }),
      // (res) => setNotlist1(res.content.sort(function (a: any, b: any) {
      //   return b.id - a.id
      // }).filter((item: any) => item.checked === false)
      // )
    )
    // .then(
    //   (res) => setNotlist1(
    //     res.content.sort(function (a: any, b: any) {
    //       return b.id - a.id
    //     }).filter((item: any) => item.checked === false)
    //   )
    // )
  }, [queries1]);

  useEffect(() => {
    _loadData();
  }, [queries1]);

  const _loadData = async () => {
    if (!loading) setLoading(true);
    const res = await getFarmQnaList(queries1);
    setLoading(false);
    if (res) {
      setList1(res.content);
      setNotlist1(res.content.sort(function (a: any, b: any) {
        return b.id - a.id
      }).filter((item: any) => item.checked === false)
      )
    }
  }

  return (
    <div className="intro-page">
      <div className="site-card-wrapper">
        <Row gutter={24}>
          <Col span={12}>
            {/* <Card title="최신 1:1 문의내역" extra={<Link to="/manager/admin/howto/inquiry">바로가기</Link>} >
              {
                list.map((item, index) => {
                  return (
                    <div>
                      <span>{item.category}/</span>
                      <span>{item.qna_name}/</span>
                      <span>{item.title}</span>
                    </div>
                  )
                })
              }
            </Card> */}
            <Card title="1:1 문의내역" extra={<Link to="/manager/admin/howto/inquiry">바로가기</Link>} >
              <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>최신순 미답변 3개</p>
              {
                notlist.length !== 0 ?
                  notlist.splice(0, 3).map((item, index) => {
                    return (
                      <div style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                        <div style={{ display: 'table-cell' }}>작성자 이름 : {item.qna_name}</div>
                        <div style={{ display: 'table-cell' }} >제목 : {item.title}</div>
                        <div style={{ display: 'table-cell' }}>작성일 : {item.create_date}</div>
                      </div>
                    )
                  }) :
                  '최신 미답변된 문의가 없습니다'
              }
            </Card>
            {/* <Card title="1:1 문의내역" extra={<Link to="/manager/admin/howto/inquiry">바로가기</Link>} >
              <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>오래된순 미답변 3개</p>
              {
                notlist ?
                  notlist.splice(0, 3).map((item, index) => {
                    return (
                      <div className="l-table">
                        <span className="l-table-cell">{item.qna_name}/</span>
                        <span className="l-table-cell">{item.title}</span>
                        <span className="l-table-cell">{item.create_date}</span>
                      </div>
                    )
                  }) :
                  '최신 미답변된 문의가 없습니다'
              }
            </Card> */}
          </Col>
          <Col span={12}>
            <Card title="농가입점문의" extra={<Link to="/manager/admin/partner">바로가기</Link>} >
              <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>최신순 미답변 3개</p>
              {
                notlist1.length !== 0 ?
                  notlist1.slice(0, 3).map((item, index) => {
                    return (
                      <div style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                        <div style={{ display: 'table-cell' }}>농장 이름 : {item.farm_name}</div>
                        <div style={{ display: 'table-cell' }} >이름 : {item.farm_qna_name}</div>
                        <div style={{ display: 'table-cell' }} >작성일 : {item.create_date}</div>
                        <div style={{ display: 'table-cell' }}>전화번호 : {item.phone_number}</div>
                      </div>
                    )
                  }) :
                  '최신 미답변된 문의가 없습니다'
              }
            </Card>
          </Col>
          <Col span={12}>
            {/* <Card title="1:1 문의내역" extra={<Link to="/manager/admin/howto/inquiry">바로가기</Link>} >
              {
                notlist.splice(0,3).map((item, index) => {
                  return (
                    <div>
                      <span>{item.category}/</span>
                      <span>{item.qna_name}/</span>
                      <span>{item.title}</span>
                    </div>
                  )
                })
              }
            </Card> */}
          </Col>
          {/* <Col span={8}>
            <Card title="Card title" bordered={false}>
              Card content
            </Card>
          </Col> */}
        </Row>


      </div>
    </div>
  );
};

export default IntroPage;
